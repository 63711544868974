import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import { useSelector } from "react-redux";
import { Flex } from 'antd';
import dropSpinner from '../assets/images/Drop_ring.gif';
import { getColorProductList } from "../services/home";
import Routes from "../Routes/Routes";
import Wishlist from "../function/Wishlist";
import AddToCart from "../function/AddToCart";
import LoaderLottie from '../assets/images/loader.json';
import Lottie from 'react-lottie';
import ProductPrice from "./ProductPrice";

// Extract arrow component
const SliderArrow = ({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Extract loading spinner component
const LoadingSpinner = () => <Lottie options={defaultOptions}
  height={150}
  width={200}
/>;

const ColorWiseProduct = ({ productAttributesData }) => {
  const colorAttribute = productAttributesData.find(attr => attr.attr_type_id === 2);
  const color_id = colorAttribute ? colorAttribute.product_attr_value : null;
  const user = useSelector((state) => state.user);
  const userId = user?.id || 0;
  const hasMounted = useRef(false);
  const [sellerList, setSellerList] = useState(null);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 576, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [imgPath, setImgPath] = useState(null);

  const fetchData = async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('color_id', color_id?.id);
      formData.append('user_id', userId);
      const response = await getColorProductList(formData);
      setSellerList(response);
      setImgPath(response.product_path);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!color_id?.id) {
      // Handle case where color_id is null or undefined
      setLoading(false);
      return;
    }
    fetchData();
  }, [color_id]);

  const renderArrows = () => (
    <div className="slider-arrow">
      <SliderArrow direction="prev" onClick={() => sliderRef.current?.slickPrev()} />
      <SliderArrow direction="next" onClick={() => sliderRef.current?.slickNext()} />
    </div>
  );

  return (
    sellerList?.data?.length > 0 &&
    <section className='best_seller_slider'>
      <div className="container-fluid">
        <div className='row'>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <h3 className='store_headings'>Kairaus Color Harmony Collection </h3>
              <div className='dinner_slider'>
                <Slider {...settings} ref={sliderRef}>
                  {sellerList?.data.map((sellerItem) => (
                    <div className='dinner_sliderImg' key={sellerItem?.id}>
                      {sellerItem?.productimages && sellerItem?.productimages.length > 0 ? (
                        <div className="imageContainer">
                          <Link to={`/product/${sellerItem?.product_slug}`} key={sellerItem?.id}>
                            <img src={`${imgPath}${sellerItem?.productimages[0]?.file_name}`} alt={sellerItem?.product_name} />
                            <img src={`${imgPath}${sellerItem?.productimages[1]?.file_name}`} className='hoverImage' alt="" />
                          </Link>
                          {sellerItem?.stock_quantity <= 0 ? (
                            <div className="sold_off_chips">
                              <p>Sold Out</p>
                            </div>
                          ) : (
                            sellerItem?.discount !== 0 &&
                            <div className="off_chips">
                              <p>{sellerItem?.discount}% off</p>
                            </div>
                          )}
                          <Wishlist is_wishlist={sellerItem?.is_wishlist} pId={sellerItem?.id} path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} mode='HeartOutlined' />
                          <AddToCart productList={sellerItem} p_id={sellerItem?.id} imgPath={imgPath} />
                        </div>
                      ) : (
                        <h1>image</h1>
                      )}
                      <div className='dinnerSlider_details'>
                        <Link to={`/product/${sellerItem?.product_slug}`} key={sellerItem?.id}> <p>{sellerItem?.product_name} </p></Link>
                        <div className='dinnerSliderSpan'>
                          {/* <span>₹{sellerItem?.price}</span>
                          {sellerItem?.price !==
                            parseFloat(sellerItem?.compare_price) &&
                            sellerItem?.compare_price > 0 && (
                                <p>₹{sellerItem?.compare_price}</p>    <ProductPrice product={productDetails} />
                            )} */}
                          <ProductPrice product={sellerItem} />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <button className="common_btns" onClick={() => navigate(`${Routes?.productByColor}/${color_id?.slug}`, { state: { from: color_id?.attr_val_name } })}>View More</button>
              </div>
            </>
          )}
          {renderArrows()}
        </div>
      </div>
    </section>
  );
};

export default ColorWiseProduct;
