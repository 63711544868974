import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import Routes from "../../Routes/Routes";
import img1 from "../../assets/images/drinkwarecup.png";
import Wishlist from "../../function/Wishlist";
import AddToCart from "../../function/AddToCart";
import { Input, Avatar, Row, Col, Pagination, Image, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { addLikeReview, addReviews, editReviews, getReviewsDetails, getReviewsList } from "../../services/reviews";
import dayjs from "dayjs";
import ReactStars from "react-rating-stars-component";
import ChangeImg from "../../assets/images/like.png";
import likeButton from "../../assets/images/thumbs-up.png";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { clearUser } from "../../reducers/userSlice";
import { addOnProduct } from "../../services/product";
import { getCustomerSupport } from "../../services/assurance";
const { TextArea } = Input;

const ProductDetailTab = ({ responseData, slug }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [activeTab, setActiveTab] = useState("dimensions");
    const [addOn, setAddOn] = useState(null);
    const [imgPath, setImgPath] = useState(responseData?.path);
    const [productDetails, setProductDetails] = useState(responseData?.data);
    const [reviews, setReviews] = useState([]);
    const [ReviewsImagePath, setReviewsImagePath] = useState();
    const [reviewCount, setReviewCount] = useState(0);
    const [rating, setRating] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [oldImage, setOldImage] = useState([]);
    const [feedbackText, setFeedbackText] = useState("");
    const [reviewDetail, setReviewDetail] = useState(null);
    const [supportData, setSupportData] = useState({});

    // Update state when responseData changes

    useEffect(() => {
        if(responseData){
        setImgPath(responseData?.path || '');
        setProductDetails(responseData?.data || {});
            productRelatedData(responseData?.data?.id);
        fetchReviews(responseData?.data?.id);
        // setReviewCount(responseData?.is_review_count);
    }
    }, [responseData]);
    const productRelatedData = async (id) => {

        const formData = new URLSearchParams();
        formData.append("product_id", id);
        formData.append("related_type", "add_on");
        formData.append("user_id", user?.id);
        try {
            let result = await addOnProduct(formData);
            setAddOn(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };
    const renderAddOnSlider = () => (
        addOn && (
            <div className="special_addons">
                <div className="special_addonSlider">
                    <Slider {...settingsAdd}>
                        {addOn?.map(item => (
                            <div className="special_image">
                                <Link to={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`}>
                                    <img src={item?.relatedproduct?.productimages.length > 0 ? `${imgPath}${item?.relatedproduct?.productimages[0]?.file_name}` : img1} alt="" />
                                    <div className='dinnerSlider_details'>
                                        <p className="makeOurkit_para">{item?.relatedproduct?.product_name}</p>

                                        {item?.relatedproduct?.stock_quantity <= 0 ?
                                            <div className="sold_off_chips">
                                                <p>Sold Out</p>
                                            </div> :
                                            item?.relatedproduct?.discount > 0 &&
                                            <div className="off_chips">
                                                <p>{item?.relatedproduct?.discount}% off</p>
                                            </div>}
                                        <div className='dinnerSliderSpan'>
                                            <span>₹{item?.relatedproduct?.price}</span>
                                            {item?.relatedproduct?.price !== parseFloat(item?.relatedproduct?.compare_price) && item?.relatedproduct?.compare_price > 0 &&
                                                <p>₹{item?.relatedproduct?.compare_price}</p>
                                            }
                                        </div>
                                    </div>
                                </Link>
                                <div className="dinnerAddon_btn">
                                    <Wishlist is_wishlist={item?.relatedproduct?.is_wishlist} pId={item?.relatedproduct?.id} path={`${Routes.ProductDetail}/${item?.relatedproduct?.product_slug}`} mode='HeartOutlined' />
                                    <AddToCart productList={item?.relatedproduct} p_id={item?.relatedproduct?.id} imgPath={imgPath} />
                                </div>
                            </div>
                        ))}

                    </Slider>
                </div>
            </div>
        )
    );
    // reviews section
    const fetchReviews = async (id) => {
        const formData = new URLSearchParams();
        formData.append("product_id", id);
        formData.append("user_id", user?.id || 0);
        try {
            const response = await getReviewsList(formData);
            // console.log('getReviewsList',response);

            if (response.status === "1") {
                setReviews(response?.data);
                setReviewsImagePath(response?.path);
                setReviewCount(response.is_review);
            }
        } catch (error) {
            // console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        if (reviewDetail?.review_images) {
            const fileListData = reviewDetail?.review_images?.map((imageObj) => ({
                uid: imageObj.image,
                name: imageObj.image,
                status: "done",
                url: `${ReviewsImagePath}${imageObj.image}`,
                id: imageObj.id,
            }));
            setFileList(fileListData);
            // setFileList(fileListData)
        }
    }, [reviewDetail]);
    // review
    const [currentPage, setCurrentPage] = useState(1);
    const [reviewsPerPage] = useState(2); // You can adjust the number of reviews per page as needed
    const indexOfLastReview = currentPage * reviewsPerPage;
    const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
    const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const renderReviews = () => (
        <div className="tabs_description">
            <Row gutter={48}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                    <h3>Customers Review</h3>
                    {currentReviews?.map((item, index) => (
                        <div key={index} className="mainContainer_rating">
                            <div
                                key={`react-stars-${index}`}
                                className="review_container"
                            >
                                <Avatar style={{ width: "35px", height: "35px" }}>
                                    <span
                                        style={{
                                            lineHeight: "224%",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {item?.user?.userfullname !== null
                                            ? item?.user?.userfullname?.charAt(0)
                                            : item?.user?.userfullname.charAt(0)}
                                    </span>
                                </Avatar>
                                <div className="reviewDetail_cont">
                                    <div className="review_widthDesign">
                                        <h6>{item?.user?.userfullname}</h6>
                                        <div className="containerMain_revirw">
                                            {/* <div className='verifiedChceked'><FormOutlined /></div> */}
                                            <h5>
                                                {dayjs(item?.created_at)?.format(
                                                    "DD MMM YYYY"
                                                )}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="tabsReviewCont">
                                        <ReactStars
                                            key={`react-stars-${index}-${item?.rating}`}
                                            count={5}
                                            size={24}
                                            isHalf={true}
                                            activeColor="#ffd700"
                                            color="#d1d1d1"
                                            value={parseFloat(item?.rating)}
                                            edit={false}
                                        />
                                    </div>
                                    <div className="tabsReviewImage">
                                        {item?.review_images?.map((imagesItem) => (
                                            <img
                                                src={`${ReviewsImagePath}${imagesItem?.image}`}
                                                alt=""
                                            />
                                        ))}
                                    </div>
                                    <p>{item?.review}</p>
                                    <div className="paragraph_review">
                                        <div className="review_likesCont">
                                            <img
                                                src={
                                                    item.is_review_like === 1
                                                        ? ChangeImg
                                                        : likeButton
                                                }
                                                className="likeButton"
                                                onClick={() => handleLikeClick(item)}
                                                alt=""
                                            />
                                            <span>{item?.review_like_count}</span>
                                        </div>
                                        <div className="containerMain_revirw">
                                            <div className="verifiedChceked">
                                                <CheckOutlined />
                                            </div>
                                            <span>Verified Buyer</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <div className="model_reviewBody">
                        <h4>
                            {reviewCount !== 0 ? " Edit review" : "Write a Review"}
                        </h4>
                        <span>
                            How likely are you to recommend this product ?
                        </span>
                        <ReactStars
                            key={`react-stars-${rating}`}
                            count={5}
                            onChange={ratingChanged}
                            value={rating ? rating : 0}
                            size={28}
                            isHalf={true}
                            color="#d1d1d1"
                            activeColor="#ffd700"
                            edit={true}
                        />

                        {/* <h5>What can we improve ?</h5> */}
                        <h5>Add your product images</h5>
                        <div className="upload_imge">
                            <Upload
                                // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                listType="picture-card"
                                name="file"
                                onPreview={handlePreview}
                                onChange={handleUpload}
                                fileList={fileList}
                                beforeUpload={beforeUpload}
                                multiple
                            >
                                {fileList?.length >= 5 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: "none",
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) =>
                                            setPreviewOpen(visible),
                                        afterOpenChange: (visible) =>
                                            !visible && setPreviewImage(""),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </div>

                        <h5>What Feature can we add to improve?</h5>
                        <TextArea
                            rows={4}
                            placeholder="Tell us more (in 250 characters please)"
                            maxLength={250}
                            value={feedbackText}
                            onChange={handleFeedbackTextChange}
                        />

                        <button
                            key="submit"
                            onClick={handleSubmit}
                            disabled={submitted}
                        >
                            {" "}
                            {reviewCount !== 0
                                ? "Update Feedback"
                                : "Submit Feedback"}
                        </button>
                    </div>
                </Col>
            </Row>
            {/* {reviews?.length > 0 && (
                <div className="blog_pagination">
                    <Pagination defaultCurrent={5} total={5} />
                </div>
            )} */}
            {reviews?.length > 0 && (
                <div className="blog_pagination">
                    <Pagination
                        current={currentPage}
                        total={reviews.length}
                        pageSize={reviewsPerPage}
                        onChange={handlePageChange}
                    />
                </div>
            )}
        </div>
    );
    //
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handleUpload = ({ fileList: newFileList }) => {
        const deletedImageIds = fileList
            .filter(
                (oldFile) => !newFileList.some((newFile) => newFile.uid === oldFile.uid)
            )
            .map((deletedFile) => deletedFile.id)
            .filter((id) => id !== undefined && id !== null);

        setOldImage((prevOldImages) => [...prevOldImages, ...deletedImageIds]);

        setFileList(newFileList);
        setSubmitted(false);
    }
    const beforeUpload = (file) => {
        const isJpgOrPng =
            file?.type === "image/jpeg" || file?.type === "image/png";
        const isVideo = file?.type.startsWith("video/");
        if (!isJpgOrPng) {
            if (isVideo) {
                message.error("Video uploads are not allowed!");
            } else {
                message.error("You can only upload JPG/PNG files!");
            }
            return false;
        }

        const isLimit = file.size / 1024 / 1024 < 2;
        if (!isLimit) {
            message.error("Image must be smaller than 2MB!");
            return false;
        }

        if (fileList.length >= 5) {
            message.error("You can upload only up to 5 images!");
            return false;
        }

        return true;
    };

    const ratingChanged = (newRating) => {
        setRating(newRating);
        setSubmitted(false);
    };
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const settingsAdd = {
        dots: false,
        infinite: addOn?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleLikeClick = async (itemId) => {
        const formData = new URLSearchParams();
        formData.append("product_review_id", itemId?.id || 0);
        try {
            const response = await addLikeReview(formData, user.token);
            // console.log('vivekClick',itemId)
            if (response.status === "1") {
                // fetchDataAndRelatedData();
                fetchReviews(responseData?.data?.id);
                itemId.is_review_like === 1
                    ? (itemId.is_review_like = 0)
                    : (itemId.is_review_like = 1);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const handleFeedbackTextChange = (e) => {
        const text = e.target.value;
        setFeedbackText(text);
        setSubmitted(false);
    };
    const handleCancel = () => {
        // fetchDataAndRelatedData();
        if (reviewDetail !== null) {
            setRating(reviewDetail?.rating);
            setFeedbackText(reviewDetail?.review);
        }
    };
    const handleSubmit = async () => {
        setSubmitted(true);
        if (user?.id) {
            if (!rating) {
                toast.error("Rating is required");
                return;
            }
            const formData = new FormData();
            formData.append("rating", rating);
            formData.append("review", feedbackText);
            fileList.forEach((file) => {
                formData.append("file", file.originFileObj);
            });
            formData.append("old_image_id", JSON.stringify(oldImage));
            // const ttoken="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjM2LCJyb2xlX2lkIjoyLCJpYXQiOjE3MTUyNTYzNzksImV4cCI6MTcxNzg0ODM3OX0.DyA7s8O6ELDJ27ZyHu7RG0hVclCAPcHeXhTo81oVYCI";
            if (reviewCount !== 0) {
                formData.append("id", reviewDetail?.id);
                try {
                    const response = await editReviews(formData, user?.token);
                    // console.log("editReviewseditReviewseditReviewseditReviewseditReviews", response);
                    if (response.status === "1") {
                        // console.log('vivekClick',response?.data?.id);
                        handleCancel();
                        setRating(response?.data?.rating);
                        setFeedbackText(response?.data?.review);
                        fetchReviews(responseData?.data?.id);
                        toast.success("Review updated successfully");
                    }
                } catch (error) {
                    if (error?.response?.data?.message === "Invalid token") {
                        const from = `${Routes?.ProductDetail}/${slug}`; // Corrected the template literal syntax
                        dispatch(clearUser());
                        navigate(Routes?.SignIn, { state: { from } });
                    }
                    // console.error("Error fetching data:", error);
                }
            } else {
                formData.append("product_id", productDetails?.id);
                try {
                    const response = await addReviews(formData, user?.token);
                    // console.log('vivekClick', response?.data?.product_id);
                    if (response.status === "1") {
                        fetchReviews(response?.data?.product_id);
                        toast.success("Review added successfully");

                    }
                } catch (error) {
                    if (error?.response?.data?.message === "Invalid token") {
                        const from = `${Routes?.ProductDetail}/${slug}`; // Corrected the template literal syntax
                        dispatch(clearUser());
                        navigate(Routes?.SignIn, { state: { from } });
                    }
                }
            }
        } else {
            navigate("/signin");
        }
    };
    const handleSingleReviews = async () => {
        if (reviewCount == "1") {
            const ReviewId = reviews.find((val) => val?.user_id === user?.id);
            if (ReviewId) {
                const formData = new URLSearchParams();
                formData.append("id", ReviewId.id);
                try {
                    const response = await getReviewsDetails(formData, user?.token);
                    if (response.status === "1") {
                        setReviewDetail(response.data);
                        setRating(response?.data?.rating);
                        setFeedbackText(response?.data?.review);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        }
    };
    useEffect(() => {
        if (reviews.length > 0) {
            handleSingleReviews();
        }
    }, [reviews]);
    useEffect(() => {
    const handleSupport = async () => {
        try {
            let result = await getCustomerSupport();
            setSupportData(result?.data);
        } catch (e) {
            console.error("error", e);
        }
    };
    handleSupport();
}, []);
    //renderSupportDetails
    const renderSupportDetails = () => (
        <div className="tabs_description">
            <Row gutter={24}>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="border_assurenceCrd"
                >
                    <div className="assurence_cards">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M44 24V9H24H4V24V39H24"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M35 39L43 32L39 28L31 35V39H35Z"
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M4 9L24 24L44 9"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="assurance_span">
                            <span>Email Us</span>
                            <p>
                                <Link to={`mailto:${supportData?.support_email}`}>
                                    {supportData?.support_email}
                                </Link>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="border_assurenceCrd"
                >
                    <div className="assurence_cards">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M41.7796 20.6066C42.0324 18.9108 41.9495 17.1747 41.5309 15.5054C40.978 13.3002 39.8392 11.2118 38.1147 9.4873C36.3902 7.76281 34.3018 6.62409 32.0967 6.07115C30.4274 5.65257 28.6912 5.56967 26.9954 5.82245"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M34.1896 19.8035C34.4604 17.9869 33.8966 16.0699 32.4982 14.6715C31.0997 13.2731 29.1827 12.7092 27.3662 12.98"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M14.3755 8.79423C15.1021 8.79423 15.7715 9.18825 16.1241 9.82349L18.5706 14.2303C18.8909 14.8073 18.9059 15.5052 18.6108 16.0955L16.254 20.8091C16.254 20.8091 16.937 24.3206 19.7954 27.179C22.6538 30.0374 26.1535 30.7086 26.1535 30.7086L30.8664 28.3522C31.4571 28.0568 32.1555 28.0721 32.7327 28.393L37.152 30.85C37.7866 31.2028 38.1802 31.8719 38.1802 32.598L38.1802 37.6715C38.1802 40.2552 35.7803 42.1213 33.3322 41.2952C28.3043 39.5987 20.4996 36.3685 15.5528 31.4216C10.6059 26.4748 7.3757 18.6701 5.67916 13.6422C4.85314 11.1941 6.71923 8.79423 9.30288 8.79423L14.3755 8.79423Z"
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="assurance_span">
                            <span>Support Helpline</span>
                            <p>
                                <Link to={`tel:${supportData?.support_phone}`}>
                                    +91-{supportData?.support_phone}
                                </Link>
                            </p>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="border_assurenceCrd"
                >
                    <div className="assurence_cards">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="24"
                                cy="11"
                                r="7"
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M4 41C4 32.1634 12.0589 25 22 25"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M31.85 28C29.7237 28 28 30.0086 28 32.4864C28 36.9727 32.55 41.0513 35 42C37.45 41.0513 42 36.9727 42 32.4864C42 30.0086 40.2763 28 38.15 28C36.8479 28 35.6967 28.7533 35 29.9062C34.3033 28.7533 33.1521 28 31.85 28Z"
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="assurance_span">
                            <span>Contact Our Team</span>
                            <p>{supportData?.support_person}</p>
                        </div>
                    </div>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={6}
                    xl={6}
                    className="border_assurenceCrd"
                >
                    <div className="assurence_cards">
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10 6C10 4.89543 10.8954 4 12 4H36C37.1046 4 38 4.89543 38 6V44L31 39L24 44L17 39L10 44V6Z"
                                fill="none"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18 22L30 22"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18 30L30 30"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M18 14L30 14"
                                stroke="#333"
                                strokeWidth="4"
                                // strokeLinecapp="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <div className="assurance_span">
                            <span>Product Warranty</span>
                            <p>{supportData?.manufacturing_warranty}</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
    return (
        <Tabs activeKey={activeTab} onSelect={handleTabSelect}>
            <Tab eventKey="dimensions" title="The Perfect Partner">
                {renderAddOnSlider()}
            </Tab>
            <Tab eventKey="whyLove" title="Why We Love It">
                <div
                    className="tabs_description"
                    dangerouslySetInnerHTML={{
                        __html: productDetails && productDetails.why_we_love_it,
                    }}
                ></div>
            </Tab>
            <Tab eventKey="shippingReturns" title="Shipping & Return & Exchange">
                <div
                    className="tabs_description"
                    dangerouslySetInnerHTML={{
                        __html: productDetails && productDetails.dimension,
                    }}
                ></div>
                <div
                    className="tabs_description mt-2"
                    dangerouslySetInnerHTML={{
                        __html: productDetails && productDetails.shipping_return,
                    }}
                ></div>
            </Tab>
            <Tab eventKey="reviews" title="Reviews">
                {/* //call here
        renderReviews */}
                {renderReviews()}
            </Tab>
            <Tab eventKey="assurance" title="Kairaus Assurance">
                {renderSupportDetails()}
            </Tab>
        </Tabs>
    );

}
export default React.memo(ProductDetailTab);









