// services/contactUs.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const getAllCategory = async() => {
  try {
    const response = await api.get('/all-category-list');
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

const menuDataList= async (slug,data) => {
  try {
    const response = await api.post(`/get-category-product/${slug}`, data);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
};

export const getAllCategorySearch = async() => {
  try {
    const response = await api.get('/search-category-list');
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const categoryApi = {getAllCategory, menuDataList, getAllCategorySearch}