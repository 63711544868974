import React, { useEffect, useRef, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { getGiftPriceList, getProductList } from '../../services/home';
import { Row, Col, Collapse, Checkbox, Slider, Select, Skeleton, Modal, Switch, Radio } from 'antd';
import { PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import { LiaGreaterThanSolid } from "react-icons/lia";
import Routes from '../../Routes/Routes';
import Wishlist from '../../function/Wishlist';
import dImg from '../../assets/images/d.png';
import { CategoryList, DiscountList, MaterialList, getFilterProductList } from '../../services/filter/categories';
import { getColor } from '../../services/filter/color';
import { useDispatch, useSelector } from 'react-redux';
import AddToCart from '../../function/AddToCart';
import { Carousel } from 'react-bootstrap';
import NoData from '../../assets/images/noData.png'
import { addToChip } from '../../reducers/filterSlice';
import { addToCompare } from '../../reducers/compareSlice';
import { addToSlug } from '../../reducers/slugSlice';
import ProductPrice from '../../components/ProductPrice';
import ScrollComponent from '../../components/ScrollComponent';
import FilterDrawer from '../../components/MobileViewFilter';
import ProductImage from '../../components/ProductImage';
import LoaderSpinner from '../../components/LoaderSpinner';

const { Panel } = Collapse;
const ProductCategory = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeSpan, setActiveSpan] = useState(null);
    // const [selectedColor, setSelectedColor] = useState(null);
    // const [filterColor, setFilterColor] = useState([])
    const [categoryList, setCategoryList] = useState();
    const [productList, setProductList] = useState(null);
    const [maxPrice, setMaxPrice] = useState();
    const [priceRange, setPriceRange] = useState([0, maxPrice]);
    const [colorPath, setColorPath] = useState(null);
    const [categoryImgPath, setCategoryImgPath] = useState(null);
    const [sliderPath, setSliderPath] = useState(null);
    // const [discountFilter, setDiscountFilter] = useState([0, 100])
    const dispatch = useDispatch();
    const chips = useSelector(state => state.chips);
    const comp = useSelector(state => state.comp);
    const isFirstRender = useRef(true);
    // for chips
    // const [chipsList, setChipsList] = useState(null);
    const [materialList, setMaterialList] = useState([])
    const [discountList, setDiscountList] = useState([])
    const [selectedDiscount, setSelectedDiscount] = useState('')
    // const [discountValues, setDiscountValues] = useState({
    //     from: '',
    //     to: ''
    // });
    let from;
    let route;
    let categorySlug;
    let menuSlug;
    let giftData;
    let price;
    let compare;
    if (location.state) {
        from = location.state.from;
        menuSlug = location.state.menuSlug
        categorySlug = location.state.categorySlug;
        route = location.state.route;
        giftData = location.state.giftData
        price = location.state.price
        compare = location.state.compare
    }
    // const newprice = price?.split(" ")[3]?.trim();
    const { slug } = useParams();
    const [category, setCategory] = useState(null);
    const [imgPath, setimgPath] = useState(null);
    const [selectedSorting, setSelectedSorting] = useState(null);
    const [productCount, setProductCount] = useState(null);
    // featch data
    const fetchData = async () => {
        const formData = new URLSearchParams();
        // if (giftData === 2) {
        //     formData.append('user_id', user?.id ?? 0);
        //     formData.append('price', newprice);
        //     try {
        //         setIsProLoading(true)
        //         const response = await getGiftPriceList(slug, formData);
        //         if (response.status === '1') {
        //             setCategory(response.category);
        //             // setCategoryImgPath(response.category_path);
        //             setProductList(response);
        //             setimgPath(response.product_path)
        //             setIsProLoading(false)
        //             const productCount = response.data.length;
        //             setProductCount(productCount);
        //         } else {
        //             console.log('Response', 'Record Not Found!');
        //             setIsProLoading(false)
        //         }
        //     } catch (error) {
        //         console.error('Error fetching product list:', error);
        //         setIsProLoading(false)
        //     }
        // } else {
        formData.append('user_id', user?.id ?? 0);
        try {
            setIsProLoading(true)
            const response = await getProductList(slug, formData);
            // console.log('vivek',response);
            if (response.status === '1') {
                setMaxPrice(response?.max_price);
                const obj = {
                    page_name: lastSegment,
                    max_price: response?.max_price,
                };

                let prevMaxPriceArray = JSON.parse(localStorage.getItem("newMaxprice")) || [];
                let exists = prevMaxPriceArray.some((item) => item.page_name === obj.page_name);

                if (!exists) {
                    prevMaxPriceArray.push(obj);
                    localStorage.setItem("newMaxprice", JSON.stringify(prevMaxPriceArray));
                }
                setPriceRange([0, response?.max_price])
                setCategory(response?.category?.categories);
                setCategoryImgPath(response.category_path);
                setProductList(response);
                setimgPath(response.product_path)
                setSliderPath(response.page_slider)
                setIsProLoading(false)
                // Set and store the product count
                const productCount = response.data.length;
                setProductCount(productCount);
                sessionStorage.setItem('productCount', productCount);
            } else {
                // console.log('Response', 'Record Not Found!');
                setIsProLoading(false)
            }
        } catch (error) {
            console.error('Error fetching product list:', error);
            setIsProLoading(false)
        }
        // }

    };
    // all Filter list
    const [colorList, setColorList] = useState(null);

    const fetchMaterialList = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', slug);

            const response = await MaterialList(formData);
            if (response.status === '1') {
                setMaterialList(response.data)
            } else {
                // console.log('Response', 'Record Not Found !');
            }
        } catch (error) {
            console.error('Error fetching product list :', error);
        }
    }
    const fetchColorList = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', slug);
            const response = await getColor(formData, user?.token);
            if (response.status === '1') {
                setColorList(response.data);
                setColorPath(response?.path)
            }
        } catch (error) {
            // console.log('Error fetching color List :', error);
        }
    }

    const fetchCategoryList = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', slug);

            const response = await CategoryList(formData);
            // console.log('responseresponseresponseresponse vivek',response);
            if (response.status === '1') {
                setCategoryList(response.data)
            } else {
                // console.log('Response', 'Record Not Found !');
            }
        } catch (error) {
            console.error('Error fetching product list :', error);
        }
    }

    const urlName = location.pathname.split('/');
    // const urlName = window?.location?.href.split('/');
    const lastSegment = urlName[urlName.length - 1];
    const newChipsData = chips.filter(chipsItem => chipsItem?.pagneName === lastSegment)
    useEffect(() => {
        fetchCategoryList();
        fetchMaterialList();
        fetchDiscountList();
        fetchColorList();
        setCheckedColors(newChipsData.map(chip => chip.colorid));
        const stockToggleItem = newChipsData?.find(item => item.type === 'stockToggle');
        if (stockToggleItem) {
            setStockToggle(stockToggleItem.value);
        } else {
            setStockToggle(0)
        }
        const existingDiscount = newChipsData.find(item => item.discountID === 1);
        if (existingDiscount) {
            setSelectedDiscount(existingDiscount.discountRange);
        } else {
            setSelectedDiscount('')
        }
    }, [slug])

    // color handler
    // const onChoose = (e) => {
    //     setSelectedColor(e?.target?.value);
    // }

    const handleSpanClick = (index) => {
        setActiveSpan(index);
    };

    const [stockToggle, setStockToggle] = useState(0)
    // console.log(stockToggle,"stockToggle")

    const removeOutOfStocks = () => {
        const newStockToggle = stockToggle === 0 ? 1 : 0;
        setStockToggle(newStockToggle);
        let updatedNames;
        if (newStockToggle === 0) {
            updatedNames = chips.filter(item => item.type !== 'stockToggle');
        } else {
            updatedNames = chips.map(item =>
                item.type === 'stockToggle'
                    ? { ...item, value: newStockToggle }
                    : item
            );

            // If the stockToggle item doesn't exist, add it
            if (!updatedNames.find(item => item.type === 'stockToggle')) {
                updatedNames.push({ type: 'stockToggle', value: newStockToggle, pagneName: lastSegment });
            }
        }

        localStorage.setItem('chips', JSON.stringify(updatedNames));
        dispatch(addToChip(updatedNames));
    };
    const onCheckbox = (id, childName) => {
        // Retrieve namesArray from localStorage or initialize it as an empty array
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];

        // Check if the id already exists in namesArray
        const existingIndex = namesArray.findIndex(item => item.id === id);

        // If the id exists, remove it; otherwise, add it
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ id, childName, pagneName: lastSegment });
        }

        // Update localStorage
        localStorage.setItem('chips', JSON.stringify(namesArray));

        // Dispatch an action or update state as needed
        dispatch(addToChip(namesArray));
    };

    const handleMaterialChange = (materialData) => {
        const { id, material_name } = materialData
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = namesArray.findIndex(item => item.materialId === id);
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ materialId: id, childName: material_name, pagneName: lastSegment });
        }
        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
    }

    const handleDiscount = (value) => {
        setSelectedDiscount(value);
        // fetchTimeout.current = setTimeout(() => {
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = namesArray.findIndex(item => item.discountID === 1);

        const discountRange = value.split('-');
        const fromDiscount = discountRange[0];
        const toDiscount = discountRange[1];
        const discountFormat = (fromDiscount == '0' && toDiscount == '100') ? 'Discount: All' : `Discount: ${fromDiscount}% Above`;

        if (existingIndex !== -1) {
            namesArray[existingIndex] = { discountID: 1, discountRange: value, childName: discountFormat, minDiscount: fromDiscount, maxDiscount: toDiscount, pagneName: lastSegment };
        } else {
            namesArray.push({ discountID: 1, discountRange: value, childName: discountFormat, minDiscount: fromDiscount, maxDiscount: toDiscount, pagneName: lastSegment });
        }

        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
        // }, 400);

    }

    const saveMaxPrice = JSON?.parse(localStorage.getItem("newMaxprice"));

    const updated_max_price = saveMaxPrice?.find(i => i?.page_name == lastSegment)?.max_price
    // console.log(updated_max_price,"updated_max_price")
    useEffect(() => {
        const max_Price = parseFloat(updated_max_price);
        if (max_Price > 0) {
            setMaxPrice(max_Price);
            // setPriceRange([0, saveMaxPrice])
        }
    }, [])

    const [checkedColors, setCheckedColors] = useState([]);
    const handleRemoveChildName = (identifier, value) => {
        // Update chips array
        let updatedNames;
        switch (identifier) {
            case 'id':
                updatedNames = chips.filter((item) => item.id !== value);
                break;
            case 'materialId':
                updatedNames = chips.filter((item) => item.materialId !== value);
                break;
            case 'colorid':
                updatedNames = chips.filter((item) => item.colorid !== value);
                break;
            case 'discountID':
                setSelectedDiscount('')
                updatedNames = chips.filter((item) => item.discountID !== value);
                break;
            case 'priceRangeID':
                setPriceRange([0, updated_max_price])
                updatedNames = chips.filter((item) => item.priceRangeID !== value);
                break;
            case 'stockToggle':
                updatedNames = chips.filter((item) => item.type !== 'stockToggle');
                setStockToggle(0);
                break;
            default:
                updatedNames = chips;
                break;
        }

        // Update localStorage
        localStorage.setItem('chips', JSON.stringify(updatedNames));

        // Dispatch an action or update state as needed
        dispatch(addToChip(updatedNames));
        // if (identifier === 'priceRangeID' || identifier === 'stockToggle') {
        //     fetchFilterProductList();
        // }
        setCheckedColors(updatedNames.map(chip => chip.colorid));
    };

    const items = categoryList && categoryList.map((category, index) => ({
        key: `${index + 1}`,
        label: "Type",
        children: (
            <div className="SubCategory">
                {category.child_items && category.child_items.map((child, childIndex) => (
                    <span key={`child-${child.id}`} onClick={() => handleSpanClick(childIndex)} className={activeSpan === childIndex ? 'active' : ''}>
                        <Checkbox checked={newChipsData?.some(chip => chip.id === child.id)} onChange={() => onCheckbox(child.id, child.name)}>{child.name}</Checkbox>
                    </span>
                ))}
            </div>
        ),
    }));

    const itemsMaterial = (materialList) => [{
        key: 'materials-panel',
        label: "Material",
        children: (
            <div className="SubCategory">
                {materialList.map(material => (
                    <span key={`material-${material.id}`} className=''>
                        <Checkbox checked={newChipsData?.some(chip => chip.materialId === material.id)} onChange={() => handleMaterialChange(material)}>{material.material_name}</Checkbox>
                    </span>
                ))}
            </div>
        ),
    }];
    // used discount Chips
    const discountItem = (discountList) => [{
        key: "discounts-panel",
        label: "Discount",
        children: (
            <Radio.Group value={selectedDiscount} onChange={(e) => handleDiscount(e?.target?.value)} >
                <div className="SubCategory">
                    {discountList?.map((discountVal, discountIndex) => (
                        <span key={`discount-${discountIndex}`} className=''>
                            <Radio value={discountVal.from_discount_val + '-' + discountVal.to_discount_val}>
                                {discountVal.from_discount_val === 0 && discountVal.to_discount_val === 100 ? 'All' : `${discountVal.from_discount_val}% Above`}
                            </Radio>
                        </span>
                    ))}
                </div>
            </Radio.Group>
        ),
    }];
    const catDataArr = useSelector((state) => state.cat?.catData);
    useEffect(() => {
        if (Array.isArray(catDataArr)) {
            const slugData = catDataArr.map(item => item.slug).filter(Boolean); // Extracting slugs
            const validSegments = new Set(slugData);

            if (!validSegments.has(slug)) {
                const slugInfo = `collections/${slug}`;
                dispatch(addToSlug({ slug: slugInfo }));
                navigate(`/${slugInfo}`);
            }
        }
        setSelectedSorting(null);
    }, [slug, catDataArr, dispatch, navigate]); // Ensure all dependencies are included


    const user = useSelector((state) => state.user);
    const handleSelectChange = (value) => {
        setSelectedSorting(value);
        setStockToggle(1);
        // console.log('setSelectedSorting',e.target.value);
    }

    const [isProLoading, setIsProLoading] = useState(false)

    const { materialIds, colorIds, chipid } = chips.filter(chipsItem => chipsItem?.pagneName === lastSegment)
        .reduce((acc, chipsItem) => {
            if (chipsItem.materialId) acc.materialIds.push(chipsItem.materialId);
            if (chipsItem.colorid) acc.colorIds.push(chipsItem.colorid);
            if (chipsItem.id) acc.chipid.push(chipsItem.id);
            return acc;
        }, { materialIds: [], colorIds: [], chipid: [] });

    const fetchFilterProductList = async () => {
        // console.log('newChipsData',newChipsData);
        // setMaxPrice(saveMaxPrice);
        // setPriceRange([0, saveMaxPrice]);
        const existingIndex = chips.findIndex(item => item.priceRangeID === 1);
        const existingpriceRange = existingIndex !== -1 ? chips[existingIndex]?.priceRange : [];
        const stockToggleItem = newChipsData.find(item => item.type === 'stockToggle');
        // console.log(stockToggleItem, "stockToggleItem")
        const stockToggleValue = stockToggleItem ? stockToggleItem.value : stockToggle;
        const existingDiscountValue = newChipsData.findIndex(item => item.discountID === 1);
        const existingDiscountRange = existingDiscountValue !== -1 ? newChipsData[existingDiscountValue]?.discountRange.split('-') : [];

        const minDiscount = existingDiscountRange.length ? existingDiscountRange[0] : '';
        const maxDiscount = existingDiscountRange.length ? existingDiscountRange[1] : '';
        try {
            const formData = new URLSearchParams();
            formData.append('category_id', JSON.stringify(chipid));
            formData.append('color_id', JSON.stringify(colorIds));
            formData.append('sorting', selectedSorting);
            formData.append('slug', slug)
            formData.append('material_id', JSON.stringify(materialIds));
            formData.append('min_price', priceRange[0] ? priceRange[0] : existingpriceRange[0] ? existingpriceRange[0] : 0);
            formData.append('max_price', priceRange[1] ? priceRange[1] : existingpriceRange[1] ? existingpriceRange[1] : updated_max_price);
            formData.append('min_discount', minDiscount)
            formData.append('max_discount', maxDiscount)
            formData.append('user_id', user?.id);
            formData.append('remove_stock', stockToggleValue)
            formData.append('type_id', '[]')
            const response = await getFilterProductList(formData);

            // console.log('getFilterProductList', response);

            if (response.status === '1') {
                setProductList(response);
                setCategory(response?.category?.categories);
                setCategoryImgPath(response.category_path);
                setSliderPath(response.page_slider);
                const productCount = response.data.length;
                setProductCount(productCount);
            } else {
                // console.log('Response', 'Record Not Found!');
            }
        } catch (error) {
            console.error('Error fetching product listsdsdsadsa:', error);
        }
    };
    useEffect(() => {
        // Fetch data conditionally

        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (newChipsData?.length > 0 || selectedSorting) {
            fetchFilterProductList();
        } else {
            fetchData();
        }
    }, [slug, user, selectedSorting, chips]);

    const fetchTimeout = useRef(null);

    const handlePriceChange = (value) => {
        const [min, max] = value;
        if (min > max) {
            setPriceRange([max, max]);
        } else {
            setPriceRange(value);
        }
        // Clear the previous timeout
        if (fetchTimeout.current) {
            clearTimeout(fetchTimeout.current);
        }

        // Set a new timeout
        fetchTimeout.current = setTimeout(() => {
            const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
            const existingIndex = namesArray.findIndex(item => item.priceRangeID === 1);

            if (existingIndex !== -1) {
                // Replace the existing item
                namesArray[existingIndex] = { priceRangeID: 1, priceRange: value, childName: `Price: ${min} - ${max}`, maxPrice: maxPrice, pagneName: lastSegment };
            } else {
                // Add a new item
                namesArray.push({ priceRangeID: 1, priceRange: value, childName: `Price: ${min} - ${max}`, maxPrice: maxPrice, pagneName: lastSegment });
            }

            localStorage.setItem('chips', JSON.stringify(namesArray));
            dispatch(addToChip(namesArray));
        }, 400);
    };

    useEffect(() => {
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = newChipsData.findIndex(item => item.priceRangeID === 1);
        const existingDiscount = newChipsData.find(item => item.discountID === 1);
        if (existingDiscount) {
            setSelectedDiscount(existingDiscount.discountRange);
        }

        if (existingIndex !== -1) {
            const [existingMinPrice, existingMaxPrice] = chips[existingIndex].priceRange;
            setPriceRange([existingMinPrice, existingMaxPrice]);
            setMaxPrice(namesArray[existingIndex]?.maxPrice);
        } else {
            setPriceRange([0, updated_max_price]);
        }
        if (chips.length <= 0) {
            // fetchData();
            // setFilterColor([]);
            setPriceRange([0, maxPrice]);
            setStockToggle(0);
        }
        setCheckedColors(newChipsData.map(chip => chip.colorid));

    }, []);


    //  featch data for filter used only

    // After used for discount 
    const fetchDiscountList = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', slug);

            const response = await DiscountList(formData);
            if (response.status === '1') {
                setDiscountList(response.data)
            } else {
                // console.log('Response', 'Record Not Found !');
            }
        } catch (error) {
            // console.error('Error fetching product list :', error);
        }
    }

    const handleItem = (slug, from, route, dataSlug, menuSlug, catfirstegorySlug, ProductId) => {
        navigate(`${Routes.ProductDetail}/${slug}`, { state: { dataSlug, route, from, menuSlug, categorySlug, ProductId } });
    }
    const [isActiveIcon, setIsActiveIcon] = useState(false);
    const [isActiveSec, setIsActiveSec] = useState(false);
    const toggleAccordion = () => {
        setIsActiveIcon(!isActiveIcon);
    }

    const toggleAccordionSec = () => {
        setIsActiveSec(!isActiveSec);
    }

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const toggleDrawerVisibility = () => {
        setIsDrawerVisible(!isDrawerVisible);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    const onChanged = (colorId) => {

        const { color, id, image } = colorId
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = namesArray.findIndex(item => item.colorid === id);
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ colorid: id, childName: color, image, pagneName: lastSegment });
        }
        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
        setCheckedColors(namesArray.map(chip => chip.colorid));
    };

    const handleReset = () => {
        // setFilterColor([])
        localStorage.removeItem('chips')
        dispatch(addToChip([]));
        setCheckedColors([])
        fetchData();
        setStockToggle(0);
        setPriceRange([0, maxPrice])
        setSelectedDiscount('')
    }

    const Breadcrumb = (from, menuSlug) => {
        navigate(`${Routes.ProductCategory}/${categorySlug}`, { state: { from, menuSlug } },)
    }
    const [marks, setMarks] = useState({});

    useEffect(() => {
        if (maxPrice > 1) {
            const updatedMarks = {
                0: '0',
                [maxPrice]: {
                    style: {
                        color: '#000',
                    },
                    label: <strong>{maxPrice}</strong>,
                },
            };
            setMarks(updatedMarks);

        }
        localStorage.removeItem('compareIDS');
    }, [maxPrice]);

    const discount = {
        0: '10%',
        100: {
            style: {
                color: '#f50',
            },
            label: <strong>50%</strong>,
        },
    };

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const compareData = JSON.parse(localStorage.getItem('compareData'));
    const onChangeCheck = (comData, e) => {

        const { product_name = '', price = 0, id } = comData;
        const comimage = `${productList.product_path}${comData.productimages[0]?.file_name_120_x_120}`;

        const dataToStore = {
            product_name,
            price,
            comimage,
            id,
            slug
            // Adding 'id' to 'dataToStore'
        };
        if (e && e.target) {
            const isChecked = e.target.checked;
            // console.log('isChecked',isChecked);
            setIsCheckboxChecked(isChecked);
            const singleCompareId = JSON.parse(localStorage.getItem('compareData')) || {};
            let compareIDs = JSON.parse(localStorage.getItem('comp')) || [];

            if (!Array.isArray(compareIDs)) {
                compareIDs = [];
            }
            if (singleCompareId.id !== comData.id) {
                if (isChecked) {
                    // Checking if the ID is already present in the compareIDs array
                    const alreadyExists = compareIDs.some(item => item.id === comData.id);
                    if (!alreadyExists) {
                        compareIDs.push(dataToStore);
                    }
                } else {
                    // Removing the item with matching ID
                    compareIDs = compareIDs.filter(compareID => compareID.id !== comData.id);
                }
                if (comp.length + 1 <= 4 || isChecked === false) {
                    // console.log('comp.length',comp.length);
                    dispatch(addToCompare(compareIDs));
                    localStorage.setItem('comp', JSON.stringify(compareIDs));
                }
            }
        }

    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {

        navigate(`${Routes.Home}compareDetail`);
        // setIsModalOpen(true);
    };
    useEffect(() => {
        if (compare === true) {
            if (comp.length >= 4) {
                setIsModalOpen(true);
                setIsCheckboxChecked(true);
            } else if (comp.length >= 1) {
                setIsCheckboxChecked(true);
            }
        } else {
            setIsCheckboxChecked(false);
        }
    }, [comp, compare]); // Add comp and navigate to the dependency array
    const handleModalAction = () => {
        setIsModalOpen(false);
        let compareIDs = JSON.parse(localStorage.getItem('comp')) || [];
        compareIDs = compareIDs.filter(compareID => compareID.id !== compareIDs[3].id);
        dispatch(addToCompare(compareIDs));
        localStorage.setItem('comp', JSON.stringify(compareIDs));
    };

    const handleOk = () => {
        handleModalAction();
    };

    const handleCancel = () => {
        handleModalAction();
    };

    const formatTooltip = value => `${value}%`;
    const [gridLayout, setGridLayout] = useState('lg'); // Default layout is 'lg' (4 columns)

    const handleGridLayoutChange = (layout) => {
        setGridLayout(layout);
    };

    const renderGridIcons = () => (
        <div className="grid-icons">
            <div className="grid-icon" onClick={() => handleGridLayoutChange('sm')}>
                {/* First SVG (2 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 24H43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('md')}>
                {/* Second SVG (3 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 18H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M5 30H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M17 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M30 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('lg')}>
                {/* Third SVG (4 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="40" height="40" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M24 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M34 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M4 14H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 34H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 24H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );

    const getColSpan = () => {
        switch (gridLayout) {
            case 'sm':
                return { xs: 12, sm: 12 };
            case 'md':
                return { xs: 12, sm: 12, md: 8 };
            case 'lg':
                return { xs: 12, sm: 12, md: 8, lg: 6 };
            default:
                return { xs: 12, sm: 12, md: 8, lg: 6 };
        }
    };

    return (
        <>
            <div className="drinkware_main">
                <div className="drinkware_top_img_sec">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {category?.assign_slider !== null ? (
                            category?.assign_slider?.page_slider?.page_slider_images.map((slider, index) => (
                                <Carousel.Item key={`slider-${slider?.id}`}>
                                    {/* <img src={`${sliderPath}${slider.image}`} alt={slider.image_title} /> */}
                                    <ProductImage imgPath={sliderPath} imgName={slider.image} alt={slider.image_title} />
                                    <Carousel.Caption>
                                        <div className='giftly_heading_para'>
                                            <h1>{slider.image_title}</h1>
                                            <p>{slider.description}</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        ) : (
                            <Carousel.Item>
                                {/* <img src={category?.image ? `${categoryImgPath}${category?.image}` : `${categoryImgPath}${category?.image}`} alt='' /> */}
                                <ProductImage imgPath={categoryImgPath} imgName={category.image} alt={category.name} />
                                <Carousel.Caption>
                                    {slug === 'price'
                                        ? <>
                                            <div className='price_heading_para'>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>599</p>
                                                    </div>
                                                    <span>599 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>999</p>
                                                    </div>
                                                    <span>999 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>1599</p>
                                                    </div>
                                                    <span>1599 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>1999</p>
                                                    </div>
                                                    <span>1999 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>2599</p>
                                                    </div>
                                                    <span>2599 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>2999</p>
                                                    </div>
                                                    <span>2999 Store</span>
                                                </div>
                                                <div className='price_details'>
                                                    <div className='price_filterCont'>
                                                        <span>under</span>
                                                        <p>3599</p>
                                                    </div>
                                                    <span>3599 Store</span>
                                                </div>
                                            </div>
                                        </>
                                        : <div className='giftly_heading_para'>
                                            <h1>{category && category?.name}</h1>
                                            <p>{category && category?.description}</p>
                                        </div>
                                    }

                                </Carousel.Caption>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </div>
                <div className="path_drinkware">
                    <Link to="/">Home</Link>
                    <LiaGreaterThanSolid />
                    {from && (
                        <>
                            {/* <LiaGreaterThanSolid /> */}
                            <span onClick={() => navigate(`/${menuSlug}`)}>{from}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    {route && (
                        <>
                            {/* <LiaGreaterThanSolid /> */}
                            <span onClick={() => Breadcrumb(from, menuSlug)}>{route}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    <p>{category && category.name}</p>
                </div>
                <div className='path_sortSerch'>
                    <div className='filter_categoryPage'>
                        <div className="drinkware_sort">
                            <span> SORT BY :</span>
                            <Select
                                style={{
                                    width: 'auto',
                                    minWidth: 180,
                                }}
                                onChange={handleSelectChange}
                                value={selectedSorting}
                                placeholder="Select"
                                options={[
                                    {
                                        value: 'a-z',
                                        label: 'A-Z',
                                    },
                                    {
                                        value: 'z-a',
                                        label: 'Z-A',
                                    },
                                    {
                                        value: 'new_arrival',
                                        label: 'New Arrivals',
                                    },
                                    {
                                        value: 'high_to_low',
                                        label: 'Price: High to Low',
                                    },
                                    {
                                        value: 'low_to_high',
                                        label: 'Price: Low to High',
                                    }]}>
                                {/* {selectedSorting === null && <option disabled>Select</option>} */}
                            </Select>
                        </div>
                        <button onClick={toggleDrawerVisibility} className='filterPhone'>Filter</button>
                    </div>
                </div>
                <div className="drinkware_acc_carousel_section">
                    <div className='chips_designs'>
                        <div className='chips_filterContainer'>
                            <div className='accordian_filterCont'>
                                {newChipsData?.filter(item => item.type !== 'stockToggle')?.map((item, index) => (
                                    <div className='chipsDesign' key={`chip-${item.id}`}>
                                        {item?.image && <ProductImage imgPath={colorPath} imgName={item?.image} />} {item.childName} <CloseOutlined onClick={() => handleRemoveChildName(item.id ? 'id' :
                                            item.materialId ? 'materialId' :
                                                item.colorid ? 'colorid' :
                                                    item.priceRangeID ? 'priceRangeID' :
                                                        item.discountID ? 'discountID' :
                                                            item.type === 'stockToggle' ? 'stockToggle' : '',
                                            item.id || item.materialId || item.colorid || item.priceRangeID || item.type === 'stockToggle' || item.discountID
                                        )} />
                                    </div>
                                ))}
                            </div>
                            {newChipsData?.some(item => item.type !== 'stockToggle') && (
                                <button className='clearData_btn' onClick={handleReset}>Clear All</button>
                            )}
                        </div>
                        <div className="accordian_section">
                            <span>Categories: </span>
                            <div className="titles_acd">
                                <Collapse items={items}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} />
                            </div>
                            <div className="titles_acd">
                                <Collapse items={itemsMaterial(materialList)}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                                >
                                    {/* <Panel {...itemsMaterial(materialList)} /> */}
                                </Collapse>
                            </div>
                            <Accordion>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header onClick={toggleAccordion}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        Color
                                        {isActiveIcon ? <MinusOutlined /> : <PlusOutlined />}
                                    </span> </Accordion.Header>
                                    <Accordion.Body>
                                        {colorList !== null ? (
                                            <div className='color_imageCat'>
                                                <Checkbox.Group
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    value={checkedColors}
                                                >
                                                    <div className='color_checkoutbox'>
                                                        {colorList?.map((color) => (
                                                            <Checkbox checked={checkedColors?.includes(color.id)} onChange={() => onChanged(color)} key={color?.id} value={color?.id}> <img src={`${colorPath}${color?.image}`} alt={color?.color} /><p>{color?.color}</p></Checkbox>

                                                        ))}
                                                    </div>
                                                </Checkbox.Group>
                                            </div>
                                        ) : (
                                            <div>Loading...</div>
                                        )}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header onClick={toggleAccordionSec}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        Price
                                        {isActiveSec ? <MinusOutlined /> : <PlusOutlined />}
                                    </span> </Accordion.Header>
                                    <Accordion.Body>
                                        <div className='accordion_range'>
                                            <Slider
                                                range
                                                marks={marks}
                                                max={maxPrice}
                                                onChange={handlePriceChange}
                                                value={priceRange}
                                            // autoAdjustOverflow={false}
                                            // defaultValue={[0, maxPrice]} // Set default value
                                            />

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="titles_acd">
                                <Collapse items={discountItem(discountList)}
                                    expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                                >
                                    {/* <Panel {...discountItem(discountList)} /> */}
                                </Collapse>
                            </div>
                            <div className='out_stock_box'>
                                <span>Remove Out of Stock Items</span>
                                <Switch checked={stockToggle} onChange={removeOutOfStocks} />
                            </div>
                        </div>
                    </div>
                    <div className="right_image_glry">
                        <div className="dinner_slider">
                            {renderGridIcons()}
                            <Row gutter={[32, 32]}>
                                {isProLoading
                                    ?
                                    Array(8).fill(0).map((item, index) => {
                                        return (
                                            <Col key={`col-${index}`} xs={24} sm={12} md={8} lg={6}>
                                                <Skeleton.Image active={true} className='pro-sk-img' />
                                                <Skeleton paragraph={{
                                                    rows: 1,
                                                }} />
                                            </Col>
                                        )
                                    })
                                    : productList && productList.data.length > 0 ? (
                                        productList.data.map((product, index) => (
                                            <Col {...getColSpan()} key={`product-${product?.id}-${index}`}>
                                                <div className='dinner_sliderImg'>
                                                    {/* <Link key={index} to={`${Routes.ProductDetail}/${product.product_slug}`}> */}
                                                    <div className='dinnerCategory_cont'>
                                                        <Link to={`${Routes.ProductDetail}/${product.product_slug}`} state={{ dataSlug: category?.name, route, from, menuSlug, categorySlug, ProductId: product?.id }}>
                                                            {product && product.productimages && product.productimages.length > 0 ? (
                                                                <div className='imageContainer ' onClick={() => handleItem(product.product_slug, from, route, category?.name, menuSlug, categorySlug, product?.id)}>
                                                                    {/* <img src={`${productList.product_path}${product.productimages[0].file_name}`} alt={product.product_name} style={{ paddingTop: "0" }} /> */}
                                                                    <ProductImage imgPath={productList.product_path} imgName={product.productimages[0].file_name} alt={product.product_name} />
                                                                    {product.productimages[1]?.file_name &&
                                                                        // <img src={`${productList.product_path}${product.productimages[1]?.file_name}`} className='hoverImage' alt='' />
                                                                        <ProductImage imgPath={productList?.product_path} imgName={product?.productimages[1]?.file_name} alt={product?.product_name} className={'hoverImage'} />
                                                                    }

                                                                </div>
                                                            ) : (
                                                                <img src={dImg} alt="Placeholder" />
                                                            )}
                                                        </Link>

                                                        {product?.stock_quantity <= 0 ?
                                                            <div className="sold_off_chips">
                                                                <p>Sold Out</p>
                                                            </div> :
                                                            product?.discount > 0 && product?.discount !== null &&
                                                            <div className="off_chips">
                                                                <p>{product?.discount}% off</p>
                                                            </div>}

                                                        {compare === true ? (compareData && compareData.id !== product.id &&
                                                            <div className='campare_checkbox'>
                                                                <Checkbox onChange={(e) => onChangeCheck(product, e)} checked={comp.some(item => item.id === product.id)}>Compare</Checkbox>
                                                            </div>
                                                        ) : (
                                                            <Wishlist is_wishlist={product?.is_wishlist} pId={product.id} path={`${Routes.ProductDetail}/${product.product_slug}`} mode='HeartOutlined' />
                                                        )}

                                                        <AddToCart productList={product} imgPath={imgPath} routeName={Routes?.ProductCategory} slug={slug} />
                                                    </div>
                                                    {/* </Link> */}
                                                    <Link key={index} to={`${Routes.ProductDetail}/${product.product_slug}`} state={{ dataSlug: category?.name, route, from, menuSlug, categorySlug, ProductId: product?.id }}>
                                                        <div className='dinnerSlider_details'>
                                                            <p>{product.product_name}</p>
                                                            <div className='dinnerSliderSpan'>
                                                                <ProductPrice product={product} />
                                                            </div>
                                                        </div>

                                                    </Link>
                                                </div>
                                            </Col>
                                        ))
                                    ) : (
                                        <>
                                            <div className='noDataCont' style={{ marginTop: 60 }}>
                                                <img src={NoData} alt='' />
                                                <h5>Coming Soon</h5>
                                            </div>
                                        </>
                                    )
                                }
                            </Row>
                            {isCheckboxChecked && (
                                <div className='compare_button'>
                                    <button onClick={showModal}>Compare Product ({comp.length})</button>
                                </div>
                            )}
                        </div>
                    </div >
                </div >
                <ScrollComponent totalProductCount={productCount} slug={slug} />

                {/* <div>ScrollComponent
                    {showDiv && (
                        <div className='button_loading'>
                            <button><svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24 6V42" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /><path d="M12 18L24 6L36 18" stroke="#fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg> Plates - 12/458</button>
                        </div>
                    )}
                </div> */}
            </div >
            {/* <Drawer
                title="Filters"
                placement="right"
                closable={true}
                onClose={closeDrawer}
                open={isDrawerVisible}
                width={380}
                height={100}
                destroyOnClose={true}
                maskClosable={true}
                drawerStyle={{ zIndex: 1000 }}
                className='filter_drawer_open'
                footer={
                    <div>
                        <Button>
                            Apply Filters
                        </Button>
                        <Button>Cancel</Button>
                    </div>
                }
            >
                <div className="drinkware_acc_carousel_section">
                    <div className="accordian_section">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Categories :</Accordion.Header>
                                <Accordion.Body>
                                    <div className="titles_acd">
                                        <Collapse items={items}
                                            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header onClick={toggleAccordion}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    Colors :
                                    {isActiveIcon ? <MinusOutlined /> : <PlusOutlined />}
                                </span> </Accordion.Header>
                                <Accordion.Body>
                                    {colorList !== null ? (
                                        <div className='color_imageCat'>
                                            <Checkbox.Group onChange={onChoose} value={selectedColor} >
                                                {colorList.map((color) => (
                                                    <Checkbox key={color.id} value={color.id} className={`color${color.color} ${selectedColor === color.id ? 'checked' : ''}`}><span>{color.color}</span></Checkbox>
                                                ))}
                                            </Checkbox.Group>
                                        </div>
                                    ) : (
                                        <div>Loading...</div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header onClick={toggleAccordionSec}> <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    Price :
                                    {isActiveSec ? <MinusOutlined /> : <PlusOutlined />}
                                </span> </Accordion.Header>
                                <Accordion.Body>
                                    <div className='accordion_range'>
                                        <Slider
                                            range
                                            marks={marks}
                                            max={5000}
                                            onChange={handlePriceChange}
                                            value={priceRange}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Drawer> */}

            <FilterDrawer isDrawerVisible={isDrawerVisible} closeDrawer={closeDrawer} items={items} colorList={colorList} materialList={materialList} itemsMaterial={itemsMaterial} checkedColors={checkedColors} colorPath={colorPath} onChanged={onChanged} isActiveSec={isActiveSec} marks={marks} maxPrice={maxPrice} handlePriceChange={handlePriceChange} priceRange={priceRange} discountItem={discountItem} discountList={discountList} stockToggle={stockToggle} removeOutOfStocks={removeOutOfStocks} />

            <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null} className='comapreSlected'>
                <p>You've reached the limit! Only four items can be compared at once.</p>
                <div className='threeProd_okay'>
                    <button onClick={handleOk}>Okay, I understand</button>
                </div>
            </Modal>
        </>
    )
}

export default ProductCategory