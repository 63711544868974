import React, { useState, useEffect } from 'react';
import ReactStars from "react-rating-stars-component";
import { Collapse } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import ProductDetailPinCode from './ProductDetailPinCode';
import ProductDetailVariant from './ProductDetailVariant';
import ProductPrice from '../ProductPrice';
import ProductDetailButtonBox from './ProductDetailButtonBox';
import ProductCoupon from '../ProductCoupon';

const ProductDetailSlider = ({ responseData, setActiveTab, country, handleCompare, showDrawer}) => {
  
    const productDetails = responseData?.data;
    const ratingData = responseData?.rating || 0;

    const [defaulColor, setDafaultColor] = useState("");
    useEffect(() => {
         if (responseData?.is_variant === 0 &&responseData?.product_attribute.length > 0) {
            let defaultColorName = responseData?.product_attribute?.find(
                (item) => item.attr_type_id === 2
            )?.product_attr_value?.attr_val_name;
            setDafaultColor(defaultColorName);
        }
    }, [responseData]);

    const handleRatingClick = () => {
        setActiveTab("reviews");
    };

    const renderStockStatus = () => (
        <p>
            {productDetails?.stock_quantity !== 0 ? (
                <span>{productDetails?.stock_quantity} in stock</span>
            ) : (
                <div className="sold_produ_chips">
                    <span>Sold Out</span>
                </div>
            )}
        </p>
    );

    const renderRating = () => (
        <div className="rating_testimonail">
            Rating:
            {ratingData !== null ? (
                <>
                    <ReactStars
                        count={5}
                        key={`react-stars-${ratingData?.StarRating}`}
                        value={parseFloat(ratingData?.StarRating)}
                        size={20}
                        isHalf={true}
                        activeColor="#ffd700"
                        color="#d1d1d1"
                        edit={false}
                    />
                    <span onClick={handleRatingClick} style={{ cursor: "pointer" }}>
                        ({ratingData?.Rating})
                    </span>
                </>
            ) : (
                <span>Loading rating...</span>
            )}
        </div>
    );

    const renderPriceSection = () => (
        <div className="price">
            <ProductPrice product={productDetails} />
            {productDetails?.discount !== 0 && productDetails?.discount !== null && (
                <div className="off_chipsDetail">
                    <span>{productDetails?.discount}% off</span>
                </div>
            )}
        </div>
    );

    const textInfo = (
        <div className="SubCategory">
            <div className="careInstruction">
                <h4>Product Details</h4>
                <div className="">
                    {productDetails && (
                        <div
                            className=""
                            dangerouslySetInnerHTML={{ __html: productDetails.additional_description }}
                        />
                    )}
                </div>
            </div>
        </div>
    );

    const text = (
        <div className="SubCategory">
            {productDetails?.producttypes?.type_name && (
                <div className="prodcut_collapse_items">
                    <p>Type : </p>
                    <span>{productDetails.producttypes?.type_name}</span>
                </div>
            )}
            {defaulColor && (
                <div className="prodcut_collapse_items">
                    <p>Color : </p>
                    <span>{defaulColor}</span>
                </div>
            )}
            {productDetails?.productmaterials?.material_name && (
                <div className="prodcut_collapse_items">
                    <p>Material : </p>
                    <span>{productDetails?.productmaterials?.material_name}</span>
                </div>
            )}
            {productDetails?.Feature && (
                <div className="prodcut_collapse_items">
                    <p>Feature : </p>
                    <span>{productDetails?.Feature}</span>
                </div>
            )}
            {productDetails?.pr_variant?.product_size?.size && (
                <div className="prodcut_collapse_items">
                    <p>Size : </p>
                    <span>{productDetails.pr_variant.product_size.size}</span>
                </div>
            )}
            {productDetails?.pr_variant?.product_color?.color && (
                <div className="prodcut_collapse_items">
                    <p>Color : </p>
                    <span>{productDetails.pr_variant.product_color.color}</span>
                </div>
            )}
            {productDetails?.pack_contain && (
                <div className="prodcut_collapse_items">
                    <p>Pack Contain : </p>
                    <span>{productDetails.pack_contain}</span>
                </div>
            )}
            {productDetails?.description && (
                <p dangerouslySetInnerHTML={{ __html: productDetails.description }}></p>
            )}
        </div>
    );

    const items = [
        {
            key: "1",
            label: " Product Description",
            children: <p>{text}</p>,
        },
        productDetails?.additional_description && {
            key: "2",
            label: "Additional Information",
            children: <p>{textInfo}</p>,
        },
    ].filter(Boolean);

    const onChange = (key) => {
        // console.log(key);
    };
    return (
        <>
        
            <div className="headding">
                <h1>{productDetails?.product_name}</h1>
            </div>
            <div className="testimonial">
                {productDetails?.stock_quantity < 3 && renderStockStatus()}
                {renderRating()}
            </div>
            {renderPriceSection()}
            <div className="button-box">
              <ProductDetailButtonBox responseData={responseData}/>
                <div className="comapre_buton">
                    <button onClick={handleCompare}>Compare</button>
                </div>
            </div>
            <div className="bulk_linkDetails mb-2">
                Want to buy this in bulk?
                <span onClick={() => showDrawer(productDetails?.id)}> Click here</span>
            </div>
            <ProductDetailVariant responseData={responseData}/>
            {country === "IN" && <ProductDetailPinCode />}
            {/* ProductCoupon */}
            
           {productDetails&&<ProductCoupon productDetails={productDetails}/>} 
            
           
            <div className="product_collapse">
                <Collapse
                    onChange={onChange}
                    items={items}
                    expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
                />
            </div>
            </>
    );
};

export default React.memo(ProductDetailSlider);
