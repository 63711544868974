import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from 'react-slick';
import { useSelector } from "react-redux";
import Lottie from 'react-lottie';
import {
  getSavingProductList
} from "../../services/home";
import dImg from '../../assets/images/d.png';
// import img1 from '../../assets/images/drinkwarecup.png';
// import img2 from '../../assets/images/drinkwarecup1.png';
// import img3 from '../../assets/images/drinkwarecup2.png';
// import img4 from '../../assets/images/drinkwarecup3.png';
import LoaderLottie from '../../assets/images/loader.json';
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";
import AddToCart from "../../function/AddToCart";
import ProductPrice from "../ProductPrice";
import ProductImage from "../ProductImage";

// Extract arrow component
const SliderArrow = React.memo(({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
));

// Default options for Lottie loader
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Extract loading spinner component
const LoadingSpinner = React.memo(() => (
  <Lottie options={defaultOptions} height={150} width={200} />
));

// Slider settings
const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const OfferItem = () => {
  const user = useSelector((state) => state.user);
  const [sellerList, setSellerList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgPath, setImgPath] = useState(null);
  const sliderRef = useRef(null);
  const hasMounted = useRef(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('type', '1');
      const response = await getSavingProductList(formData, user?.token);
      setSellerList(response);
      setImgPath(response.path);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }, [user?.token]);

  useEffect(() => {
    if (!hasMounted.current) {
      fetchData();
      hasMounted.current = true;
    }
  }, [fetchData]);

  const handlePrevClick = useCallback(() => sliderRef.current?.slickPrev(), []);
  const handleNextClick = useCallback(() => sliderRef.current?.slickNext(), []);

  return (
    sellerList?.data?.length > 0 && (
      <section className='best_seller_slider'>
        <div className="container-fluid">
          <div className='row'>
            <h3 className='store_headings'>Kairaus’s Savings Bonanza</h3>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className='dinner_slider'>
                <Slider {...sliderSettings} ref={sliderRef}>
                  {sellerList?.data.slice(0, 9).map((sellerItem) => (
                    <div className='dinner_sliderImg' key={sellerItem?.id}>
                      {sellerItem?.productimages?.length > 0 ? (
                        <div className="imageContainer">
                          <Link to={`/product/${sellerItem?.product_slug}`} state={{ ProductId: sellerItem?.id }}>
                          <ProductImage imgPath={sellerList.path} imgName={sellerItem?.productimages[0]?.file_name} alt={`Image for ${sellerItem?.product_name}`} />
                          {sellerItem?.productimages[1]?.file_name&&<ProductImage imgPath={sellerList.path} imgName={sellerItem?.productimages[1]?.file_name} alt={`Image for ${sellerItem?.product_name}`} className={'hoverImage'}/>}
                          </Link>
                          {sellerItem?.stock_quantity <= 0 ? (
                            <div className="sold_off_chips">
                              <p>Sold Out</p>
                            </div>
                          ) : (
                            sellerItem?.discount !== 0 &&
                            <div className="off_chips">
                              <p>{sellerItem?.discount}% off</p>
                            </div>
                          )}
                          <Wishlist 
                            is_wishlist={sellerItem?.is_wishlist} 
                            pId={sellerItem?.id} 
                            path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} 
                            mode='HeartOutlined' 
                          />
                          <AddToCart 
                            productList={sellerItem} 
                            p_id={sellerItem?.id} 
                            imgPath={imgPath} 
                          />
                        </div>
                      ) : (
                        <img src={dImg} alt="Placeholder" />
                      )}
                      <div className='dinnerSlider_details'>
                        <Link to={`/product/${sellerItem?.product_slug}`} state={{ ProductId: sellerItem?.id }}>
                          <p>{sellerItem?.product_name}</p>
                        </Link>
                        <div className='dinnerSliderSpan'>
                          <ProductPrice product={sellerItem} />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <button className="common_btns" onClick={() => navigate(Routes.BigSaving)}>View More</button>
              </div>
            )}
            <div className="slider-arrow">
              <SliderArrow direction="prev" onClick={handlePrevClick} />
              <SliderArrow direction="next" onClick={handleNextClick} />
            </div>
          </div>
        </div>
      </section>
    )
  );
};

export default OfferItem;
