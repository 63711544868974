const Routes = {
  Home: '/',
  MenuPlates: "/",
  ProductCategory: "/collections",
  Collection: '/collections',
  ProductDetail: '/product',
  BlogCategory: '/blogs',
  BlogDetails: '/blog',
  AboutUs: '/about-us',
  Faqs: '/faqs',
  Checkout: "/checkout",
  Payment: "/payment",
  ThankuCheck: "/thanku-check",
  SignIn: "/signin",
  SignUp: "/signup",
  ThankYouSign: "/thankyou-signup",
  AccountProfile: "/account-profile",
  NewArrivalProducts: "/newarrival",
  ContactUs: "/contact-us",
  Forgotpassword: "/forgot-password",
  ResetPassword: '/reset-password',
  // OrderPage: '/orderPage',
  AllProduct: '/allProduct',
  PaymentFailed: '/payment-failed',
  BestSeller: "/bestseller",
  productByColor: "/product-by-color",
  Shipping: '/shipping-&-returns-policy',
  Privacy: '/privacy-policy',
  Exchanges: '/exchanges',
  TermCondition: '/terms-&-conditions',
  CompareDetail: '/compareDetail',
  Viewed: '/viewed',
  Rewards: '/loyalty-program',
  BigSaving: '/bigsavings',
  // Rewards : '/rewards',
  Confirmation: '/confirmation',
  FinestProduct: '/finest-product',
  ReturnPage: '/return-page',
  Error: 'error',
  AllItems: '/collections/all-items',
  OurStory: '/Our-story',
  ExchangeRate: '/exchangerate',
  OccasionCards:'/occasionCards',
  Iframe:'/iframe'
}
export default Routes;