import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductImage = ({ imgPath, imgName, alt, className}) => {
  return (
    <LazyLoadImage
      alt={alt}
      src={`${imgPath}${imgName}`}
      className={className} 
    />
  );
};

export default ProductImage;
