import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd';
import ProfilePage from '../accountDetails/ProfilePage';
import AddressPage from './AddressPage';
import GstInfo from './GstInfo';
import CouponsPage from '../accountDetails/CouponsPage';
import FavouritePage from './FavouritePage';
import ChangePassword from "./ChangePassword";
import { useLocation, useNavigate } from 'react-router-dom';
import OrderPage from './OrderPages';

function AccountProfile() {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Profile');
    const [userName, setUserName] = useState('');
    const { state } = useLocation();


    useEffect(() => {
        if (state && state.from) {
            setActiveTab(state.from)
        }
        window.scrollTo(0, 0);
    }, [state])
    const handleChange = (key) => {
        setActiveTab(key);
        navigate('', { state: { from: key } });
    }



    // const renderTabContent = () => {
    //     switch (activeTab) {
    //         case 'Profile':
    //             return <ProfilePage setUserName={setUserName} />;
    //         case 'orders':
    //             return <OrderPage />;
    //         case 'addresses':
    //             return <AddressPage />;
    //         case 'gstInfo':
    //             return <GstInfo />;
    //         case 'coupons':
    //             return <CouponsPage />;
    //         case 'favourites':
    //             return <FavouritePage />;
    //         case 'changePassword':
    //             return <ChangePassword />;
    //         default:
    //             return null;
    //     }
    // };

    // const handleItemCLick = (dropDownItem) => {
    //     if (dropDownItem === "logout") {
    //         localStorage.removeItem('user');
    //         dispatch(clearUser());
    //         navigate(Routes.SignIn);
    //     }
    //     else {
    //         navigate(Routes.OrderPage, { state: { from: dropDownItem } });
    //     }
    // };

    const items = [
        {
            key: 'Profile',
            label: <span>Profile</span>,
            children: <ProfilePage setUserName={setUserName} />,
        },
        {
            key: 'orders',
            label: <span>My Orders</span>,
            children: <OrderPage />,
        },
        {
            key: 'addresses',
            label: <span>Addresses</span>,
            children: <AddressPage />,
        },
        {
            key: 'gstInfo',
            label: <span>GST Information</span>,
            children: <GstInfo />,
        },
        {
            key: 'coupons',
            label: <span>Coupons</span>,
            children: <CouponsPage />,
        },
        {
            key: 'favourites',
            label: <span>Favourites</span>,
            children: <FavouritePage />,
        },
        {
            key: 'changePassword',
            label: <span>Change Password</span>,
            children: <ChangePassword />,
        }
    ];

    return (
        <>
            <div className='account_profile'>
                <div className='accounts_pageOrder'>
                    <div>
                        <h1>Account</h1>
                        <p>{userName}</p>
                    </div>
                </div>

                <div className='account_profile_tabs'>
                    <div className='account_masterTabs'>
                        <Tabs tabPosition="left"
                            activeKey={activeTab}
                            items={items}
                            onChange={handleChange}>
                            {/* <items tab={<span>Profile</span>} key="Profile" />
                            <items tab={<span>My Orders</span>} key="orders" />
                            <items tab={<span>Addresses</span>} key="addresses" />
                            <items tab={<span>GST Information</span>} key="gstInfo" />
                            <items tab={<span>Coupons</span>} key="coupons" />
                            <items tab={<span>Favourites</span>} key="favourites" />
                            <items tab={<span>Change Password</span>} key="changePassword" /> */}
                        </Tabs>
                    </div>
                    <div className="acoountContent_wrapper">{items.find(tab => tab.key === activeTab)?.children}</div>
                </div>
            </div>
        </>
    )
}

export default AccountProfile