import React, { useEffect, useState } from 'react';
import { Drawer, Radio, Checkbox, Tooltip, Modal, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { FaGreaterThan } from 'react-icons/fa6';
import frame from '../../assets/images/Frame.png';
import DrawerCuppon from './DrawerCuppon';
import ProceedSection from './ProceedSection';
import DrawerCartProduct from './DrawerCartProduct';
import Routes from '../../Routes/Routes';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getCouponList } from '../../services/coupon/Coupon';
import NoCartimg from '../../assets/images/nocart.png'
import { STRINGS } from '../../constants/Constant';
import { addToCartId } from '../../reducers/cartIdSlice';
import CalculateTotals from '../../utils/cartUtils';
import DeleteImg from '../../assets/images/delete.png'
import trashLottie from '../../assets/images/trash-lottie.json'
import Lottie from 'react-lottie'
import { addToCart, clearCart } from '../../reducers/cartSlice';
import { deleteCart, updateCartStatus } from '../../services/cart/addCart';
import { applyCoupon } from '../../reducers/couponSlice';
import { getRewardsPoints } from '../../services/rewardpoints/getRewards';
import { clearUser } from '../../reducers/userSlice';
import { applyRewardPoint, cartAmountList, getApplyCouponData, toggleFirstCoupon } from '../../services/coupon/applyCoupon';
import { debounce } from 'lodash';
import couponsImg from '../../assets/images/couponsImg.jpg';
import { CloseOutlined } from '@ant-design/icons';
import { setFirstOrderCoupon } from '../../reducers/firstOrderCouponSlice';


const DrawerCart = ({ onClose, openDrawer }) => {
  // console.log('openDrawer',openDrawer);
  const { total = { totalComparePrice: 0, totalCount: 0, totalPrice: 0 }, disAmount = 0, grandTotal = 0, bagDiscount, firstOrderCouponDiscount = 0 } = CalculateTotals();
  const [initialCoupons, setInitialCoupons] = useState([]);
  const [openCupponDrawer, setOpenCupponDrawer] = useState(false);
  const cart = useSelector((state) => state.cart);
  const couponDis = useSelector((state) => state.couponDis);
  const firstOrderCoupon = useSelector((state) => state.firstOrderCoupon);
  const totalProductCount = cart.reduce((total, item) => total + item.count, 0);
  const cartIds = useSelector((state) => state.cartId);
  const couponSlice = useSelector((state) => state.coupon);
  const dispatch = useDispatch();
  const [rewardPointsChecked, setRewardPointsChecked] = useState(false);
  const [points, setPoints] = useState(0);
  const [rewardPoints, setRewardPoints] = useState(null)
  let firstOrderCouponDetail = localStorage.getItem('FirstOrderCouponDetails');
  const excRate = useSelector(state => state.excrate);
  const user = useSelector((state) => state.user);
  const { token } = user;
  const navigate = useNavigate();
  const [cartPriceList, setCartPriceList] = useState(null);
  const [isFirstCoupon, setIsFirstCoupon] = useState(false);

  firstOrderCouponDetail = JSON.parse(firstOrderCouponDetail);

  useEffect(() => {
    const debouncedGetCartAmountList = debounce(async () => {
      try {
        const response = await cartAmountList(token);
        if (response.status !== "1") return;

        setCartPriceList(response);

        const { reward_discount } = response;
        let adjustedDiscount = reward_discount;
        setRewardPointsChecked(adjustedDiscount > 0);
        setPoints(adjustedDiscount);
      } catch (error) {
        // Handle error (optional: log or show error message)
      }
    }, 500); // Debounce with a delay of 500ms
    if (token && openDrawer) {
      debouncedGetCartAmountList();
    }

    return () => {
      debouncedGetCartAmountList.cancel(); // Clean up the debounced function
    };
  }, [token, cart, couponDis, openDrawer, firstOrderCoupon]); // Ensure dependencies are correct
  // Keep dependencies if necessary
  const handleRewardPointsChange = async () => {
    if (!cart.some(item => item.isChecked)) return;
    const response = await applyRewardPoint(token);
    if (response.status !== '1') {
      setRewardPointsChecked(false);
      setPoints(response.discount);
      // return;
    }
    const amntListResponse = await cartAmountList(token);
    if (amntListResponse.status !== '1') return;
    setCartPriceList(amntListResponse);
    const { reward_discount } = amntListResponse;
    setRewardPointsChecked(reward_discount > 0);
    setPoints(reward_discount);
  };

  // Function to handle reward points fetching
  const fetchRewardPoints = async () => {
    try {
      const response = await getRewardsPoints(user.token);
      if (response?.status === '1') {
        setRewardPoints(response?.data);
      }
    } catch (error) {
      if (error.message === "Invalid token") {
        localStorage.removeItem('user'); // Remove only the 'user' key from local storage
        dispatch(clearUser());
        navigate(Routes.SignIn, { state: { from: Routes.Home } }); // Redirect to login page with state
      } else {
        console.error('Error fetching reward points:', error);
      }
    }
  };
  // Function to handle the application of the first order coupon
  const applyFirstOrderCoupon = async (couponCode) => {
    try {
      const formData = new URLSearchParams();
      formData.append('coupon_code', couponCode);
      await getApplyCouponData(formData, user.token);
    } catch (error) {
      console.error('Error applying first order coupon:', error);
    }
  };

  useEffect(() => {
    const debouncedEffect = debounce(() => {
      if (user.token && openDrawer) {
        fetchRewardPoints();
      }

      const { data, order_count } = (JSON.parse(localStorage.getItem('FirstOrderCouponDetails'))) || {};
      if (user.token && openDrawer && cart.length > 0 && order_count == 0) {
        applyFirstOrderCoupon(data?.coupon_code);
      }
    }, 50);

    debouncedEffect();

    // Cleanup function to clear timeout if the component unmounts or dependencies change
    return () => {
      clearTimeout(debouncedEffect);
    };
  }, [openDrawer]);
  //

  const handleCouponClick = async () => {

    if (cart.length > 0) {
      if (user && user.id) {
        setOpenCupponDrawer(true);
        try {
          const response = await getCouponList(user.token);
          const responseData = response?.data || [];
          const transformedCoupons = responseData.map((coupon) => ({
            id: coupon.id,
            coupon_code: coupon.coupon_code,
            coupon_type: coupon.coupon_type,
            type_val: coupon.type_val,
            coupon_desc: coupon.coupon_desc,
            coupon_allow: coupon.coupon_allow,
            applied: coupon.is_coupon === 1 ? true : false,//applied: couponSlice.id === coupon.id ? true : false, //old data
            removed: false,
            showRemoveConfirmation: false,
            from_date: coupon.from_date,
            to_date: coupon.to_date,
            min_amount: coupon.min_amount,
            upto_amount: coupon.upto_amount
          }));

          setInitialCoupons(transformedCoupons);
          // Extract coupon codes from responseData
          const couponCodes = responseData.map(coupon => coupon.coupon_code);

          // Store all coupon codes in session storage
          sessionStorage.setItem('couponCodes', JSON.stringify(couponCodes));
        } catch (error) {
          // console.error('Error fetching coupon data:', error);
          if (error.message === "Invalid token") {
            // Handle invalid token scenario
            localStorage.removeItem('user'); // Remove only the 'user' key from local storage
            dispatch(clearUser());
            navigate(Routes.SignIn, { state: { from: Routes.Home } }); // Redirect to login page with state
          } else {
            console.error('Error adding to wishlist:', error);
            // Handle other errors gracefully
          }
        }
      } else {
        onClose();
        navigate(Routes.SignIn, { state: { from: Routes.Checkout } });
      }
    } else {
      onClose();
      navigate(Routes.Home);
      toast.error(STRINGS.NO_PRODUCT_IN_CART_CONTINUE_SHOPPING);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    if (cartIds.length > 0) {
      setIsModalOpen(true);
    }
  };
  const handleOk = async () => {
    const filteredCart = cart.filter((cartItem) => cartIds.includes(cartItem.id));

    const updatedCart = cart.filter((item) => !cartIds.includes(item.id));

    if (!user.id) {
      localStorage.setItem('cart', JSON.stringify(updatedCart));
      dispatch(addToCart(updatedCart));
    }

    if (filteredCart.length > 0) {
      try {
        const deleteOperations = filteredCart.map(async (product) => {
          const formData = new URLSearchParams();
          formData.append('id', product.id);

          const responseDel = await deleteCart(formData);
          const deleteCartStatus = responseDel.data.status;
          if (deleteCartStatus === "1") {
            return product.id; // Return the ID of the successfully deleted product
          } else {
            return product.id;
          }
        });

        const deletedProductIds = await Promise.all(deleteOperations);

        // Update local storage and state after all delete operations are completed
        const updatedCart = cart.filter((item) => !deletedProductIds.includes(item.id));

        if (updatedCart.length > 0) {
          localStorage.setItem('cart', JSON.stringify(updatedCart));
          dispatch(addToCart(updatedCart));
        } else {
          const couponnull = {};
          dispatch(applyCoupon(couponnull));
          localStorage.setItem('coupon', JSON.stringify(couponnull));
          localStorage.removeItem("cart");
          localStorage.removeItem("shippingFees")
          dispatch(clearCart());
        }
        toast.success(STRINGS.REMOVED_SUCCESSFULLY);
      } catch (error) {
        console.error("Deleting Error:", error);
      }
    }

    setIsModalOpen(false);
  };
  const handleCancel = async () => {
    setIsModalOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: trashLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const [selectAllChecked, setSelectAllChecked] = useState(true);
  const [allProductIds, setAllProductIds] = useState([]);

  useEffect(() => {
    const productIds = cart.map((product) => product.id);
    setAllProductIds(productIds);
  }, [cart]);
  //handle single id
  const handleUpdateCartStatus = async (pId, isChecked) => {
    try {
      const products = [{
        product_id: pId,
        status: isChecked ? "0" : "1",
      }];

      await updateCartStatus(products, user.token);
    } catch (error) {
      console.error('Failed to update cart status:', error);
    }
  };
  //handle multipal ids
  const handleSelectUpdateCartStatus = async (pIds, isChecked) => {
    try {
      const products = pIds.map(pId => ({
        product_id: pId,
        status: isChecked ? "0" : "1",
      }))
      await updateCartStatus(products, user.token);
    } catch (error) {
      console.error('Failed to update cart status:', error);
    }
  };

  //
  // const cart = useSelector(state => state.cart);

  const closeDrawerCart = (e) => {
    const className = e?.target?.className;
    setOpenCupponDrawer(false)
    if (className === undefined) {
      onClose();
    }
  };
  const [showGSTForm, setShowGSTForm] = useState(false);
  const isINR = excRate?.currencyCode === "INR";
  const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || '$';
  if (!cartPriceList) return null; // Early return if cartPriceList is not available

  const {
    currency_symbol,
    bag_mrp,
    kairaus_saving,
    orderdiscount_coupon_code,
    order_discount,
    first_order_discount,
    total_amt,
    first_order_discount_coude
  } = cartPriceList
  const { id } = JSON.parse(localStorage.getItem('FirstOrderCouponDetails'))?.data || {};
  const handleFirstOrder = async () => {
    const formData = new URLSearchParams({ coupon_id: id });
    try {
      const response = await toggleFirstCoupon(formData, user.token);

      if (response?.status === "1") {
        setIsFirstCoupon(response.data.applied_status === 1);
        dispatch(setFirstOrderCoupon(response.data.applied_status === 1));
      }
    } catch (error) {
      console.error('Error toggling first coupon:', error);
    }
  };
  //

  const handleSelectAll = (flag) => {
    // Update the 'isCheck' field for all cart items based on the flag
    const updatedCart = cart.map(item => ({
      ...item,
      isChecked: !flag // Invert the flag to update 'isCheck'
    }));
    dispatch(addToCart(updatedCart));

    // Update the cart status based on the flag
    handleSelectUpdateCartStatus(allProductIds, flag);
    // Toggle the select all checked state
    setSelectAllChecked((prev) => !prev);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
  };
 
  const handleCardClick = (p_id) => {
    let isChecked = false;
  
    // Toggle isChecked for the selected product and update the cart
    const updatedCart = cart.map(item => {
      if (item.id === p_id) {
        isChecked = !item.isChecked;  // Capture the toggled value
        return { ...item, isChecked };
      }
      return item;
    });
  
    // Update cart status based on the toggled isChecked value
    handleUpdateCartStatus(p_id, !isChecked);
  
    // Update the select all checkbox
    setSelectAllChecked(updatedCart.some(item => item.isChecked));
  
    // Save the updated cart in localStorage and dispatch it to the Redux store
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    dispatch(addToCart(updatedCart));
  };
  
  return (
    <>
      <Drawer
        title={`Your Cart (${totalProductCount})`}
        placement='right'
        onClose={onClose}
        open={openDrawer}
        width={600}
        className="addToCart_drawer"
      >
        {cart.length === 0 ? (
          <div className='noDataCont' onClick={onClose}>
            <img src={NoCartimg} alt='' />
            <h5> No Product In cart.</h5>
            <Link to={Routes.Home}>Continue Shopping</Link>
          </div>
        ) : (
          <div className='drawer_cupns_space'>
            <div className='drwawer_margins'>
              {user?.id &&
                rewardPoints?.total_points >= 100 && (
                  <div className='coupan_area'>
                    <div className='copan_left'>
                      <Radio checked={rewardPointsChecked} onClick={handleRewardPointsChange} />
                      <p>{rewardPoints?.total_points} Reward Points</p>
                    </div>
                    <div className='copan_right'>
                      <button onClick={handleRewardPointsChange}> {points !== null ? 'Remove' : 'Apply'}</button>
                    </div>
                  </div>
                )
              }
              {first_order_discount > 0 && firstOrderCouponDetail?.order_count === 0 && (
                <div className='sticky_div'>
                  <div className='img_fixed'>
                    <img src={couponsImg} alt="couponsImg" className='couponsImg' />
                    <div className='coupon_number'>
                      {firstOrderCouponDetail?.data?.type_val}
                      {firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type} <br />Off
                    </div>
                    <div className='coupons_details'>
                      <h4>FIRSTBUY</h4>
                      <span>Get a special discount with our First Buy Coupon! Shop now and save on your first purchase!</span>
                    </div>
                    <Tooltip title="Delete Coupon" placement="bottom" onClick={handleFirstOrder}>
                      <div className='close_iocn'>
                        <CloseOutlined />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className='select_allProduct'>
                <div className='selected_container'>
                  <Checkbox checked={selectAllChecked} onChange={() => handleSelectAll(selectAllChecked)} />
                  <span>{`${cart.filter(item => item.isChecked).length} / ${cart.length} Items Selected`}</span>

                </div>
                <div className='icons_selected'>
                  {/* <Tooltip title="Move To Favourite">
                    <HeartOutlined onClick={handleWishlist} />
                  </Tooltip> */}
                  <Tooltip title="Delete Item">
                    <img src={DeleteImg} onClick={showModal} alt='' />
                  </Tooltip>
                </div>
              </div>
              {cart.map((product, index) => (
                <DrawerCartProduct
                  key={index}
                  product={product}
                  onClose={onClose}
                  handleCardClick={handleCardClick}
                  checked={selectAllChecked}
                  setSelectAllChecked={setSelectAllChecked}
                  onChange={handleSelectAll}
                />
              ))}
              <div className='coupan' onClick={handleCouponClick}>
                <div className='left_Coupon'>
                  <img src={frame} alt='img' />
                </div>
                <div className='right_Coupon'>
                  <p>Check Out More Vouchers</p>
                </div>

                <div className='icon_nex_cupn'>
                  <FaGreaterThan />
                </div>
              </div>
              {cartPriceList ?
                (
                  <div className='prize_detail'>
                    <h4>Price Details</h4>
                    <div className='prize_dra'>
                      <p>Bag MRP {`(${total.totalCount} items)`}</p>
                      <p>{currency_symbol} {bag_mrp.toFixed(2)}</p>
                    </div>
                    {kairaus_saving > 0 && (<div className='prize_dra'>
                      <p>Kairaus Savings</p>
                      <p>- {currency_symbol} {kairaus_saving.toFixed(2)}</p>
                    </div>)}

                    <div className='prize_dra' onClick={handleCouponClick}>
                      <div className='extra_coupons'>
                        <p>Coupon Discount</p>
                        {orderdiscount_coupon_code && (
                          <span>({orderdiscount_coupon_code})</span>
                        )}
                      </div>

                      {order_discount > 0 ? (
                        <p>- {order_discount.toFixed(2)}</p>
                      ) : (

                        <span onClick={handleCouponClick}>Apply Coupon</span>

                      )}
                    </div>
                    <div className='prize_dra'>

                      {first_order_discount > 0 && (
                        <>
                          <p>
                            First Order Discount ({first_order_discount_coude})
                          </p>
                          <p>- {currency_symbol} {first_order_discount}</p>
                        </>
                      )}

                    </div>
                    {/* <div className='prize_dra'>
                  <p>Shipping</p>
                  <p>Free</p>
                </div> */}
                    {
                      points > 0 && typeof points === 'number' && (
                        <div className='prize_dra'>
                          <p>Reward Points Discount</p>
                          <p>-₹ {points.toFixed(2)}</p>
                        </div>
                      )
                    }

                    <div className='prize_dra'>
                      <p className='p_bold'>Total</p>
                      <p className='p_bold'>{currency_symbol}{total_amt.toFixed(2)}</p>
                      {/* <p className='p_bold'>₹{FirstOrderCouponDiscount(grandTotal.toFixed(2))}</p> */}
                    </div>

                  </div>
                ) : (
                  <div className='prize_detail'>
                    <h4>Price Details</h4>
                    <div className='prize_dra'>
                      <p>Bag MRP {`(${total.totalCount} items)`}</p>
                      <p>{isINR ? "₹" : currencySymbol} {total.totalComparePrice.toFixed(2)}</p>
                    </div>
                    {
                      bagDiscount > 0 && (<div className='prize_dra'>
                        <p>Kairaus Savings</p>
                        <p>{isINR ? "- ₹" : `- ${currencySymbol}`} {bagDiscount ? `${bagDiscount.toFixed(2)}` : 0}</p>
                      </div>)
                    }

                    <div className='prize_dra' onClick={handleCouponClick}>
                      <div className='extra_coupons'> <p>Coupon Discount</p><span>({couponSlice?.coupon_code})</span></div>
                      {disAmount > 0 ? (
                        <p> {isINR ? "- ₹" : `- ${currencySymbol}`}{disAmount.toFixed(2)}</p>
                      ) : (

                        <span onClick={handleCouponClick}>Apply Coupon</span>

                      )}
                    </div>
                    <div className='prize_dra'>

                      {firstOrderCouponDetail?.order_count === 0 && (
                        <>
                          <p>
                            First Order Discount (
                            {firstOrderCouponDetail?.data?.type_val}
                            {firstOrderCouponDetail?.data?.coupon_type === 'percent' ? '%' : firstOrderCouponDetail?.data?.coupon_type})
                          </p>
                          <p>
                            {isINR ? "- ₹" : `- ${currencySymbol}`}
                            {firstOrderCouponDiscount.toFixed(2)}
                          </p>
                        </>
                      )}

                    </div>
                    {/* <div className='prize_dra'>
                  <p>Shipping</p>
                  <p>Free</p>
                </div> */}
                    {
                      points > 0 && (
                        <div className='prize_dra'>
                          <p>Reward Points Discount</p>
                          <p>-₹{points}</p>
                        </div>
                      )

                    }
                    <div className='prize_dra'>
                      <p className='p_bold'>Total</p>
                      <p className='p_bold'>{isINR ? "₹" : currencySymbol} {grandTotal.toFixed(2)}</p>
                      {/* <p className='p_bold'>₹{FirstOrderCouponDiscount(grandTotal.toFixed(2))}</p> */}
                    </div>

                  </div>
                )}

              <div className='reward_point_drawer'>
                <div className='reward_text_p'>
                  <p>Redeem points on your next order!</p>
                  <p className='bold_p_reward'>Earn {cartPriceList?.total_amt || grandTotal.toFixed(0)} Reward Points</p>
                </div>
              </div>
              {/* <GstInfo showGSTForm={showGSTForm} setShowGSTForm={setShowGSTForm} /> */}
            </div>
            {/* cartPriceList?.total_amt.toFixed(2) */}

            <ProceedSection grandTotal={cartPriceList?.total_amt || grandTotal} onClose={onClose} showGSTForm={showGSTForm} isINR={isINR} currencySymbol={currencySymbol} />

          </div>
        )}
      </Drawer>
      {openCupponDrawer && <DrawerCuppon drawerClose={closeDrawerCart} openCupponDrawer={openCupponDrawer} grandTotal={cartPriceList?.total_amt || grandTotal} initialCoupons={initialCoupons} disAmount={disAmount} first_order_discount={first_order_discount} />}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className='deletePopUp_model'
        footer={
          <div className='custom-modal-footer'>
            <Button key="cancel" onClick={handleCancel} className='cancel_Button'>
              Cancel
            </Button>
            <Button key="delete" onClick={handleOk} className='deleteButton'>
              Delete
            </Button>
          </div>
        }
      >
        <div className='delete_popUpDesign'>
          <p>Are you sure want to delete the {cart?.length > 1 ? "products" : "product"}?</p>
          <Lottie options={defaultOptions}
            max-height={200}
            width={250}
          />

        </div>
      </Modal>
    </>
  );
};

export default React.memo(DrawerCart);
