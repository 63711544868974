import React, { useState, useEffect } from 'react';

const ScrollComponent = ({ totalProductCount, slug }) => {
    const savedCount = parseInt(sessionStorage.getItem('productCount'), 10) || 0;
    const [showDiv, setShowDiv] = useState(false);
    const [count, setCount] = useState(4); // Initial count
    const total = totalProductCount || savedCount; // Total number
    const [lastScrollY, setLastScrollY] = useState(0); // Track the last scroll position

    // Calculate the visibility ratio dynamically based on screen size  635∶272
    const calculateVisibilityRatio = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const aspectRatio = screenHeight / screenWidth;
        return aspectRatio;
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const screenVisibilityRatio = calculateVisibilityRatio();

        if (currentScrollY > window.innerHeight * screenVisibilityRatio) {
            setShowDiv(true);
        } else {
            setShowDiv(false);
        }

        const deltaY = currentScrollY - lastScrollY;
        // console.log('deltaYdeltaYdeltaYdeltaY',Math.abs(deltaY));
        // console.log('window',window.innerHeight);

        const oneThirdHeight = window.innerHeight / 2.3;
        // const oneHalfHeight = window.innerHeight / 2;

        const remainingProducts = total % 4 === 0 ? 4 : total % 4;

        if (Math.abs(deltaY) >= oneThirdHeight) {
            if (deltaY > 0) {
                // Scrolling down
                setCount(prevCount => Math.min(prevCount + 4, total));
            } else {
                // Scrolling up
                setCount(prevCount => {
                    const newCount = prevCount - 4;
    
                    if (prevCount === total) {
                        return prevCount - remainingProducts;
                    }
    
                    return Math.max(newCount, 0);
                });
            }

            setLastScrollY(currentScrollY);
        }

        // Reset count to zero when near the top of the page
        if (currentScrollY < 100 && count > 0) {
            setCount(0);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY,count]);
    const shouldShowButton = count > 0 && total > 0;
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
        <div>
            {showDiv && shouldShowButton && (
                <div className='button_loading'>
                    <button>
                        <svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 6V42" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 18L24 6L36 18" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg> {capitalizeFirstLetter(slug)} : {count}/{total}
                    </button>
                </div>
            )}
        </div>
    );
};

export default ScrollComponent;
