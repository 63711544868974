import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { getCouponList } from '../../services/coupon/Coupon';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

function CouponsPage() {
    const [couponList, setCouponList] = useState([]);
    const user = useSelector((state) => state.user);

    const fetchCouponsList = async () => {
        let result = await getCouponList(user.token);
        setCouponList(result?.data);
    }

    useEffect(() => {
        fetchCouponsList();
    }, [])

    const [copied, setCopied] = useState(false);

    const copyCode = (item) => {
        navigator.clipboard.writeText(item.coupon_code);
        setCopied(item);
        // setTimeout(() => {
        //     setCopied(false);
        // }, 3000);
    };



    const showCouponList = () => {
        return couponList?.map((item) => {
            // const text = (
            //     <p>
            //         <ul>
            //             {item?.min_amount !== 0 &&
            //                 <li>Rs. {item?.min_amount} off on minimum purchase of Rs. {item?.upto_amount}.</li>}
            //             <li>Maximum applicable discount of {item.type_val}%.</li>
            //         </ul>
            //     </p>
            // );
            // const items_details = [
            //     {
            //         key: item.key,
            //         label: `Expiry: ${dayjs(item?.to_date).format("DD MMM YYYY")}`,
            //         children: text,
            //         extra: 'Details',
            //     },
            // ];
         
            return (
                <div className='multiCoupons_crd' key={`coupons-${item?.id}`}>
                    {/* <div className='coupns_details_off'>
                        <div className='coupons_off'>
                            <span>{item.type_val}{item.coupon_type === 'percent' ? '%' : ' rs'} <p>OFF</p></span>
                        </div>
                        <div className='coupons_minimum'>
                            <h3>{item?.coupon_desc.split(' upto ')[0]} <span> Rs{item?.coupon_desc.split(' upto ')[1]?.replace('rs', '')}</span></h3>
                            <h3>Code : <span> </span></h3>
                        </div>
                    </div>
                    <div className='coupons_accordian'>
                        <Collapse items={items_details} bordered={false} />
                    </div> */}
                    <div className="coupon-card">
                        <h3>{item?.coupon_desc.split(' upto ')[0]} 
                        {/* <span> Rs{item?.coupon_desc.split(' upto ')[1]?.replace('rs', ' ')}</span> */}
                        </h3>
                        <div className="coupon-row">
                            <span id="cpnCode">{item.coupon_code}</span>
                            <span id="cpnBtn" onClick={()=>copyCode(item)}>{copied === item ? 'COPIED' : 'COPY CODE'}</span>
                        </div>
                        <p>Valid Till: {dayjs(item?.to_date).format("DD MMM YYYY")}</p>
                        <div className="circle1"></div>
                        <div className="circle2"></div>
                    </div>
                </div>
            )
        })
    }

    return (
        <>
            <div className='coupons_pages'>
                <h1>All Coupons</h1>
                <div className='coupons_cards'>
                    {showCouponList()}
                </div>
            </div>
        </>
    )
}

export default CouponsPage