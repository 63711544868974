import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
};
const api = axios.create(apiConfig);

// Create a separate function for handling API errors
const handleApiError = (error) => {
  // console.error('API Error:', error);
  throw error;
};

// Home Slider List
export const getReviewsList = async (data) => {
  try {
    const response = await api.post('/get-user-review-rating',data);
    if (response.status === 200) {
      return response.data;
    } else {
      // throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    return handleApiError(error);
  }
};

export const addReviews = async (data, token) => {
    try {
        const response = await api.post('/add-review-rating', data, {
            headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error(`API Error: ${response.statusText}`);
          }
    } catch (error) {
      return handleApiError(error);
    }
  };

  export const getReviewsDetails = async (data, token) => {
    try {
      const response = await api.post('/user-review-rating-detail',data, {
        headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error(`API Error: ${response.statusText}`);
          }
    } catch (error) {
      return handleApiError(error);
    }
  };

  export const editReviews = async (data, token) => {
    try {
      const response = await api.put('/edit-review-rating',data, {
        headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error(`API Error: ${response.statusText}`);
          }
    } catch (error) {
      return handleApiError(error);
    }
  };

  export const addLikeReview = async (data, token) => {
    try {
      const response = await api.post('/add-review-like',data, {
        headers: {
              Authorization: token,
            },
          });
          if (response.status === 200) {
            return response.data;
          } else {
            throw new Error(`API Error: ${response.statusText}`);
          }
    } catch (error) {
      return handleApiError(error);
    }
  };