import React from 'react'
import LoaderLottie from "../assets/images/loader.json"
import Lottie from 'react-lottie';

function LoaderSpinner () {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Lottie options={defaultOptions} height={150} width={200} />
        </div>
    );
}

export default LoaderSpinner;