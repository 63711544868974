import React, { useEffect, useState } from "react";
import { getProductCoupon } from "../services/coupon/Coupon";
import { useDispatch, useSelector } from "react-redux";
import { updateProductCoupon } from "../services/cart/addCart";
import { toast } from "react-toastify";
import { applyProductCoupon, clearProductCoupon } from "../reducers/productcouponSlice";

const ProductCoupon = ({ productDetails }) => {
    const [productCouponList, setProductCouponList] = useState([]);
    const [error, setError] = useState(null);
    const { id, token } = useSelector(state => state.user);
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [appliedCouponCode, setAppliedCouponCode] = useState(null);
    const cart = useSelector((state) => state.cart);
    // console.log('cart',cart.id);  
    const dispatch = useDispatch();
    // console.log('isProductInCart',isProductInCart);
    const productCoupon = useSelector(state => state.productCoupon);
    const { currencyCode, currencySymbol, rate = 1 } = useSelector(state => state.excrate);
    const isINR = currencyCode === "INR";
    const [appliedPrice, setAppliedPrice] = useState(null);
    // Reset appliedPrice to null when currencyCode, currencySymbol, or rate change
    useEffect(() => {
        setAppliedPrice(null);
        setAppliedCouponCode(null);
        setAppliedCouponCode(null);
        sessionStorage.removeItem(`coupon_${productDetails.id}`);
        sessionStorage.removeItem(`appliedPrice_${productDetails.id}`);
    }, [currencyCode, currencySymbol, rate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const formData = new URLSearchParams();
                formData.append('product_id', productDetails.id);
                formData.append('user_id', id ? id : 0);
                const response = await getProductCoupon(formData);
                // console.log('response',response);
                if (response.status === '1') {
                    setProductCouponList(response.data);
                    setError(null);
                }
                const storedCouponCode = sessionStorage.getItem(`coupon_${productDetails.id}`);
                const storedNewPrice = sessionStorage.getItem(`appliedPrice_${productDetails.id}`);
                if (storedCouponCode && storedNewPrice) {
                    setAppliedCouponCode(storedCouponCode);
                    setAppliedPrice(storedNewPrice);
                }
                if (productCoupon && !storedCouponCode) {
                    dispatch(clearProductCoupon());
                }
                if (storedCouponCode && response?.couponDetails?.subtotal_amount) {
                    dispatch(clearProductCoupon());
                    sessionStorage.removeItem(`coupon_${productDetails.id}`);
                    sessionStorage.removeItem(`appliedPrice_${productDetails.id}`);
                }
                if (response?.couponDetails?.subtotal_amount) {
                    setIsCouponApplied(true);
                    setAppliedPrice(response?.couponDetails?.subtotal_amount);
                    setAppliedCouponCode(response?.couponDetails?.coupon_code);
                }

            } catch (error) {
                setError('Error fetching data');
            }
        };

        fetchData();
    }, [productDetails, id]);

    // Handle coupon application
    const handleCopyClick = async (couponCode, coupon_type, type_val) => {
        const coupon_Code = isCouponApplied && appliedCouponCode === couponCode ? "" : couponCode;
        // const convertedPrice = usd_price * (rate ?? 1) * count;
        const isProductInCart = cart.some(item => item.id === productDetails.id);

        if (!isProductInCart) {
            const product_id = productDetails.id;
            if (coupon_Code === "") {
                setIsCouponApplied(false);
                setAppliedCouponCode(null);
                sessionStorage.removeItem(`coupon_${product_id}`);
                sessionStorage.removeItem(`appliedPrice_${product_id}`);
                dispatch(clearProductCoupon());
            } else {
                setIsCouponApplied(true);
                setAppliedCouponCode(coupon_Code);
                dispatch(applyProductCoupon(coupon_Code));
                sessionStorage.setItem(`coupon_${product_id}`, coupon_Code);
                // Calculate discount based on coupon type

                // Calculate discount amount
                const discountAmount = coupon_type === 'percent'
                    ? isINR
                        ? productDetails.price * (type_val / 100)
                        : productDetails.usd_price * (rate ?? 1) * (type_val / 100)
                    : type_val;
                // Calculate applied price
                const appliedPrice = isINR
                    ? productDetails.price - discountAmount
                    : productDetails.usd_price * (rate ?? 1) - discountAmount;

                setAppliedPrice(appliedPrice);
                // Store the applied price in sessionStorage
                sessionStorage.setItem(`appliedPrice_${product_id}`, appliedPrice);
            }
        }
        try {
            const formData = new URLSearchParams({
                product_id: productDetails.id,
                coupon_code: coupon_Code,
            });
            if (appliedCouponCode || isProductInCart) {
                const productCouRes = await updateProductCoupon(formData, token);
                // console.log('couponCode',productCouRes);
                if (productCouRes.data.status === "1") {
                    setIsCouponApplied(true);
                    setAppliedPrice(productCouRes?.data?.finalAmount);
                    setAppliedCouponCode(couponCode);
                    dispatch(applyProductCoupon(coupon_Code));
                    sessionStorage.setItem(`coupon_${productDetails.id}`, coupon_Code);
                } else if (productCouRes.data.status === "0") {
                    setIsCouponApplied(false);
                    setAppliedCouponCode(null);
                } else if (productCouRes.data.status === "2") {
                    toast.error(productCouRes?.data?.message);
                    setIsCouponApplied(false);
                    setAppliedCouponCode(null);
                }
            }

        } catch (error) {
            console.error("Cart Updating error:", error);
        }
    }

    return (
        <>
            {productCouponList?.length > 0 && (
                <div className='coupons_containers'>
                    <h4>Best offers for you!</h4>
                    {error && <div>Error: {error}</div>}
                    <div className='product_best_offer_row'>
                        {productCouponList?.map(item => (
                            <div key={item.coupon_code} className='product_best_offer_item'>
                                <div className='product_best_offer_info'>
                                    <strong>{item?.coupon_desc}</strong>
                                </div>
                                <div className='product_best_offer_code'>
                                    <span className='code_info'>Use Code:</span>
                                    <div className={appliedCouponCode === item.coupon_code ? 'offer_code appliedOffer' : 'offer_code'}>
                                        <span className='copy_code' onClick={() => handleCopyClick(item?.coupon_code, item?.coupon_type, item?.type_val)}>
                                            <div className='applied_span'>
                                                {appliedCouponCode === item.coupon_code ? <p>COUPON APPLIED!</p> : item?.coupon_code}
                                            </div>
                                        </span>
                                    </div>
                                    {appliedCouponCode === item.coupon_code &&
                                        <div className='applied_couponsSpan'>
                                            <h6><span>After applying the coupon, Your New Price :</span> ₹{appliedPrice}  </h6>
                                        </div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

export default React.memo(ProductCoupon);
