import React from 'react';
import { RiseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ccavenueResponse } from '../../services/ccavenue/ccavenueApi';
function PayPalForm({ from }) {
    const { id = '' } = from;
    const user = useSelector((state) => state.user);
    const buy = useSelector(state => state.buy);
    
    const buyNow = buy.length>0?"buy_now":"";

    const handlePaymentRedirect = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('address_id', id);
            formData.append('buy_now', buyNow);

            const response = await ccavenueResponse(formData, user?.token);
            if (response?.paymentUrl) {
                window.location.href = response.paymentUrl;
            } else {
                console.error('Payment URL not found');
            }
        } catch (error) {
            console.error('Error during payment processing:', error);
        }
    };

    return (
        <div className="cashDelivery_form">
            <h2>Pay at your CC Avenue</h2>
            <div className="netBanking_warning">
                <RiseOutlined />
                <span>Click on the button to be redirected to the CC Avenue payment page!</span>
            </div>
            <div className='payBtn'>
                <td>
                    <button onClick={() => handlePaymentRedirect()}>Place Order </button>
                </td>
            </div>
        </div>
    );
}

export default PayPalForm