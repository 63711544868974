import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import { getCartAmountdetail } from '../../services/coupon/applyCoupon';

const AmountDetails = ({ setCartDetails }) => {
    const buy = useSelector((state) => state.buy);
    const user = useSelector((state) => state.user); // Assuming user.token is from the Redux store
    const navigate = useNavigate();

    const buyNow = useMemo(() => (buy.length > 0 ? 'buy_now' : ''), [buy]);

    useEffect(() => {
        const fetchCartData = async () => {
            const formData = new URLSearchParams();
            formData.append('buy_now', buyNow);

            try {
                const CartData = await getCartAmountdetail(formData, user.token);
                if (CartData.status === '1') {
                    // Set all cart details in one call
                    setCartDetails({
                        cartList: CartData?.cart_list,
                        imgPath: CartData?.path,
                        priceDetails: CartData?.price_data,
                    });
                }

                if (!CartData?.price_data?.total_items || CartData?.price_data?.total_items < 1) {
                    navigate(Routes.Home);
                }
            } catch (e) {
                console.error('Error fetching cart data', e);
            }
        };

        // Debounce to avoid frequent calls
        const debounceFetch = setTimeout(fetchCartData, 300);

        return () => clearTimeout(debounceFetch);
    }, [buyNow, user.token, navigate, setCartDetails]);

    return null; // This component doesn't render any JSX directly.
};

export default AmountDetails;
