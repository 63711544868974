import React, { useRef, useEffect, useState } from "react";
import { LiaGreaterThanSolid } from "react-icons/lia";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getProductDetails } from "../../services/home";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector, useDispatch } from "react-redux";
import {getProductRelated } from "../../services/product";
import Drawer from "../../components/drawer/Drawer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {RightOutlined,LeftOutlined} from "@ant-design/icons";
// import img1 from "../../assets/images/drinkwarecup.png";
import Routes from "../../Routes/Routes";
import axios from "axios";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";
// import hoverImage from "../../assets/images/dining4.png";
import ProductDetailImgSlider from "../../components/ProductDetailImgSlider.jsx";
// import NoDataimg from "../../assets/images/noData.png";
import BulkDrawer from "../../components/drawer/BulkDrawer.jsx";
import BestSeller from "../../components/home/BestSeller.js";
import { getFaqDetailList } from "../../services/faq.js";
import Compare from "../../components/Compare.jsx";
import ColorWiseProduct from "../../components/ColorWiseProduct.jsx";
import { addToSlug } from "../../reducers/slugSlice.js";
import { addToCompare, clearCompare } from "../../reducers/compareSlice.js";
import NoData from '../../assets/images/noData.png'
import TrendingProduct from "../../components/productDetails/TrendingProduct.jsx";
import RelatedProducts from "../../components/productDetails/RelatedProducts.jsx";
import ProductFAQ from "../../components/productDetails/ProductFAQ.jsx";
import ProductCombo from "../../components/productDetails/ProductCombo.jsx";
import ProductDetailSlider from "../../components/productDetails/ProductDetailSlider.jsx";
import ProductDetailTab from "../../components/productDetails/ProductDetailTab.jsx";

// Arrow components for carousel navigation
const NextArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
        <div style={{ color: "#000", fontSize: "24px", position: "absolute", top: "-30px", right: "13px" }}>
            <RightOutlined />
        </div>
    </div>
);
const PrevArrow = ({ className, style, onClick }) => (
    <div className={className} style={{ ...style, display: "block" }} onClick={onClick}>
        <div style={{ color: "#000", fontSize: "24px", position: "absolute", top: "-30px", left: "-20px" }}>
            <LeftOutlined />
        </div>
    </div>
);

const ProductDetail = () => {
    const { slug } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [productDetails, setProductDetails] = useState(null);
    const [imgPath, setImgPath] = useState(null);
    const [trendingProduct, setTrendingProducts] = useState([]);
    const [productAttributesData, setProductAttributesData] = useState([]);
    const [sellerCategory, setSellerCategory] = useState([]);
    const [comboProductId, setComboProductId] = useState(null);
    const [comboProductCategoryId, setComboProductCategoryId] = useState(null);
    const [relatedData, setRelatedData] = useState([]);
    const [singleCategorySlug, setSingleCategorySlug] = useState(null);
    const [singleCompareData, setSingleCompareData] = useState(null);
    const [statusCheck, setStatusCheck] = useState(null);
    const [responseData, setResponseData] = useState(null);
    const [faq, setFaq] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [open, setOpen] = useState(false);
    const [openCompare, setOpenCompare] = useState(false);
    const [bulkProductId, setBulkProductId] = useState("0");
    const [country, setCountry] = useState("");
    const hasMounted = useRef(false);
    //  
    let dataSlug;
    let route;
    let from;
    let menuSlug;
    let categorySlug;
    // let ProductId;
    if (location.state) {
        from = location.state.from;
        menuSlug = location.state.menuSlug;
        route = location.state.route;
        categorySlug = location.state.categorySlug;
        dataSlug = location.state.dataSlug;
        // ProductId = location.state.ProductId;
    }
    useEffect(() => {
        const handleBackNavigation = (event) => {
            navigate(-1);
        };

        window.addEventListener('popstate', handleBackNavigation);

        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, [navigate]);

    const onClose = () => {
        setOpenDrawer(false);
    };
 
    const fetchDataAndRelatedData = async (data) => {
        const newSlug = data || slug;
        const formData = new URLSearchParams();
        formData.append("user_id", user?.id || 0);
        try {
            const response = await getProductDetails(newSlug, formData);
            setStatusCheck(response?.data?.status);
            if (response.status === "1") {
                const productId=response?.data?.id;
                setProductAttributesData(response?.product_attribute);
                setResponseData(response);
                setSingleCompareData(response);
                setSingleCategorySlug(response?.category[0]?.slug);
                setProductDetails(response.data);
                setSellerCategory(response?.category?.map((val) => val?.id));
                setComboProductId(response.data?.id);
                setComboProductCategoryId(response.data?.category_id)
                setImgPath(response.path);
                if (response?.product_buffer_days !== "") {
                    localStorage.setItem(
                        "bufferdays",
                        response?.data?.product_buffer_days
                    );
                }
                //Fetch related data only if relatedId is not null
                if (response?.data?.category_id !== null) {
                    const formData = new URLSearchParams();
                    formData.append("product_id", productId);
                    formData.append("related_type", "related");
                    formData.append("user_id", user?.id ? user?.id : 0);
                    const responseRelatedData = await getProductRelated(formData);
                    // console.log('responseRelatedData', responseRelatedData);
                    setRelatedData(responseRelatedData?.data); // Store related data in state
                }
                // up sell
                if (response?.data?.category_id !== null) {
                    const formData = new URLSearchParams();
                    formData.append("product_id", Number(response?.data?.id));
                    formData.append("related_type", "up_sell");
                    formData.append("user_id", user?.id ? user?.id : 0);

                    const responseRelatedData = await getProductRelated(formData);
                    // console.log('responseRelatedData', responseRelatedData);
                    setTrendingProducts(responseRelatedData?.data);
                }
            }
            if (response.status === 204) {
                // alert('see');
                const slugIfo = `product/${slug}`;
                dispatch(addToSlug({ slug: slugIfo }));
                navigate(`/${slugIfo}`);
            }
        } catch (error) {
            if (error.response?.data?.status === "0") {
                // console.log('error.response?.data?.status', error.response?.data?.status);
                // navigate(Routes.Error);
            }
            // console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchDataAndRelatedData();
        hasMounted.current = true;
    }, [slug]);

   
    // useEffect(() => {
    //     if (buy.length > 0) {
    //         const cart = JSON.parse(localStorage.getItem("cart")) || [];
    //         buy.forEach((item) => {
    //             const existingItemIndex = cart.findIndex(
    //                 (cartItem) => cartItem.id === item.id
    //             );
    //             if (existingItemIndex !== -1) {
    //                 // Update the count of the existing item
    //                 const updatedCartItem = {
    //                     ...cart[existingItemIndex],
    //                     count: cart[existingItemIndex].count + item.count,
    //                 };
    //                 cart[existingItemIndex] = updatedCartItem;
    //             } else {
    //                 // Add the new item to the cart
    //                 cart.push(item);
    //             }
    //         });

    //         localStorage.setItem("cart", JSON.stringify(cart));
    //         dispatch(addToCart(cart));
    //         dispatch(clearBuy());
    //         localStorage.removeItem("buy");
    //     }
    // }, []);
    const relatedSettings = {
        dots: true,
        infinite: relatedData?.length > 4 ? true : false,
        centerMode: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const getIP = async () => {
        try {
            const res = await axios.get("https://api.ipify.org/?format=json");
            // setIP(res.data.ip);
            return res.data.ip;
        } catch (error) {
            console.error("Error fetching IP:", error);
            return null;
        }
    };

    const getCountry = async (ip) => {
        try {
            const res = await axios.get(`https://ipapi.co/${ip}/country/`);
            setCountry(res.data);
        } catch (error) {
            console.error("Error fetching country:", error);
        }
    };

    const fetchData = async () => {
        const ip = await getIP();
        if (ip) {
            await getCountry(ip);
        }
    };

    useEffect(() => {
        if (productDetails?.id) {
            const faqList = async () => {
                try {
                    const formData = new URLSearchParams();
                    formData.append("product_id", productDetails.id);
                    const response = await getFaqDetailList(formData);
                    if (response.status === "1") {
                        setFaq(response?.data?.faq);
                    }
                } catch (error) {
                    // console.error("Error fetching data:", error);
                }
            };
            faqList();
        }
    }, [productDetails?.id]);

    useEffect(() => {
        fetchData();
    }, []);

   
    const handleRelated = (data) => {
        fetchDataAndRelatedData(data);
        window.scrollTo(0, 0);
    };
    const backBreadCum = (from, menuSlug) => {
        navigate(`${Routes.ProductCategory}/${categorySlug}`, {
            state: { from, menuSlug },
        });
    };
    const showDrawer = (id) => {
        setOpen(true);
        setBulkProductId(id);
    };
    const bulkDrawerOnClose = () => {
        setOpen(false);
    };

    const onCloseCompare = () => {
        setOpenCompare(false);
    };
    const handleCompare = () => {
        // Retrieve and parse compare data from localStorage
        let compareData = JSON.parse(localStorage.getItem("compareData")) || {};

        // Retrieve and parse compare IDs from localStorage
        let compareIDs = JSON.parse(localStorage.getItem("comp")) || [];


        // Ensure compareIDs is an array
        if (!Array.isArray(compareIDs)) {
            compareIDs = [];
        }

        // Extract IDs from compareIDs
        const ids = compareIDs.map((item) => item.id);

        // Assuming singleCompareData is available in scope
        if (!singleCompareData?.data) {
            console.error("singleCompareData is not available");
            return;
        }

        // Destructure the required data from the response
        const {
            product_name = "",
            productimages = [],
            price = 0,
            id = 0,
        } = singleCompareData.data;

        const imgPath = singleCompareData.path || "";
        const comimage = `${imgPath}${productimages[0]?.file_name_120_x_120 || ""}`;
        const catSlug = singleCompareData.category?.[0]?.slug || "";
        const category_name = singleCompareData.category?.[0]?.name || "";

        // Create the data object to store
        const dataToStore = {
            product_name,
            price,
            comimage,
            id,
            catSlug,
            category_name,
        };
        if (compareIDs?.[0]?.slug && catSlug !== compareIDs[0]?.slug) {
            dispatch(clearCompare());
            localStorage.removeItem('comp');
        }
        // Check if the product ID is already in the compare list
        if (!ids.includes(id)) {
            // Update localStorage with the new data
            localStorage.setItem("compareData", JSON.stringify(dataToStore));
            setOpenCompare(true);
        } else {
            // Update localStorage with the new data
            localStorage.setItem("compareData", JSON.stringify(dataToStore));
            // Update localStorage with the updated compareIDs
            compareIDs.push(compareData);
            localStorage.setItem("comp", JSON.stringify(compareIDs));
            // Remove the existing ID from compareIDs
            const newCompareIDs = compareIDs.filter((item) => item.id !== id);

            // Update localStorage with the new compareIDs
            dispatch(addToCompare(newCompareIDs));
            localStorage.setItem("comp", JSON.stringify(newCompareIDs));

            // Set the open state to true to show the compare data
            setOpenCompare(true);
        }

    };
    if (statusCheck === "0") {
        return (
            <div className='noDataCont' style={{ marginTop: 60 }}>
                <img src={NoData} alt='' />
                <h5>Coming Soon</h5>
            </div>
        )
    }

    return (
        <>
            <div className="dinnerplate-container">
                <div className="path_drinkware">
                    <Link to="/">Home</Link>
                    <LiaGreaterThanSolid />
                    {from && (
                        <>
                            {/* <LiaGreaterThanSolid /> */}
                            <span onClick={() => navigate(`/${menuSlug}`)}>{from}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    {route && (
                        <>
                            <span onClick={() => backBreadCum(from, menuSlug)}>{route}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    {dataSlug && (
                        <>
                            <span onClick={() => navigate(-1)}>{dataSlug}</span>
                            <LiaGreaterThanSolid />
                        </>
                    )}
                    <p>{productDetails?.product_name}</p>
                </div>
                <div className="main-box">
                    <div className="left-slide">
                        <ProductDetailImgSlider
                            productDetails={productDetails}
                            imgPath={imgPath}
                        />
                    </div>

                    <div className="right-slide">
                        <ProductDetailSlider responseData={responseData} setActiveTab='dimensions' country={country} handleCompare={handleCompare} showDrawer={showDrawer} />
                    </div>
                </div>

                <div className="tabs_sub">
                    <ProductDetailTab responseData={responseData} slug={slug}/>
                  
                </div>
                {comboProductId && <ProductCombo
                    comboProductId={comboProductId}
                    comboProductCategoryId={comboProductCategoryId}
                    productDetails={productDetails}
                />}
                <BestSeller
                    productDetails={productDetails}
                    sellerCategory={sellerCategory}
                />
                <ColorWiseProduct
                    productAttributesData={
                        productAttributesData
                    }
                />
                <RelatedProducts
                    relatedData={relatedData}
                    relatedSettings={relatedSettings}
                    imgPath={imgPath}
                    handleRelated={handleRelated}
                    Routes={Routes}
                />


                <TrendingProduct
                    trendingProduct={trendingProduct}
                    relatedSettings={relatedSettings}
                    imgPath={imgPath}
                    handleRelated={handleRelated}
                    Routes={Routes}
                />

                <ProductFAQ faq={faq} />

            </div>

            <Drawer onClose={onClose} openDrawer={openDrawer} />
            <BulkDrawer
                onClose={bulkDrawerOnClose}
                openDrawer={open}
                productId={bulkProductId}
            />
            <Compare
                openCompare={openCompare}
                onCloseCompare={onCloseCompare}
                dataSlug={singleCategorySlug}
            />
        </>
    );
};
export default ProductDetail;
