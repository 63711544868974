import { Collapse, Checkbox, Slider, Switch, Radio } from 'antd';
import { PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import Accordion from 'react-bootstrap/Accordion';
import { useEffect, useRef, useState } from 'react';
import { DiscountList, MaterialList, TypeList, getFilterProductList } from '../services/filter/categories';
import { useDispatch, useSelector } from 'react-redux';
import { addToChip } from '../reducers/filterSlice';
import { getColor } from '../services/filter/color';

const { Panel } = Collapse;

const CommFilter = ({ setProductList, searchValue, fetchProducts, price, setimgPath, setCategoryImgPath, setSliderPath, setCategory }) => {
    const [materialList, setMaterialList] = useState(null);
    const chips = useSelector(state => state.chips);
    const dispatch = useDispatch();
    const [isActiveIcon, setIsActiveIcon] = useState(false);
    const [isActiveSec, setIsActiveSec] = useState(false);
    const [colorList, setColorList] = useState(null);
    const urlName = window?.location?.href.split('/');
    const lastSegment = urlName[urlName.length - 1];
    const [colorPath, setColorPath] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [maxPrice, setMaxPrice] = useState('');
    const [priceRange, setPriceRange] = useState([0, maxPrice]);
    const newChipsData = chips.filter(chipsItem => chipsItem?.pagneName === lastSegment);
    const user = useSelector((state) => state.user);
    const [stockToggle, setStockToggle] = useState(0);
    const [marks, setMarks] = useState({});
    const [checkedColors, setCheckedColors] = useState([]);
    const [selectedDiscount, setSelectedDiscount] = useState('')
    const [discountList, setDiscountList] = useState(null)
    // console.log('priceRangepriceRangepriceRange',price);
    const saveMaxPrice = localStorage.getItem("maxPrice");

    useEffect(() => {
        const newMaxPrice = price > 0 ? price : saveMaxPrice;
        const existingIndex = newChipsData.findIndex(item => item.priceRangeID === 1);
        if (existingIndex !== -1) {
            const [existingMinPrice, existingMaxPrice] = chips[existingIndex].priceRange;
            setPriceRange([existingMinPrice, existingMaxPrice]);
        } else {
            setPriceRange([0, newMaxPrice]);
        }
        setMaxPrice(newMaxPrice);
        localStorage.setItem("maxPrice", newMaxPrice);
    }, [price]);

    useEffect(() => {
        const stockToggleItem = newChipsData.find(item => item.type === 'stockToggle');
        const existingDiscount = newChipsData.find(item => item.discountID === 1);
        if (stockToggleItem) setStockToggle(stockToggleItem.value);
        if (existingDiscount) setSelectedDiscount(existingDiscount.discountRange);
        const max_Price = parseFloat(saveMaxPrice);
        if (max_Price > 0) setMaxPrice(max_Price);
    }, [newChipsData]);

    useEffect(() => {
        if (maxPrice > 1) {
            const updatedMarks = {
                0: '0',
                [maxPrice]: {
                    style: {
                        color: '#000',
                    },
                    label: <strong>{maxPrice}</strong>,
                },
            };
            setMarks(updatedMarks);

        }
    }, [maxPrice, price]);

    const [typeList, setTypeList] = useState(null); //TypeList
    //
    const fetchList = async (apiFunction, setState, lastSegment, searchValue, userToken = null) => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', lastSegment);
            if (lastSegment === 'allProduct') {
                formData.append('keyword', searchValue);
            }
            const response = await apiFunction(formData, userToken);
            if (response.status === '1') {
                setState(response.data);
                return response?.path || null;  // Return path if available (for colors)
            }
        } catch (error) {
            console.error('Error fetching list:', error);
        }
    };

    useEffect(() => {
        const fetchAllLists = async () => {
            // Fetch Type List
            await fetchList(TypeList, setTypeList, lastSegment, searchValue);

            // Fetch Material List
            await fetchList(MaterialList, setMaterialList, lastSegment, searchValue);

            // Fetch Color List and handle the color path separately
            const colorPath = await fetchList(getColor, setColorList, lastSegment, searchValue, user?.token);
            if (colorPath) {
                setColorPath(colorPath);
            }

            // Fetch Discount List
            await fetchList(DiscountList, setDiscountList, lastSegment, searchValue);
        };

        fetchAllLists();
    }, [lastSegment, searchValue, user?.token]);
    //

    const handleTypeChange = (typedata) => {
        const { id, type_name } = typedata
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = namesArray.findIndex(item => item.typeId === id);
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ typeId: id, childName: type_name, pagneName: lastSegment });
        }

        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
    };

    const removeOutOfStocks = () => {
        const newStockToggle = stockToggle === 0 ? 1 : 0;
        setStockToggle(newStockToggle);
        let updatedNames;
        if (newStockToggle === 0) {
            updatedNames = chips.filter(item => item.type !== 'stockToggle');
        } else {
            updatedNames = chips.map(item =>
                item.type === 'stockToggle'
                    ? { ...item, value: newStockToggle }
                    : item
            );

            // If the stockToggle item doesn't exist, add it
            if (!updatedNames.find(item => item.type === 'stockToggle')) {
                updatedNames.push({ type: 'stockToggle', value: newStockToggle, pagneName: lastSegment });
            }
        }

        localStorage.setItem('chips', JSON.stringify(updatedNames));
        dispatch(addToChip(updatedNames));
    };
    const handleDiscount = (value) => {
        setSelectedDiscount(value);

        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const discountFormat = value === 0 ? 'Discount: All' : `Discount: ${value}% Above`;

        const existingIndex = namesArray.findIndex(item => item.discountID === 1);

        const discountData = { discountID: 1, discountRange: value, childName: discountFormat, minDiscount: value, pagneName: lastSegment };

        if (existingIndex !== -1) {
            namesArray[existingIndex] = discountData; // Update existing discount
        } else {
            namesArray.push(discountData); // Add new discount
        }

        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
    };
    const handleMaterialChange = (materialData) => {
        const { id, material_name } = materialData
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = namesArray.findIndex(item => item.materialId === id);
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ materialId: id, childName: material_name, pagneName: lastSegment });
        }

        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
    };



    const toggleAccordion = () => {
        setIsActiveIcon(!isActiveIcon);
    };

    const toggleAccordionSec = () => {
        setIsActiveSec(!isActiveSec);
    };

    const onChoose = (e) => {
        setSelectedColor(e?.target?.value);
    };

    const onChanged = (colorId) => {
        const { color, id, image } = colorId;
        const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
        const existingIndex = newChipsData.findIndex(item => item.colorid === id);
        if (existingIndex !== -1) {
            namesArray.splice(existingIndex, 1);
        } else {
            namesArray.push({ colorid: id, childName: color, image, pagneName: lastSegment });
        }
        localStorage.setItem('chips', JSON.stringify(namesArray));
        dispatch(addToChip(namesArray));
        setCheckedColors(namesArray.map(chip => chip.colorid));
    };
    const fetchTimeout = useRef(null);
    const handlePriceChange = (value) => {
        // setPriceRange(value);
        const [min, max] = value;
        if (min > max) {
            setPriceRange([max, max]);
        } else {
            setPriceRange(value);
        }
        // Clear the previous timeout
        if (fetchTimeout.current) {
            clearTimeout(fetchTimeout.current);
        }

        // Set a new timeout
        fetchTimeout.current = setTimeout(() => {
            const namesArray = JSON.parse(localStorage.getItem('chips')) || [];
            const existingIndex = newChipsData.findIndex(item => item.priceRangeID === 1);

            if (existingIndex !== -1) {
                // Replace the existing item
                namesArray[existingIndex] = { priceRangeID: 1, priceRange: value, childName: `Price: ${min} - ${max}`, maxPrice: maxPrice, pagneName: lastSegment };
            } else {
                // Add a new item
                namesArray.push({ priceRangeID: 1, priceRange: value, childName: `Price: ${min} - ${max}`, maxPrice: maxPrice, pagneName: lastSegment });
            }

            localStorage.setItem('chips', JSON.stringify(namesArray));
            dispatch(addToChip(namesArray));
        }, 400);
    };

    const handleReset = () => {
        setPriceRange([0, price])
        localStorage.removeItem('chips')
        dispatch(addToChip([]));
        setStockToggle(0)
        setCheckedColors([]);
        setSelectedDiscount('')
    }
    const handleRemoveChildName = (identifier, value) => {
        // Update chips array
        let updatedNames;
        switch (identifier) {
            case 'id':
                updatedNames = chips.filter((item) => item.id !== value);
                break;
            case 'typeId':
                updatedNames = chips.filter((item) => item.typeId !== value);
                break;
            case 'materialId':
                updatedNames = chips.filter((item) => item.materialId !== value);
                break;
            case 'colorid':
                updatedNames = chips.filter((item) => item.colorid !== value);
                break;
            case 'discountID':
                setSelectedDiscount('')
                updatedNames = chips.filter((item) => item.discountID !== value);
                break;
            case 'priceRangeID':
                setPriceRange([0, saveMaxPrice])
                updatedNames = chips.filter((item) => item.priceRangeID !== value);
                break;
            case 'stockToggle':
                updatedNames = chips.filter((item) => item.type !== 'stockToggle');
                setStockToggle(0);
                break;
            default:
                updatedNames = chips;
        }
        localStorage.setItem('chips', JSON.stringify(updatedNames));

        // Dispatch an action or update state as needed
        dispatch(addToChip(updatedNames));
        setCheckedColors(updatedNames.map(chip => chip.colorid));
    };

    const { materialIds, typeIds, colorIds } = newChipsData.filter(chipsItem => chipsItem?.pagneName === lastSegment)
        .reduce((acc, chipsItem) => {
            if (chipsItem.materialId) acc.materialIds.push(chipsItem.materialId);
            if (chipsItem.typeId) acc.typeIds.push(chipsItem.typeId);
            if (chipsItem.colorid) acc.colorIds.push(chipsItem.colorid);
            return acc;
        }, { materialIds: [], colorIds: [], typeIds: [] });


    const fetchFilterProductList = async () => {
        const existingIndex = newChipsData.findIndex(item => item.priceRangeID === 1);
        const existingpriceRange = existingIndex !== -1 ? newChipsData[existingIndex]?.priceRange : [];
        const stockToggleItem = newChipsData.find(item => item.type === 'stockToggle');
        const stockToggleValue = stockToggleItem ? stockToggleItem.value : stockToggle;
        const existingDiscountValue = newChipsData.findIndex(item => item.discountID === 1);
        const existingDiscountRange = existingDiscountValue !== -1 ? newChipsData[existingDiscountValue]?.discountRange : '';
        const minDiscount = existingDiscountRange;
        try {
            const formData = new URLSearchParams();
            formData.append('category_id', JSON.stringify([]));
            formData.append('color_id', JSON.stringify(colorIds));
            formData.append('sorting', '');
            formData.append('slug', lastSegment);
            formData.append('material_id', JSON.stringify(materialIds));
            formData.append('type_id', JSON.stringify(typeIds));
            formData.append('min_price', priceRange[0] ? priceRange[0] : existingpriceRange[0] ? existingpriceRange[0] : 0);
            formData.append('max_price', priceRange[1] ? priceRange[1] : existingpriceRange[1] ? existingpriceRange[1] : saveMaxPrice);
            formData.append('min_discount', minDiscount)
            formData.append('user_id', user?.id);
            formData.append('remove_stock', stockToggleValue)
            if (lastSegment == 'allProduct') {
                formData.append('keyword', searchValue)
            }
            // }
            const response = await getFilterProductList(formData);
            if (response.status === '1') {
                setProductList(response);
                setimgPath(response.product_path)
                setCategory(response?.category);
                setSliderPath(response.page_slider)
            } else {
                // console.log('Response', 'Record Not Found!');
            }
        } catch (error) {
            console.error('Error fetching product list:', error);
        }
    };
    useEffect(() => {
        const stockToggleItem = newChipsData.find(item => item.type === 'stockToggle');
        const newcond = stockToggleItem?.value || stockToggle
        if (newChipsData?.length > 0 || newcond) {
            fetchFilterProductList();
        } else {
            fetchProducts()
        }
    }, [chips]);

    const discountItem = () => ({
        key: '15',
        header: "Discount",
        children: (
            <Radio.Group value={selectedDiscount} onChange={(e) => handleDiscount(e?.target?.value)} >
                <div className="SubCategory">
                    {discountList?.map(discountVal => (
                        <span key={discountVal.id} className=''>
                            <Radio value={discountVal.from_discount_val}>
                                {discountVal.from_discount_val === 0 && discountVal.to_discount_val === 100 ? 'All' : `${discountVal.from_discount_val}% Above`}
                            </Radio>
                        </span>
                    ))}
                </div>
            </Radio.Group>
        ),
    });
    //
    const typeListItems = [
        {
            key: '1',
            label: 'Type',
            children: (
                <div className="SubCategory">
                    {typeList && typeList.map(({ id, type_name }) => (
                        <span key={id}>
                            <Checkbox
                                checked={newChipsData.some(chip => chip.typeId === id)}
                                onChange={() => handleTypeChange({ id, type_name })}
                            >
                                {type_name}
                            </Checkbox>
                        </span>
                    ))}
                </div>
            ),
        },
    ];
    const materialListItems = [
        {
            key: '2',
            label: 'Material',
            children: (
                <div className="SubCategory">
                    {materialList && materialList?.map(material => (
                        <span key={material.id} className=''>
                            <Checkbox
                                checked={newChipsData?.some(chip => chip.materialId === material.id)}
                                onChange={() => handleMaterialChange(material)}
                            >
                                {material.material_name}
                            </Checkbox>
                        </span>
                    ))}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className='chips_designs'>
                <div className='chips_filterContainer'>
                    <div className='accordian_filterCont'>
                        {newChipsData?.filter(item => item.type !== 'stockToggle')
                            .map((item, index) => (
                                <div className='chipsDesign' key={index}>
                                    {item?.image && <img src={`${colorPath}${item?.image}`} alt='' />} {item.childName} <CloseOutlined onClick={() => handleRemoveChildName(item.id ? 'id' :
                                        item.typeId ? 'typeId' :
                                            item.materialId ? 'materialId' :
                                                item.colorid ? 'colorid' :
                                                    item.priceRangeID ? 'priceRangeID' :
                                                        item.discountID ? 'discountID' :
                                                            item.type === 'stockToggle' ? 'stockToggle' : '',
                                        item.typeId || item.id || item.materialId || item.colorid || item.priceRangeID || item.type === 'stockToggle' || item.discountID
                                    )} />
                                </div>
                            ))}
                    </div>
                    {newChipsData?.some(item => item.type !== 'stockToggle') && (
                        <button className='clearData_btn' onClick={handleReset}>Clear All</button>
                    )}
                </div>
                <div className="accordian_section">
                    <span>Filter : </span>
                    {typeList && (
                        <div className="titles_acd">
                            <Collapse items={typeListItems} expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)} />

                        </div>)}
                    {materialList && (<div className="titles_acd">
                        <Collapse items={materialListItems} expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)} />
                    </div>)}

                    {colorList && (<Accordion>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header onClick={toggleAccordion}>
                                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                    Color
                                    {isActiveIcon ? <MinusOutlined /> : <PlusOutlined />}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>

                                <div className='color_imageCat'>
                                    <Checkbox.Group style={{ width: '100%' }} onChange={onChoose} value={checkedColors}>
                                        <div className='color_checkoutbox'>
                                            {colorList.map(color => (
                                                <Checkbox
                                                    onChange={() => onChanged(color)}
                                                    checked={checkedColors?.includes(color.id)}
                                                    key={color.id}
                                                    value={color.id}
                                                >
                                                    <img src={`${colorPath}${color.image}`} alt={color.color} />
                                                    <p>{color.color}</p>
                                                </Checkbox>
                                            ))}
                                        </div>
                                    </Checkbox.Group>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>)}
                    <Accordion>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header onClick={toggleAccordionSec}>
                                <span style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                    Price
                                    {isActiveSec ? <MinusOutlined /> : <PlusOutlined />}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='accordion_range'>
                                    <Slider
                                        range
                                        marks={marks}
                                        max={maxPrice}
                                        onChange={handlePriceChange}
                                        value={priceRange}
                                        defaultValue={[0, maxPrice]}
                                    />
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="titles_acd">
                        <Collapse items={discountItem} expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />} />
                    </div>
                    <div className='out_stock_box'>
                        <span>Remove Out of Stock Items</span>
                        <Switch checked={stockToggle} onChange={removeOutOfStocks} />
                    </div>
                </div>
                <div className='filterButton'>
                    <button>Filter</button>
                </div>
            </div>
        </>
    );
};

export default CommFilter;
