import { createSlice } from '@reduxjs/toolkit';

const initialState = typeof window !== 'undefined' 
  ? localStorage.getItem('productcoupon') || null 
  : null;

const productcouponSlice = createSlice({
  name: 'productCoupon',
  initialState,
  reducers: {
    applyProductCoupon: (state, action) => action.payload,
    clearProductCoupon: () => null,
  },
});

export const { applyProductCoupon, clearProductCoupon } = productcouponSlice.actions;

export default productcouponSlice.reducer;
