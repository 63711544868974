import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { LiaGreaterThanSolid } from "react-icons/lia";
import compareImg from '../../assets/images/product1.png';
import { Row, Col } from 'antd';
import { compareProduct } from '../../services/assurance';
import Wishlist from '../../function/Wishlist';
import AddToCart from '../../function/AddToCart.js';
import { addToCompare } from '../../reducers/compareSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import Routes from '../../Routes/Routes.js';
import { Link, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

const CompareDetail = () => {
    const comp = useSelector(state => state.comp);
    const [path, setPath] = useState(null);
    const [compareList, setCompareList] = useState([]);
    const [is_wishlist, setIsWishlist] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);

    const categoryName = useMemo(() => JSON.parse(localStorage?.getItem("compareData")), []);
    const compareData = useMemo(() => JSON.parse(localStorage?.getItem('compareData')) || {}, []);

    // Fetch Compare Product
    const fetchCompareProduct = useCallback(async (productIds) => {
        try {
            const formData = new URLSearchParams();
            formData.append('product_id', JSON.stringify(productIds));

            const response = await compareProduct(formData);
            if (response.status === "1") {
                const matchedProductIndex = response.data.findIndex(product => product.id === compareData.id);
                if (matchedProductIndex !== -1) {
                    const matchedProduct = response.data.splice(matchedProductIndex, 1)[0];
                    response.data.unshift(matchedProduct);
                }
                setIsWishlist(response?.is_wishlist);
                setPath(response.path);
                setCompareList(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [compareData]);

    // Handle Scroll Debounced
    const handleScroll = useCallback(debounce(() => {
        setIsSticky(window.scrollY > 100);
    }, 100), []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const handleCloseIcon = useCallback((id) => {
        const updatedComp = comp.filter(item => item.id !== id);
        if (compareData?.id !== id) {
            localStorage.setItem('comp', JSON.stringify(updatedComp));
            dispatch(addToCompare(updatedComp));
            fetchCompareProduct(updatedComp.map(item => item.id));
        }
    }, [comp, compareData, dispatch, fetchCompareProduct]);

    useEffect(() => {
        const productIds = [compareData?.id, ...comp.map(item => item.id)];
        fetchCompareProduct(productIds);
    }, [comp, compareData, fetchCompareProduct]);

    const handleAddProduct = useCallback(() => {
        navigate('/products');
    }, [navigate]);

    const renderAddProductCard = (key) => (
        <Col key={key} span={6}>
            <Link to={`/collections/${categoryName?.catSlug}`} state={{ compare: true }}>
                <div className="add_product_card" onClick={handleAddProduct}>
                    <div className="add_product_icon">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 8V40" stroke="#000" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 24H40" stroke="#000" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <p>Add Product</p>
                </div>
            </Link>
        </Col>
    );

    return (
        <div className='compare_container'>
            <div className="path_about_drinkware">
                <p>
                    <Link to="/">Home</Link>
                    <LiaGreaterThanSolid />
                    <Link to={`${Routes?.ProductCategory}/${categoryName?.catSlug}`} state={{ ProductId: categoryName?.id }}>
                        <h6>{categoryName?.category_name}</h6>
                    </Link>
                </p>
            </div>
            <h1>Compare Product</h1>
            <div className='products_contant_cards'>
                <Row gutter={32}>
                    {compareList?.map((comp, index) => (
                        <Col key={index} span={6}>
                            <div id="myHeader" className={`${isSticky ? "newProduct_compareCard" : "product_compareCard"} ${index === 0 ? "extraClass" : ""}`}>
                                <Link to={`${Routes.ProductDetail}/${comp.product_slug}`} state={{ ProductId: comp?.id }} className='anchor_image'>
                                    <img src={`${path}/${comp.productimages[0]?.file_name}`} alt={comp.product_name} onError={(e) => { e.target.onerror = null; e.target.src = compareImg }} />
                                </Link>
                                <Link to={`${Routes.ProductDetail}/${comp.product_slug}`} state={{ ProductId: comp?.id }} className='anchore_link'>
                                    <h6>{comp.product_name}</h6>
                                    <div className="price">
                                        <p>₹{comp.price}</p>
                                        <span>₹{comp.compare_price}</span>
                                        {comp?.discount > 0 && (
                                            <div className="off_chips_compare">
                                                <p>{comp.discount}% off</p>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                                {index !== 0 && (
                                    <div className='close_icon' onClick={() => handleCloseIcon(comp.id)}>
                                        <svg width="18" height="18" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 8L40 40" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M8 40L40 8" stroke="#fff" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                )}
                                <div className="wishlist">
                                    {is_wishlist !== null && (
                                        <Wishlist
                                            is_wishlist={is_wishlist}
                                            pId={comp?.id}
                                            path={`${Routes.ProductDetail}/${comp?.product_slug}`}
                                            mode='HeartOutlined'
                                        />
                                    )}
                                </div>
                                <AddToCart productList={comp} imgPath={path} />
                            </div>
                        </Col>
                    ))}
                    {compareList.length < 4 && renderAddProductCard(1)}
                    {compareList.length < 3 && renderAddProductCard(2)}
                    {compareList.length < 2 && renderAddProductCard(3)}
                </Row>
                {compareList.length > 0 && (
                    <div className='product_keyDeatil'>
                        <h6>Key Details</h6>
                        <div className='details_keys'>
                            {compareList.map((comp, index) => (
                                <ul key={index}>
                                    {comp?.producttypes?.type_name && <li>{comp.producttypes.type_name}</li>}
                                    {comp?.productmaterials?.material_name && <li>{comp.productmaterials.material_name}</li>}
                                    {comp?.Feature && <li>{comp.Feature}</li>}
                                    {comp?.pack_contain && <li>Set of {comp.pack_contain}</li>}
                                </ul>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompareDetail;
