import { useState, useRef } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { Skeleton } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

// import img1 from "../assets/images/dining1.png";
// import img2 from "../assets/images/dining2.png";
// import img3 from "../assets/images/dining3.png";
// import img4 from "../assets/images/dining4.png";
// import img5 from "../assets/images/dining01.png";


const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="nextArr" onClick={onClick}>
            <RightOutlined />
        </div>
    );
};
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="prevArr" onClick={onClick}>
            <LeftOutlined />
        </div>
    );
};


function ProductDetailImgSlider({ productDetails, imgPath }) {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [active, setActive] = useState(false);

    const clickHandler = (i) => {
        setCurrentImageIndex(i);
        setActiveThumbnail(i);
    };
  
    const setActiveThumbnail = (index) => {
        refs.current.forEach((ref, i) => {
            if (ref) {
                ref.classList.toggle('active', i === index);
            }
        });
    };

    const nextImage = () => {
        const newIndex = (currentImageIndex + 1) % productDetails?.productimages.length;
        setCurrentImageIndex(newIndex);
        setActiveThumbnail(newIndex);
    };

    const prevImage = () => {
        const newIndex = (currentImageIndex - 1 + productDetails?.productimages.length) % productDetails?.productimages.length;
        setCurrentImageIndex(newIndex);
        setActiveThumbnail(newIndex);
    };

    const refs = useRef([]);
    refs.current = [];

    const addRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el);
        }
    };

    const srcSet = [
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 355w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 481w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 584w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 687w`,
        // `${watchImg770} 770w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 861w`,
        // `${watchImg955} 955w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 1033w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 1112w`,
        // `${watchImg1192} 1192w`,
        `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name} 1200w`,
    ].join(', ');
    return (
        <div className="productImg_container">
            <div className="img_thumbnail">
                <div className="thumbnail_1">
                    {productDetails ? (
                        productDetails.productimages.map((image, i) => (
                            <div
                                className={i === currentImageIndex ? 'img_wrap active' : 'img_wrap'}
                                key={i}
                                onClick={() => clickHandler(i)}
                                ref={addRefs}
                            >
                                <img
                                    src={`${imgPath}${image?.file_name_120_x_120}`}
                                    alt={image?.file_name_120_x_120}
                                />
                            </div>
                        ))
                    ) : (
                        <>
                            <Skeleton.Image active={active} />
                            <Skeleton.Image active={active} />
                            <Skeleton.Image active={active} />
                        </>
                    )}
                </div>

                <div className="full_img">
                    {productDetails ? (
                        <ReactImageMagnify
                            isActivatedOnTouch
                            {...{
                                smallImage: {
                                    alt: productDetails?.product_name,
                                    isFluidWidth: true,
                                    src: `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name}`,
                                    sizes: '(max-width: 480px) 100vw, (max-width: 768px) 50vw, (max-width: 1200px) 30vw, 30px',
                                    srcSet: srcSet,
                                },
                                largeImage: {
                                    src: `${imgPath}${productDetails?.productimages[currentImageIndex]?.file_name}`,
                                    width: 1500,
                                    height: 1500,
                                },
                                enlargedImageContainerDimensions: {
                                    width: '100%',
                                    height: '100%',
                                },
                            }}
                        />
                    ) : (
                        <Skeleton.Image active={active} height={510} width={'100%'} size={'large'} />
                    )}

                    <div className="arrow_wrap">
                        <PrevArrow onClick={prevImage} />
                        <NextArrow onClick={nextImage} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetailImgSlider
