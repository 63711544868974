import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import ProceedSection from './ProceedSection';
import { applyCoupon } from '../../reducers/couponSlice';
import { getApplyCouponData, removeCoupon, toggleFirstCoupon } from '../../services/coupon/applyCoupon';
import { toast } from 'react-toastify';
import { STRINGS } from '../../constants/Constant';
import { setFirstOrderCoupon } from '../../reducers/firstOrderCouponSlice';

const DrawerCuppon = (props) => {
  // console.log('props',props);
  const dispatch = useDispatch();
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [isProductCouponApplied, setIsProductCouponApplied] = useState(false);
  const { drawerClose, openCupponDrawer, grandTotal, initialCoupons, disAmount, first_order_discount } = props;
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const handleCouponCode = (c_code) => {
    setCouponCode(c_code)
  }
  const productCouponId = JSON.parse(localStorage.getItem('productCoupon')) || [];

  useEffect(() => {
    setCoupons(initialCoupons.map((coupon) =>
      productCouponId.some((productCoupon) => productCoupon.couponId === coupon.id)
        ? { ...coupon, applied: true }
        : coupon
    ));
    setIsProductCouponApplied(false)
  }, [initialCoupons]);

  const handleApplyCoupon = async (couponId, coupon) => {
    // Validate the grand total against the coupon's minimum and maximum amounts
    if (grandTotal < coupon.min_amount) {
      toast.error(`Minimum order amount to apply this coupon is ₹${coupon.min_amount}`, { toastId: "1" });
      return;
    }

    if (coupon.upto_amount && grandTotal > coupon.upto_amount) {
      toast.error(`Maximum order amount to apply this coupon is ₹${coupon.upto_amount}`, { toastId: "1" });
      return;
    }

    try {
      // Prepare the form data
      const formData = new URLSearchParams();
      formData.append('coupon_code', coupon.coupon_code);

      // Fetch the coupon application result
      const response = await getApplyCouponData(formData, user.token);
      // console.log('response',response);
      if (response?.status === '1') {
        const { coupon_type, type_val, id, coupon_code } = response.data;
        const couponDis = { coupon_type, type_val, id, coupon_code };

        // Update the store and local storage
        dispatch(applyCoupon(couponDis));
        localStorage.setItem('coupon', JSON.stringify(couponDis));

        // Update the coupon list, marking the applied coupon
        setCoupons((prevCoupons) =>
          prevCoupons.map((prevCoupon) =>
            prevCoupon.id === couponId
              ? { ...prevCoupon, applied: true }
              : { ...prevCoupon, applied: false }
          )
        );
        // Mark that a product coupon has been applied
        setIsProductCouponApplied(true);
      } else if (response?.status === '0') {
        toast.error(response.message);
      }
    } catch (error) {
      if (error?.response?.data?.status === '0') {
        toast.error("Please try again later.");
        console.error('Error applying coupon:', error.response);
      }
    }
  };


  const handleRemoveCoupon = async (couponId) => {
    try {
      setCoupons((prevCoupons) =>
        prevCoupons.map((coupon) =>
          coupon.id === couponId
            ? { ...coupon, applied: false, removed: true, showRemoveConfirmation: false }
            : coupon
        )
      );
      const couponnull = {};
      dispatch(applyCoupon(couponnull));
      localStorage.setItem('coupon', JSON.stringify(couponnull));
      // Rmove coupon To DB
      await removeCoupon(user.token);
      // Optionally, you might want to notify the user or perform additional actions after removal
    } catch (error) {
      console.error('Failed to remove coupon:', error);
    }
  };

  const toggleRemoveConfirmation = (couponId) => {
    setCoupons((prevCoupons) =>
      prevCoupons.map((coupon) =>
        coupon.id === couponId
          ? { ...coupon, showRemoveConfirmation: !coupon.showRemoveConfirmation }
          : coupon
      )
    );
  }

  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleApplyButtonClick = async () => {
    const couponCodes = JSON.parse(sessionStorage.getItem('couponCodes')) || [];
    if (!couponCodes.includes(couponCode)) {
      toast.error('Invalid coupon code');
      return;
    }
    try {
      const formData = new URLSearchParams();
      formData.append('coupon_code', couponCode);
      const response = await getApplyCouponData(formData, user.token);
      // console.log('setCouponCode',response);
      if (response.status === '1') {
        const to_date = response && response.data && response.data.to_date;
        if (new Date(to_date) >= new Date()) {
          const responsecouponId = response && response.data && response.data.id;
          setCouponCode('');
          setCoupons((prevCoupons) =>
            prevCoupons.map((prevCoupon) =>
              prevCoupon.id === responsecouponId ? { ...prevCoupon, applied: true } : { ...prevCoupon, applied: false }));
          //
          const { coupon_type, type_val, id, coupon_code } = response.data;
          const couponDis = { coupon_type, type_val, id, coupon_code };
          dispatch(applyCoupon(couponDis));
          localStorage.setItem('coupon', JSON.stringify(couponDis));
        } else {
          toast.error(STRINGS.COUPON_EXPIRED);
        }
      } else {
        // Show error in toast for invalid coupon
        toast.error(STRINGS.COUPON_NOT_VALID);
      }
    } catch (error) {
      console.error('Error applying coupon:', error);
      if (couponCode === '') {
        toast.error("Please enter a coupon code")
      } else {
        toast.error(STRINGS.COUPON_NOT_VALID);
      }
    }
  };
  const { order_count, coupon_code, id } = JSON.parse(localStorage.getItem('FirstOrderCouponDetails'))?.data || {};
  // const [isFirstCoupon, setIsFirstCoupon] = useState(true);
  const [isFirstCoupon, setIsFirstCoupon] = useState(first_order_discount > 0);

  const handleFirstOrder = async () => {
    const formData = new URLSearchParams({ coupon_id: id });
    try {
      const response = await toggleFirstCoupon(formData, user.token);
      if (response?.status === "1") {
        setIsFirstCoupon(response.data.applied_status === 1);
        dispatch(setFirstOrderCoupon(response.data.applied_status === 1));
      }
    } catch (error) {
      console.error('Error toggling first coupon:', error);
    }
  };
  useEffect(() => {
    if (order_count === 0) {
      setIsFirstCoupon(true);
    }
  }, [order_count]);
  // console.log('isFirstCoupon', isFirstCoupon);

  return (
    <Drawer title={`Your Cart (${cart.length})`} onClose={drawerClose} open={openCupponDrawer} width={600}>
      <div className="drawr_cupns_main">
        <div className="cupns_input">
          <input type="text" placeholder='Enter Coupon Code' value={couponCode} onChange={handleCouponCodeChange} />
          <button onClick={handleApplyButtonClick}>Apply</button>
        </div>
        <div className="cupns_list_all">
          <div className='cupns_list_detail'>
            <div className="coupon">
              <div className="center">
                <div className='copoons_cenetr'>
                  {/* <h2 onClick={() => handleCouponCode(coupon_code)}> {coupon.coupon_code}</h2> */}
                  <h2> {coupon_code}</h2><button onClick={handleFirstOrder}>{isFirstCoupon ? "Applied" : "Apply"}</button>
                </div>
              </div>
            </div>
          </div>
          {/* // */}
          {coupons?.map((coupon) => (
            <CouponListSubItem
              key={coupon.id}
              coupon={coupon}
              onApply={handleApplyCoupon}
              onRemove={toggleRemoveConfirmation}
              handleCouponCode={handleCouponCode}
              onClose={drawerClose}
              productCouponId={productCouponId}
            />
          ))}
        </div>
      </div>
      <ProceedSection grandTotal={grandTotal} onClose={drawerClose} />

      {isProductCouponApplied && coupons?.some((coupon) => coupon.applied) && (
        <div className="show_offer_details">
          <h2>You Saved Rs. {disAmount.toFixed(2)}</h2>
          <p>Congratulations on your savings!</p>
          <button className='proceed_btn' onClick={drawerClose}>View Cart</button>
        </div>
      )}
      {coupons?.some((coupon) => coupon.showRemoveConfirmation) && (
        <div className="show_offer_details">
          <h2>Remove Coupon</h2>
          <p>Are you sure you don’t want to use this coupon?</p>
          <div className="cancel_btns">
            <button className='remove_btns_b' onClick={() => handleRemoveCoupon(coupons.find((coupon) => coupon.showRemoveConfirmation)?.id)}>Remove</button>
            <button className='remove_btns_b' onClick={drawerClose}>Cancel</button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

const CouponListSubItem = ({ coupon, onApply, onRemove, handleCouponCode, onClose, productCouponId }) => {

  const currentDate = new Date();
  const couponExpirationDate = new Date(coupon.to_date);
  currentDate.setHours(0, 0, 0, 0); // Resetting hours, minutes, seconds, and milliseconds
  couponExpirationDate.setHours(0, 0, 0, 0); // Resetting hours, minutes, seconds, and milliseconds

  return (
    <>
      <div className='cupns_list_detail'>
        <div className='per_off_detail'>
          <p>{coupon.coupon_desc}</p>
          {coupon.applied && <span><IoCheckmarkCircle style={{ fontSize: "22px" }} />Applied </span>}
        </div>
        <div className="coupon">
          <div className="center">
            <div className='copoons_cenetr'>
              <h2 onClick={() => handleCouponCode(coupon.coupon_code)}> {coupon.coupon_code}</h2>
              {
                coupon.applied ? (
                  productCouponId.some((productCoupon) => productCoupon.couponId === coupon.id) ? (
                    ""
                  ) : (
                    <button onClick={() => onRemove(coupon.id)}>Remove</button>
                  )
                ) : (
                  couponExpirationDate.getTime() >= currentDate.getTime() ? (
                    <button onClick={() => onApply(coupon.id, coupon)}>Apply</button>
                  ) : (
                    <div className='disablepayBtn'> <button disabled>Coupon Expired</button></div>
                  )
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DrawerCuppon;
