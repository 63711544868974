import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import { getNewArrivalsList } from "../../services/home";
import dImg from '../../assets/images/d.png';
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";
import AddToCart from "../../function/AddToCart";
import ProductPrice from "../ProductPrice";
import { useSelector } from "react-redux";
import LoaderLottie from '../../assets/images/loader.json';
import Lottie from 'react-lottie';
import ProductImage from "../ProductImage";

// Extract arrow component
const SliderArrow = ({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Extract loading spinner component
const LoadingSpinner = () => <Lottie options={defaultOptions} height={150} width={200} />;

const NewArrival = () => {
  const user = useSelector((state) => state.user);
  const [arrivalList, setArrivalList] = useState(null);
  const userId = user?.id ? user.id : 0;
  const hasMounted = useRef(false);
  const settings = {
    dots: false,
    infinite: arrivalList?.data?.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 576, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [imgPath, setImgPath] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasMounted.current) {
      const fetchData = async () => {
        try {
          const formData = new URLSearchParams();
          formData.append('user_id', userId);
          const response = await getNewArrivalsList(formData);
          setArrivalList(response);
          setImgPath(response.path);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
        }
      };
      fetchData();
      hasMounted.current = true;
    }
  }, [userId]);

  const renderArrows = () => (
    <div className="slider-arrow">
      <SliderArrow direction="prev" onClick={() => sliderRef.current?.slickPrev()} />
      <SliderArrow direction="next" onClick={() => sliderRef.current?.slickNext()} />
    </div>
  );

  return (
    arrivalList?.data?.length > 0 &&
    <section className='access_by_categ'>
      <div className="container-fluid">
        <div className='row'>
          <h3 className='store_headings'>Kairaus's New Arrivals</h3>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}> <LoadingSpinner /> </div>
          ) : (
            <div className='dinner_slider'>
              <Slider {...settings} ref={sliderRef}>
                {arrivalList?.data.map((product) => (
                  <div className='dinner_sliderImg' key={product?.id}>
                    {product?.productimages && product?.productimages.length > 0 ? (
                      <div className="imageContainer">
                        <Link to={`/product/${product?.product_slug}`} state={{ ProductId: product?.id }} key={product?.id}>
                        {/* ProductImage */}
                        <ProductImage imgPath={arrivalList?.path} imgName={product?.productimages[0]?.file_name} alt={product?.product_name}/>
                        {product?.productimages[1]?.file_name && <ProductImage imgPath={arrivalList?.path} imgName={product?.productimages[1]?.file_name} alt={""} className='hoverImage'/>}
                        </Link>
                        {product?.stock_quantity <= 0 ? (
                          <div className="sold_off_chips">
                            <p>Sold Out</p>
                          </div>
                        ) : (
                          product?.discount !== 0 &&
                          <div className="off_chips">
                            <p>{product?.discount}% off</p>
                          </div>
                        )}
                        <Wishlist is_wishlist={product?.is_wishlist} pId={product?.id} path={`${Routes.ProductDetail}/${product?.product_slug}`} mode='HeartOutlined' />
                        <AddToCart productList={product} imgPath={imgPath} routeName={Routes?.Home} />
                      </div>
                    ) : (
                      <img src={dImg} alt="Placeholder" />
                    )}
                    <div className='dinnerSlider_details'>
                      <Link to={`/product/${product?.product_slug}`} state={{ ProductId: product?.id }} key={product?.id}> 
                        <p>{product?.product_name}</p>
                      </Link>
                      <div className='dinnerSliderSpan'>
                        <ProductPrice product={product} />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <button className="common_btns" onClick={() => navigate(Routes.NewArrivalProducts)}>View More</button>
            </div>
          )}
          {renderArrows()}
        </div>
      </div>
    </section>
  );
};

export default NewArrival;
