import { createSlice } from '@reduxjs/toolkit';

// Initialize state from sessionStorage if available
const initialState = JSON.parse(sessionStorage.getItem('exchangeRate')) || {};

const excRateSlice = createSlice({
  name: 'excrate',
  initialState,
  reducers: {
    addToExcRate: (state, action) => {
      // Update the state with the payload
      const newState = { ...action.payload };
      
      // Store the updated exchange rate in sessionStorage
      sessionStorage.setItem('exchangeRate', JSON.stringify(newState));
      
      return newState;
    },
  },
});

export const { addToExcRate } = excRateSlice.actions;
export default excRateSlice.reducer;
