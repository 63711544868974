import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Slider from 'react-slick';
import { getBestSellerList } from "../../services/home";
import dImg from '../../assets/images/d.png';
import img1 from '../../assets/images/drinkwarecup.png';
import img2 from '../../assets/images/drinkwarecup1.png';
import img3 from '../../assets/images/drinkwarecup2.png'
import img4 from '../../assets/images/drinkwarecup3.png';
// import hoverImage from '../../assets/images/dining01.png';
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";
import { useSelector } from "react-redux";
import AddToCart from "../../function/AddToCart";
import { useLocale } from "antd/es/locale";
import { bestSelletProductWise } from "../../services/product";
import LoaderLottie from '../../assets/images/loader.json'
import Lottie from 'react-lottie';
import ProductPrice from "../ProductPrice";

// Extract arrow component
const SliderArrow = ({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
);

const productItems = [
  {
    id: 1,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img2
  },
  {
    id: 2,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img3
  },
  {
    id: 3,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img4
  },
  {
    id: 4,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img1
  },
  {
    id: 5,
    product_name: "Colorful Patterned Plates",
    old_price: "999.00",
    new_price: "1499",
    product_image: img3
  },

]

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottie,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

// Extract loading spinner component
const LoadingSpinner = () => <Lottie options={defaultOptions}
  height={150}
  width={200}
/>;



const BestSeller = ({ productDetails, sellerCategory }) => {

  const user = useSelector((state) => state.user);
  const userId = user?.id ? user.id : 0;
  const location = useLocation()
  const [sellerList, setSellerList] = useState(null);

  const hasMounted = useRef(false);


  const settings = {
    dots: false,
    infinite: sellerList?.data?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200, // defines screen size below which the settings will change
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);
  const [loading, setLoading] = useState(true);
  // console.log(sellerList,"sellerList")
  const [imgPath, setimgPath] = useState(null);

  const navigate = useNavigate();
  const { pathname } = location;

  const fetchData = async () => {
    if (pathname == '/') {
      try {
        const formData = new URLSearchParams();
        formData.append('user_id', userId);
        const response = await getBestSellerList(formData);
        // console.log('getNewArrivalsList',response);
        setSellerList(response);
        setimgPath(response.path);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    } else {
      try {
        const formData = new URLSearchParams();
        formData.append('category_id', JSON.stringify(sellerCategory));
        const response = await bestSelletProductWise(formData);
        // console.log('getNewArrivalsList',response);
        setSellerList(response);
        setimgPath(response.path);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }

    }

  };

  const removeSameProduct = sellerList?.data?.filter(sameId => sameId?.id !== productDetails?.id);
  
  useEffect(() => {
    // if (!hasMounted.current) {
    //     fetchData();
    //   hasMounted.current = true;
    // }
    fetchData();
  }, [sellerCategory]);

  const renderArrows = () => (
    <div className="slider-arrow">
      <SliderArrow direction="prev" onClick={() => sliderRef.current?.slickPrev()} />
      <SliderArrow direction="next" onClick={() => sliderRef.current?.slickNext()} />
    </div>
  );

  return (

    removeSameProduct?.length > 0 &&
    <section className='best_seller_slider'>
      <div className="container-fluid">
        <div className='row'>
          <h3 className='store_headings'>Kairaus's Spotlight</h3>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }} >  <LoadingSpinner /> </div>
          ) : (
            <div className='dinner_slider'>
              <Slider {...settings} ref={sliderRef}>
                {removeSameProduct?.map((sellerItem) => (
                  <div className='dinner_sliderImg' key={sellerItem?.id}>
                    {sellerItem?.productimages && sellerItem?.productimages.length > 0 ? (
                      <div className="imageContainer">
                        <Link to={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} state={{ProductId:sellerItem?.id}} key={sellerItem?.id}>
                          <img src={`${sellerList.path}${sellerItem?.productimages[0]?.file_name}`} alt={sellerItem?.product_name} />
                          <img src={!sellerItem?.productimages[1]?.file_name ? sellerList.path.sellerItem?.productimages[0]?.file_name:`${sellerList.path}${sellerItem?.productimages[1]?.file_name}`} className='hoverImage' alt="" />
                        </Link>

                        {sellerItem?.stock_quantity <= 0 ?
                          <div className="sold_off_chips">
                            <p>Sold Out</p>
                          </div> :
                            sellerItem?.discount !== 0 &&
                          <div className="off_chips">
                              <p>{sellerItem?.discount}% off</p>
                          </div>}

                        <Wishlist is_wishlist={sellerItem?.is_wishlist} pId={sellerItem?.id} path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} mode='HeartOutlined' />

                        <AddToCart productList={sellerItem} p_id={sellerItem?.id} imgPath={imgPath} />

                      </div>
                    ) : (
                      <img src={dImg} alt="Placeholder" />
                    )}

                    <div className='dinnerSlider_details'>
                      <Link to={`/product/${sellerItem?.product_slug}`} state={{ProductId:sellerItem?.id}} key={sellerItem?.id}> <p>{sellerItem?.product_name} </p></Link>
                      <div className='dinnerSliderSpan'>
                        {/* <span>₹{sellerItem?.price}</span>
                        {sellerItem?.price !== parseFloat(sellerItem?.compare_price) && sellerItem?.compare_price > 0 &&
                            <p>₹{sellerItem?.compare_price}</p>
                        } */}
                        <ProductPrice product={sellerItem}/>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <button className="common_btns" onClick={() => navigate(Routes.BestSeller)}>View More</button>
            </div>
          )}
          {renderArrows()}
        </div>
      </div>
    </section>
  );
};

export default BestSeller;
