import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from "react-icons/fa6";
import AddToCart from "../../function/AddToCart";
import Wishlist from "../../function/Wishlist";
import Routes from "../../Routes/Routes";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import OutOfStock from '../OutOfStock';
import { addToBuy } from '../../reducers/buySlice';
import { addToBuyDB } from '../../services/cart/addCart';
import DrawerCart from "../drawer/Drawer";


const ProductDetailButtonBox = ({ responseData }) => {
    const [productDetails, setProductDetails] = useState(null);
    const [is_wishlistt, setIs_wishlist] = useState(null);
    const [imgPath, setImgPath] = useState(null);
    const [openCartDrawer, setOpenCartDrawer] = useState(false);

    const cart = useSelector(state => state.cart);

    useEffect(() => {
        // console.log('responseData?.data==========',responseData?.data);
        
        setProductDetails(responseData?.data);
        setImgPath(responseData?.path);
        setIs_wishlist(responseData?.is_wishlist);
    }, [responseData])
    const [productCount, setProductCount] = useState(1);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const applied = useSelector(state => state.applied);
    const appliedPrice = useSelector(state => state.appliedPrice);
    const productCouponId = useSelector(state => state.productCouponId);
    const isProductOutOfStock = OutOfStock({
        productId: productDetails?.id,
        stockQuantity: productDetails?.stock_quantity,
    });

    const showDrawer = () => {
        setOpenCartDrawer(true);
      };
      const onClose = () => {        
        setOpenCartDrawer(false)
      };

    const handleCountDec = () => {
        if (productCount <= 1) {
            return;
        }
        setProductCount(prevCount => prevCount - 1);
    };

    const handleCountInc = (stock_quantity) => {
        if (productCount < stock_quantity) {
            setProductCount(productCount + 1);
        } else {
            toast.error("Oops, the count has exceeded the available stock", {
                toastId: "1",
            });
        }
    };
    const sCoupon = useSelector(state => state.productCoupon);
    const storedCouponCode = sessionStorage.getItem(`coupon_${productDetails?.id}`);
    const productCoupon = sCoupon || storedCouponCode || "";
    // console.log('productCouponproductCouponproductCoupon vivek',productCoupon);
    const excRate = useSelector(state => state.excrate);

    const handleBuyNowClick = () => {
        if (user?.id) {
            let newItems = [];
            const {
                id,
                product_name,
                compare_price,
                product_slug,
                is_wishlist,
                weight,
                unit,
                stock_quantity,
                shipping_amount_type,
                shipping_charge,
                usd_price
            } = productDetails;
            const image = `${imgPath}${productDetails?.productimages[0]?.file_name}`;
            const price = applied ? appliedPrice : productDetails?.price;
            const newItem = {
                id,
                product_name,
                product_slug,
                is_wishlist,
                price,
                compare_price,
                shipping_amount_type,
                shipping_charge,
                image,
                count: productCount,
                p_variant_id: 0,
                weight,
                unit,
                stock_quantity,
                usd_price,
                productCoupon
            };
            newItems.push(newItem);
            dispatch(addToBuy(newItems));
            localStorage.setItem("buy", JSON.stringify(newItems));
            applied &&
                localStorage.setItem("productCoupon", JSON.stringify(productCouponId));
            // navigate("/checkout");
            // add to Db
            const addToDB = async () => {
                const dataToSend = [
                    {
                        product_id: id,            // Use the actual product ID
                        quantity: productCount,     // Use the actual quantity
                        product_coupon: productCoupon, // Use the actual coupon code
                        usd_amount:usd_price,
                        currency_code:excRate?.currencyCode,
                        currency_rate:excRate?.rate,
                        currency_symbol:excRate?.currencySymbol
                       
                    }
                ];
                try {
                    const CartData = await addToBuyDB(dataToSend, user.token);
                    if (CartData.status === "1") {
                        navigate("/checkout");
                    } else {
                        toast.error("There was an issue processing your cart. Please check your connection or try again later.");
                    }

                } catch (e) {
                    //
                }
            }
            addToDB();

        } else {
            const from = `${Routes?.ProductDetail}/${productDetails?.product_slug}`;
            navigate(Routes?.SignIn, { state: { from } });
        }
    };

    return (
        <div className="buttons_threeNumber">
            <button className="btn-incriment" disabled={isProductOutOfStock}>
                <span onClick={handleCountDec}><FaMinus /></span>
                {productCount}
                <span onClick={() => handleCountInc(productDetails?.stock_quantity)}><FaPlus /></span>
            </button>
          {cart?.find(item=>item.id==productDetails?.id) ?(
            <div className='addCart_Btn'> <button onClick={()=>showDrawer()}> Go to Cart</button></div>
          ) : <AddToCart
                imgPath={imgPath}
                productList={productDetails}
                productCount={productCount}
                appliedPrice={appliedPrice}
                applied={applied}
                myClass="add-to-cart"
            />}
            {!isProductOutOfStock && (
                <button onClick={handleBuyNowClick} className="buy-now">BUY NOW</button>
            )}
            <div className="wishlist">
                {is_wishlistt !== null && (
                    <Wishlist
                        is_wishlist={is_wishlistt}
                        pId={productDetails?.id}
                        path={`${Routes.ProductDetail}/${productDetails?.product_slug}`}
                        mode="HeartOutlined"
                    />
                )}
            </div>
            <DrawerCart onClose={onClose} openDrawer={openCartDrawer} />
        </div>
    );
    
};

export default ProductDetailButtonBox;
