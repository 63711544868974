import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBestSellerAllList, getBestSellerList, getNewArrivalsList } from '../../services/home';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Typography, Collapse, Checkbox, Radio, Slider, Skeleton } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RightOutlined, HeartOutlined, PlusOutlined, MinusOutlined, CloseOutlined } from '@ant-design/icons';
import '../../assets/css/style/product.scss'
import { productApi } from '../../services/product/productApi';
import { useDispatch, useSelector } from 'react-redux';
import Wishlist from '../../function/Wishlist';
import Routes from '../../Routes/Routes';
import { addToCart } from '../../reducers/cartSlice';
import { toast } from 'react-toastify';
import { categoryApi } from '../../services/category/categoryApi';
import { LiaGreaterThanSolid } from "react-icons/lia";
import { getColor } from '../../services/filter/color';
import hoverImage from '../../assets/images/2nd.png';
import { getFilterProductList } from '../../services/filter/categories';
import { STRINGS } from '../../constants/Constant';
import { Carousel } from 'react-bootstrap';
import NoData from '../../assets/images/noData.png'
import AddToCart from '../../function/AddToCart';
import { addToChip } from '../../reducers/filterSlice';
import CommFilter from '../../components/CommFilter';
import ProductPrice from '../../components/ProductPrice';
import LoaderSpinner from '../../components/LoaderSpinner';


// const { Link: AntdLink } = Typography;
const BestSeller = () => {
    const [loading, setLoading] = useState(true);
    const location = useLocation()
    const [productList, setProductList] = useState(null);
    const [categoryImgPath, setCategoryImgPath] = useState(null);
    const [imgPath, setimgPath] = useState(null);
    const [index, setIndex] = useState(0);
    const user = useSelector((state) => state.user);
    const [category, setCategory] = useState(null);
    const userId = user?.id ? user.id : 0;
    const [sliderPath, setSliderPath] = useState(null);
    const [price, setPrice] = useState(null);
    const [slugData, setSlugData] = useState()
    const { pathname } = location;
    const staticSlug = pathname.split("/").join("")

    const fetchProducts = async () => {
        try {
            const formData = new URLSearchParams();
            formData.append('slug', staticSlug);
            const response = await getBestSellerAllList(formData);
            setProductList(response);
            setimgPath(response.path);
            setCategory(response?.slider_details);
            setCategoryImgPath(response.category_path);
            setSliderPath(response.page_slider)
            setSlugData(response?.slug)
            setPrice(response.max_price)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally{
            setLoading(false)
        }
    };

    // useEffect(() => {
    //   fetchProducts();
    // }, []);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const [gridLayout, setGridLayout] = useState('lg'); // Default layout is 'lg' (4 columns)

    const handleGridLayoutChange = (layout) => {
        setGridLayout(layout);
    };

    const renderGridIcons = () => (
        <div className="grid-icons">
            <div className="grid-icon" onClick={() => handleGridLayoutChange('sm')}>
                {/* First SVG (2 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M24 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 24H43" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('md')}>
                {/* Second SVG (3 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5" y="5" width="38" height="38" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 18H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M5 30H43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M17 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M30 5V43" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                </svg>
            </div>
            <div className="grid-icon" onClick={() => handleGridLayoutChange('lg')}>
                {/* Third SVG (4 columns) */}
                <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="4" y="4" width="40" height="40" rx="2" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M24 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M34 4V44" stroke="#333" strokeWidth="3" strokeLinecap="round" />
                    <path d="M4 14H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 34H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4 24H44" stroke="#333" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </div>
    );

    const getColSpan = () => {
        switch (gridLayout) {
            case 'sm':
                return { xs: 12, sm: 12 };
            case 'md':
                return { xs: 12, sm: 12, md: 8 };
            case 'lg':
                return { xs: 12, sm: 12, md: 8, lg: 6 };
            default:
                return { xs: 12, sm: 12, md: 8, lg: 6 };
        }
    };

    return (
        <>
            <div className='prodcut_arrivalNew'>
                <div className="drinkware_top_img_sec">
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        {category?.page_slider?.page_slider_images.map((slider, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img src={`${sliderPath}${slider.image}`} alt={slider.image_title} />
                                    {/* <Carousel.Caption>
                                        <div className='giftly_heading_para'>
                                            <h1>{slider.image_title}</h1>
                                            <p>{slider.description}</p>
                                        </div>
                                    </Carousel.Caption> */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </div>
                <div className='path_drinkware'>
                    Home <LiaGreaterThanSolid /> <p>{category?.page_slider?.title}</p>
                </div>
                <div className='drinkware_acc_carousel_section'>
                    <CommFilter setProductList={setProductList} setimgPath={setimgPath} fetchProducts={fetchProducts} setCategoryImgPath={setCategoryImgPath} setSliderPath={setSliderPath} price={price} setCategory={setCategory} />
                    <div className='right-image-glry'>
                        <div className='dinner_slider'>
                            {renderGridIcons()}
                            <Row gutter={[32, 32]}>
                                {loading ? 
                                   Array(8).fill(0).map((item, index) => {
                                    return (
                                        <Col key={`col-${index}`} xs={24} sm={12} md={8} lg={6}>
                                            <Skeleton.Image active={true} className='pro-sk-img' />
                                            <Skeleton paragraph={{
                                                rows: 1,
                                            }} />
                                        </Col>
                                    )
                                }) :
                                productList?.data?.length > 0 ?
                                    productList?.data.map((sellerItem) => (
                                        <Col {...getColSpan()} key={sellerItem?.id}>
                                            <div className='dinner_sliderImg'>
                                                <div className='dinnerCategory_cont'>
                                                    <Link to={`/product/${sellerItem?.product_slug}`} state={{ from: category?.page_slider?.title, menuSlug: slugData, ProductId: sellerItem?.id }} key={sellerItem?.id}>
                                                        <div className='imageContainer '>
                                                            <img src={`${imgPath}${sellerItem?.productimages[0]?.file_name}`} />
                                                            <img src={!sellerItem?.productimages[1] ? hoverImage : `${imgPath}${sellerItem?.productimages[1]?.file_name}`} className='hoverImage' />
                                                        </div>
                                                    </Link>
                                                    {sellerItem?.stock_quantity <= 0 ?
                                                        <div className="sold_off_chips">
                                                            <p>Sold Out</p>
                                                        </div> :
                                                        sellerItem?.discount !== 0 &&
                                                        <div className="off_chips">
                                                            <p>{sellerItem?.discount}% off</p>
                                                        </div>}

                                                    <Wishlist is_wishlist={sellerItem?.is_wishlist} pId={sellerItem?.id} path={`${Routes.ProductDetail}/${sellerItem?.product_slug}`} mode='HeartOutlined' />

                                                    <AddToCart productList={sellerItem} p_id={sellerItem?.id} imgPath={imgPath} />

                                                </div>
                                                <Link to={`/product/${sellerItem?.product_slug}`} state={{ from: "Best Sellers",  ProductId: sellerItem?.id }} key={sellerItem?.id}>
                                                    <div className='dinnerSlider_details'>
                                                        <p>{sellerItem?.product_name} </p>
                                                        <div className='dinnerSliderSpan'>
                                                            {/* <span>₹{sellerItem?.price}</span>
                                                            {sellerItem.price !== parseFloat(sellerItem.compare_price) && sellerItem.compare_price > 0 &&
                                                                <p>₹{sellerItem.compare_price}</p>
                                                            } */}
                                                            <ProductPrice product={sellerItem}/>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Col>
                                    )) :
                                    <div className='noDataCont' style={{ marginTop: 60 }}>
                                        <img src={NoData} alt='' />
                                        <h5>Coming Soon</h5>
                                    </div>
                                }
                            </Row >

                            {/* </Slider> */}
                        </div >
                    </div >
                </div >
            </div >
        </>
    );
};

export default BestSeller;