// services/orders/order.js
import axios from "axios";

// const apiConfig = {
//   baseURL: process.env.REACT_APP_API_SHIP_URL || 'https://apiv2.shiprocket.in/v1/external',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };
const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};



const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};
export const serviceability= async (formData) => {
    try {
      // {{BASEU}}/shiprocket-pincode-verift?pickup_postcode=302014&delivery_postcode=841405&weight=0.5&cod=1
        const response = await api.post('/shiprocket-pincode-verift',formData);
        return response.data;
      } catch (error) {
        return handleApiError(error);
      }
      
  };

