import { createSlice } from '@reduxjs/toolkit';

const firstOrderCouponSlice = createSlice({
  name: 'firstOrderCoupon',
  initialState: false,  // Store true/false as the initial state
  reducers: {
    setFirstOrderCoupon: (state, action) => !!action.payload,  // Ensure payload is converted to boolean
  },
});

export const { setFirstOrderCoupon } = firstOrderCouponSlice.actions;

export default firstOrderCouponSlice.reducer;
