import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer} from 'react-toastify';
import { Provider } from 'react-redux';
import store from './store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
    <App />
    </Provider>
    <ToastContainer position="bottom-center" />
    </>
);

