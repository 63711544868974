// services/search/autoSearch.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

export const getAutoSearch = async (data) => {
    try {
      const response = await api.post('/get-auto-search-product', data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  };

  export const getAllProductSearch = async (data) => {
    try {
      const response = await api.post('/get-search-keyword-product', data);
      return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  };
