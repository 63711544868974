import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getAllBlog } from '../../services/blog';
import { Row, Col, Pagination } from 'antd';
import Routes from '../../Routes/Routes';
import ProductImage from '../ProductImage';

function BlogsCategory() {
  const hasMounted = useRef(false);
  const [blogs, setBlog] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(9); // Number of blogs per page

  const fetchData = async (page = 1) => {
    try {
      const blogResponse = await getAllBlog({ page, pageSize });
      if (blogResponse.status === '1') {
        setBlog(blogResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!hasMounted.current) {
      fetchData(currentPage);
      hasMounted.current = true;
    }
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const renderBlogs = () => (
    blogs?.data?.map((item) => (
      <Col key={item.slug} xs={24} sm={12} md={8} lg={8} xl={8}>
        <div className='img_detail_one'>
          <Link to={`${Routes.BlogDetails}/${item.slug}`}>
          {item.image &&<ProductImage imgPath={blogs.path} imgName={item.image} alt={item.title}/>}
            <h5>{item.title}</h5>
          </Link>
        </div>
      </Col>
    ))
  );

  return (
    <>
      <div className='sec_blogcategory_one_heading'>
        <h1>The Kairaus Journal</h1>
      </div>

      <div className='blog_img_details_main'>
        <Row gutter={[16, 48]}>
          {renderBlogs()}
        </Row>
        <div className='blog_pagination'>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={blogs?.total || 0} // Assuming 'total' is available in the response
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default BlogsCategory;
