import React, { useEffect, useRef, useState } from "react";
import {
  Input,
  Form,
  Row,
  Col,
  Checkbox,
} from "antd";
import { toast } from "react-toastify";
import { signUpData } from "../../services/auth/singUp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToUser } from "../../reducers/userSlice";
import Routes from "../../Routes/Routes";
import { Link } from "react-router-dom";
import { addCart, getCart } from "../../services/cart/addCart";
import { addToCart } from "../../reducers/cartSlice";
import { applyCoupon } from "../../reducers/couponSlice";
import { Tooltip } from 'antd';
import logoName from "../../assets/images/KAIRAUS_logo_383x60.svg";


function Signup() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const user = useSelector((state) => state.user);
  let cart = useSelector((state) => state.cart);


  useEffect(() => {
    // Check if the user is already logged in

    if (user?.id) {
      navigate(Routes.Home, { replace: true });
    }
  }, [navigate, user]);


  const [errorOccurred, setErrorOccurred] = useState(false);

  // Fetch data from DB
  const getCartDataFromDB = async (token) => {
    try {
      const result = await getCart(token);

      if (result?.data?.length > 0) {
        const cartItems = result.data.map((item) => {
          const { id, product_name, product_slug, is_wishlist, price, compare_price, stock_quantity,usd_price,usd_compare_price} = item.product;
          const image = `${result.path}${item.product.productimages[0].file_name}`;
          const newItem = { id, product_name, product_slug, is_wishlist, image, price, count: item.quantity, p_variant_id: 0,compare_price, stock_quantity,usd_price,usd_compare_price,isChecked:true};

          return newItem;
        });
        let updatedCart = [...cart, ...cartItems];

        // To prevent by duplicate product in cart
        let uniqueIds = {};
        updatedCart = updatedCart.filter(obj => {
          if (!uniqueIds[obj.id]) {
            uniqueIds[obj.id] = true;
            return true;
          }
          return false;
        });
        localStorage.setItem('cart', JSON.stringify(updatedCart));
        dispatch(addToCart(updatedCart));
      }
    } catch (error) {
      // console.log('get cart api error', error);
    }
  };
  const [issubmit, setIssubmit] = useState(false);
  // console.log(issubmit,"issubmitissubmit")


  const handleSubmit = async (values) => {
    if (values) {
      setIssubmit(true);
    }

    const { fname, lastname, email, password, mobile_number } = values;

    try {
      const formData = new URLSearchParams();
      formData.append('firstname', fname);
      formData.append('lastname', lastname);
      formData.append('email', email);
      formData.append('mobile_number', mobile_number);
      formData.append('password', password);
      formData.append('referrer', '');
     
      const responseData = await signUpData(formData);

      if (responseData.status === '1') {
        const couponnull = {};
        dispatch(applyCoupon(couponnull));
        localStorage.setItem('coupon', JSON.stringify(couponnull));
        toast.success(responseData.message);
        const { id, email, userfullname } = responseData.data;
        const userInfo = { id, email, userfullname, token: responseData.token };
        localStorage.setItem('user', JSON.stringify(userInfo));

        // Add cart data to DB
        if (cart.length > 0) {
          const products = cart?.map(product => ({
            product_id: product.id,
            product_variant_id: product.p_variant_id,
            quantity: product.count,
            amount: product.price,
          }));
          await addCart(products, responseData.token);
        }

        getCartDataFromDB(userInfo.token);
        navigate(Routes?.ThankYouSign);
        dispatch(addToUser(userInfo));
        setIssubmit(false);
      }
    } catch (error) {
      setIssubmit(false);
      // console.error('API Request Error:', error);
      if (!errorOccurred) {
        toast.error(error?.message);
        setErrorOccurred(true);
      }

    }
  };
  const handleFormValuesChange = () => {
    setErrorOccurred(false);
  };

  const firstName = useRef(null);
  useEffect(() => {
    firstName.current.focus();
  }, [])


  const handleKeyPressForMobile = (e) => {
    const key = e.key;
    // const value = e.target.value;
    const cursorPosition = e.target.selectionStart;

    if (cursorPosition === 0 && key === '0') {
      e.preventDefault();
      return;
    }
    if (!(key >= '0' && key <= '9') && key !== 'Backspace' && key !== 'Tab' && key !== 'ArrowLeft' && key !== 'ArrowRight') {
      // If the pressed key is not a number (0-9), the backspace key, or the tab key
      e.preventDefault(); // Prevent typing
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };


  const handleKeyPressName = (e) => {
    const key = e.key;
    if ((key >= "0" && key <= "9") || !(key.match(/[a-zA-Z\s]/))) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="BD_signup_wrapper">
        <div className="bd_model_left">
        <Link to={Routes.SignIn}>
          <Tooltip title="Back">
            <button className='bacl_btn'>
              <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.79889 24H41.7989" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.7988 36L5.79883 24L17.7988 12" stroke="#333" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </Tooltip>
          </Link>
          <Link to={Routes.Home}>
          <img src={logoName} alt="logo" />
          </Link>
          <h1>Sign Up!</h1>
          <h4>
            Sign up and enjoy exclusive deals, offers, invites and rewards.
          </h4>

          <Form
            name="control-hooks"
            layout="vertical"
            onFinish={handleSubmit}
            autoComplete="off"
            onValuesChange={handleFormValuesChange}
          >
            <Row gutter={18}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fname"
                  label={<>First Name</>}
                  rules={[
                    {
                      required: true,
                      message: 'First name is required'
                    },

                  ]}
                >
                  <Input className="input_signin" ref={firstName}
                    onKeyDown={handleKeyPressName} onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ fname: trimmedValue });

                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="lastname"
                  label={<>Last Name</>}
                  rules={[{
                    required: true,
                    message: 'Last name is required'
                  },                  
                  ]}
                >
                  <Input className="input_signin" onKeyDown={handleKeyPressName}
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      form?.setFieldsValue({ lastname: trimmedValue });
                    }}
                  />
                </Form.Item>
              </Col>             

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="email"
                  label={<>Email Address</>}
                  rules={[{ required: true, message: 'Email is required' }, { type: "email", message: "Please enter a valid email" },]}
                >
                  <Input type="email" className="input_signin" onKeyDown={handleKeyPress} autoComplete="off" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="mobile_number"
                  label={<>Phone Number</>}
                  rules={[
                    { required: true, message: 'Mobile number is required' },
                    {
                      pattern: /^[0-9]{10}$/, // Validate for a 10-digit number
                      message: 'Please enter a valid mobile number',
                    },
                  ]}>
                  <Input type="text" className="input_signin" onKeyDown={handleKeyPressForMobile} maxLength={10} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="password"
                  label={<>Create Password</>}
                  rules={[{ required: true, message: "Password is required" }, {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-+=])[A-Za-z\d!@#$%^&*()-+=]{8,20}$/,
                    message: <>
                      <ul>
                        <li>Password must be between 8 to 20 characters</li>
                        <li>Password must contain at least one UpperCase letter (A-Z)</li>
                        <li>Password must contain at least one LowerCase letter (a-z)</li>
                        <li>Password must contain at least one number (0-9)</li>
                        <li>Password must contain at least one special characters</li>
                      </ul>
                    </>
                  },
                  ]}
                >
                  <Input.Password className="input_signin"
                    onKeyDown={handleKeyPress} maxLength={20} autoComplete="new-password" />
                </Form.Item>
              </Col>
             
            </Row>

            <div className="checkbox_sigup">
              <Checkbox>
                {" "}
                Yes, email me my member rewards, special invites, trend alerts &
                more,
              </Checkbox>
            </div>
           
            <div className="bd_model_button">

              <button key="submit" disabled={issubmit}>Submit</button>
            </div>
            <div className="form_footer_content">
              <p>
                Already have an account? <Link to={Routes.SignIn}>Log in</Link>
              </p>
            </div>
          </Form>
        </div >
      </div >
    </>
  );
}

export default Signup;
