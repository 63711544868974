import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TruckOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import GstInfo from '../../components/GstInfo';
import { getCustomerSupport } from '../../services/assurance';
import Dayjs from 'dayjs';
import ProductPrice from '../../components/ProductPrice';
import AmountDetails from './AmountDetails';

function Confirmation() {
    const navigate = useNavigate();
    const [supportData, setSupportData] = useState({});
    const [showGSTForm, setShowGSTForm] = useState(false);
    
    // Address information from session storage
    const from = useMemo(() => JSON.parse(sessionStorage.getItem('selectedAddress')) || {}, []);
    const {
        address = '', land_mark = '', mobile_number = '', city = {}, zip_code = '', name = '', state = {}, country = {},
        billing_name = '', billing_phone = '', billing_address = '', billing_city = {}, billing_country = {}, billing_state = {},
        billing_pincode = '', is_billing_address = ''
    } = from;
    const city_name = city?.city_name || '';
    const state_name = state?.state_name || '';
    const country_name = country?.country_name || '';
    const billingCity = billing_city?.city_name || '';
    const billingCountry = billing_country?.country_name || '';
    const billingState = billing_state?.state_name || '';

    // Get shipping fees from Redux store
    const shippingComp = useSelector(state => state.shippingFees);

    // Cart details stored in session
    const [cartDetails, setCartDetails] = useState(() => ({
        cartList: null,
        imgPath: null,
        priceDetails: null
    }));

    // Fetching customer support data on mount
    useEffect(() => {
        const fetchSupportData = async () => {
            try {
                const result = await getCustomerSupport();
                setSupportData(result?.data || {});
            } catch (error) {
                console.error('Error fetching customer support:', error);
            }
        };
        fetchSupportData();
    }, []);

    const estimatedDays = useMemo(() => {
        const bufferDays = parseInt(localStorage.getItem("bufferdays")) || 0;
        return bufferDays + (supportData?.product_buffer_days || 0);
    }, [supportData]);

    const newDateString = useMemo(() => {
        const dateFormat = 'MMM DD, YYYY';
        const originalDate = Dayjs(shippingComp, dateFormat);
        const newDate = originalDate.add(estimatedDays, 'day');
        return newDate.isValid() ? newDate.format(dateFormat) : shippingComp;
    }, [shippingComp, estimatedDays]);

    // Navigate to Payment on click
    const handleAddCart = useCallback(() => {
        navigate(Routes.Payment);
    }, [navigate]);

    const { cartList, imgPath, priceDetails } = cartDetails;
    const {
        bag_mrp = 0, kairaus_saving = 0, order_discount = 0, first_order_discount = 0,
        reward_discount = 0, shipping_amt = 0, total_amt = 0, total_items = 0
    } = priceDetails || {};

    return (
        <div className="confirmation_section">
            <AmountDetails setCartDetails={setCartDetails} />

            <h1>Confirmation for Your Product Detail</h1>
            <div className="confirmation_orders">
                <h3>Your Products</h3>
                <div className="confirmation_product">
                    {cartList?.map(item => (
                        <div className="confirmation_img" key={item?.product?.id}>
                            <Link to={`${Routes?.ProductDetail}/${item?.product?.product_slug}`} state={{ ProductId: item?.product?.id }}>
                                <img src={`${imgPath}/${item?.product?.productimage?.file_name_120_x_120}`} alt={item?.product?.product_name} />
                            </Link>
                            <div className="confirm_produtDetail">
                                <div className="confirm_wishlist">
                                    <Link to={`${Routes?.ProductDetail}/${item?.product?.product_slug}`} state={{ ProductId: item?.product?.id }}>
                                        <p>{item?.product?.product_name}</p>
                                    </Link>
                                </div>
                                <div className="confirm_price">
                                    <h5 className="pinCode_delivery"><TruckOutlined /> Estimated delivery: {newDateString}</h5>
                                    <div className="confirmPriceTag">
                                        <ProductPrice product={item?.product} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Row gutter={12}>
                <Col xs={24} sm={12}>
                    <div className='confirmation_address'>
                        <h3>Your Address</h3>
                        <div className='shipping_address'>
                            <h4>Shipping Address</h4>
                            <div className='shipping_deatils'>
                                <span>{name}</span>
                                <span>+91-{mobile_number}</span>
                                <span>{address}</span>
                                {land_mark && <span>{land_mark}</span>}
                                <span>{city_name}, {state_name}, {country_name}</span>
                                <span>{zip_code}</span>
                            </div>
                        </div>
                        {is_billing_address === '1' && (
                            <div className='shipping_address'>
                                <h4>Billing Address</h4>
                                <div className='shipping_deatils'>
                                    <span>{billing_name}</span>
                                    <span>+91-{billing_phone}</span>
                                    <span>{billing_address}</span>
                                    <span>{billingCity}, {billingState}, {billingCountry}</span>
                                    <span>{billing_pincode}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </Col>
                <Col xs={24} sm={12}>
                    <div className="confirmation_billing">
                        <h3>Your Bill</h3>
                        <div className="billing_details">
                            <div className="bid_mrp">
                                <p>Big MRP ({total_items} items)</p>
                                <span>₹{bag_mrp.toFixed(2)}</span>
                            </div>
                            <div className="bid_mrp">
                                <p>Kairaus Savings</p>
                                <span>{kairaus_saving ? `-₹${kairaus_saving.toFixed(2)}` : '0'}</span>
                            </div>
                            {order_discount > 0 && (
                                <div className="bid_mrp">
                                    <p>Coupons Discount</p>
                                    <span>-₹{order_discount.toFixed(2)}</span>
                                </div>
                            )}
                            {reward_discount > 0 && (
                                <div className="bid_mrp">
                                    <p>Reward Points Discount</p>
                                    <span>-₹{reward_discount.toFixed(2)}</span>
                                </div>
                            )}
                            {first_order_discount > 0 && (
                                <div className="bid_mrp">
                                    <p>First Order Discount</p>
                                    <span>-₹{first_order_discount.toFixed(2)}</span>
                                </div>
                            )}
                            <div className="bid_mrp">
                                <p>Shipping Charges</p>
                                <span>{shipping_amt > 0 ? `+ ₹${shipping_amt}` : 'Free'}</span>
                            </div>
                            <div className="bid_total">
                                <p>Total</p>
                                <span>₹{total_amt.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>
                    <GstInfo showGSTForm={showGSTForm} setShowGSTForm={setShowGSTForm} />
                </Col>
            </Row>

            <div className={showGSTForm ? 'disablepayBtn' : 'confirm_pay'}>
                <button disabled={showGSTForm} onClick={handleAddCart}>Pay Now</button>
            </div>
        </div>
    );
}

export default Confirmation;