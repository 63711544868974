import { useSelector } from "react-redux";

const PriceDetails = ({ cartDetailPrice }) => {
  const {
    bag_mrp =0,
    kairaus_saving =0,
    order_discount =0,
    first_order_discount =0,
    reward_discount =0,
    shipping_amt =0,
    total_amt =0,
    total_items =0,
  } = cartDetailPrice ||{};

//   const shippingCharges = useSelector((state) => state.shippingFees);
  const excRate = useSelector((state) => state.excrate);
  const isINR = excRate?.currencyCode === "INR";
  const currencySymbol = excRate?.currencySymbol || excRate?.currencyCode || "$";

  return (
    <div className="prize_detail">
      <div className="prize_dra">
        <p>Bag MRP {`(${total_items} items)`}</p>
        <p>{isINR ? "₹" : currencySymbol} {bag_mrp.toFixed(2)}</p>
      </div>
      <div className="prize_dra">
        <p>Bag Discount</p>
        <p>{kairaus_saving ? `-${isINR ? "₹" : currencySymbol} ${kairaus_saving.toFixed(2)}` : "0"}</p>
      </div>
      {order_discount > 0 && (
        <div className="prize_dra">
          <p>Coupon Discount</p>
          <span>-{isINR ? "₹" : currencySymbol} {order_discount.toFixed(2)}</span>
        </div>
      )}
      <div className="prize_dra">
        <p>Shipping Charges</p>
        <span>
          {shipping_amt > 0 ? `+${isINR ? "₹" : currencySymbol} ${shipping_amt.toFixed(2)}` : "Free"}
        </span>
      </div>
      {reward_discount > 0 && (
        <div className="prize_dra">
          <p>Reward Points Discount</p>
          <p>-{isINR ? "₹" : currencySymbol} {reward_discount.toFixed(2)}</p>
        </div>
      )}
      {first_order_discount > 0 && (
        <div className="prize_dra">
          <p>First Order Discount</p>
          <span>-{isINR ? "₹" : currencySymbol} {first_order_discount.toFixed(2)}</span>
        </div>
      )}
      <div className="prize_dra">
        <p className="p_bold">Total</p>
        <p className="p_bold">{isINR ? "₹" : currencySymbol} {total_amt.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default PriceDetails;
