import axios from 'axios';
const apiConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
};
const api = axios.create(apiConfig);

const handleApiError = (error) => {
    // console.errror('API Error:', error);
    throw error;
}

//  About Us API
export const getFaqCategory= async (data,token) => {
  try {
    const response = await api.post('/get-faq-category', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};
export const getFaqList= async (data,token) => {
    try {
      const response = await api.post('/get-faq-list', data, {
          headers: {
            Authorization: token,
          },
        });
        return response.data;
    } catch (error) {
      return handleApiError(error);
    }
  };

export const getFaqDetailList = async (data) => {
    try {
        const response = await api.post('/get-product-faq-list',data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(`API Error: ${response.statusText}`)
        }
    } catch (error) {
        return handleApiError(error);
    }
}


