import axios from "axios";
const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

 const editUserProfile= async (data,token) => {
  try {
    const response = await api.post('/edit-user-profile', data, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        // throw new Error(`API Error: ${response.statusText}`);
        return response.statusText;
      }
  } catch (error) {
    return handleApiError(error);
    // return error;
  }
};

const getProfileDetail= async (token) => {
  try {
    const response = await api.get('/user-profile', {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

const changePassword= async (data,token) => {
  try {
    const response = await api.post('/change-password', data, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    // return handleApiError(error);
    return error;
  }
};

const forgotPassword = async (data) => {
  try {
    const response = await api.post('/forgot-password', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

const getOrderDetail= async (token) => {
  try {
    const response = await api.get('/get-order-list', {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

const sendOtp= async (data,token) => {
  try {
    const response = await api.post('/send-mobile-verify-otp', data, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

const verifyOtp= async (data,token) => {
  try {
    const response = await api.post('/verify-mobile-otp', data, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

const downloadInvoice= async (data,token) => {
  try {
    const response = await api.post('/download-invoice', data, {
        headers: {
          Authorization: token,
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(response.statusText);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

const resetPassword = async (data) => {
  try {
    const response = await api.post('/reset-password', data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};
export const authApi = {editUserProfile,getProfileDetail, changePassword, forgotPassword, getOrderDetail, sendOtp, verifyOtp, downloadInvoice, resetPassword}
