import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useCallback } from "react";
import Slider from 'react-slick';
import { getHomeFinestProductList } from "../../services/home";
import dImg from '../../assets/images/d.png';
import img1 from '../../assets/images/drinkwarecup.png';
import img2 from '../../assets/images/drinkwarecup1.png';
import img3 from '../../assets/images/drinkwarecup2.png';
import img4 from '../../assets/images/drinkwarecup3.png';
import hoverImage from '../../assets/images/dining01.png';
import Routes from "../../Routes/Routes";
import Wishlist from "../../function/Wishlist";
import AddToCart from "../../function/AddToCart";
import LoaderLottie from '../../assets/images/loader.json';
import Lottie from 'react-lottie';
import ProductPrice from "../ProductPrice";
import { useSelector } from "react-redux";
import ProductImage from "../ProductImage";

// Extract arrow component
const SliderArrow = ({ direction, onClick }) => (
  <div className={`sa_${direction}Arrow`} onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="29" viewBox="0 0 19 29" fill="none">
      <path d={direction === "prev" ? "M17 27L3 14.5L17 2" : "M2 27L16 14.5L2 2"} stroke="black" strokeOpacity="0.3" strokeWidth="5" strokeLinejoin="round" />
    </svg>
  </div>
);

// Extract loading spinner component
const LoadingSpinner = () => (
  <Lottie options={{
    loop: true,
    autoplay: true,
    animationData: LoaderLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }} height={150} width={200} />
);

const FinestProduct = () => {
  const user = useSelector((state) => state.user);
  const [sellerList, setSellerList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgPath, setImgPath] = useState(null);
  const sliderRef = useRef(null);
  const hasMounted = useRef(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const formData = new URLSearchParams();
      formData.append('user_id', user?.id || 0);
      const response = await getHomeFinestProductList(formData);
      setSellerList(response);
      setImgPath(response.path);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    if (!hasMounted.current) {
      fetchData();
      hasMounted.current = true;
    }
  }, [fetchData]);

  const settings = {
    dots: false,
    infinite: sellerList?.data?.length > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 576, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const renderArrows = () => (
    <div className="slider-arrow">
      <SliderArrow direction="prev" onClick={() => sliderRef.current?.slickPrev()} />
      <SliderArrow direction="next" onClick={() => sliderRef.current?.slickNext()} />
    </div>
  );

  return (
    sellerList?.data?.length > 0 && (
      <section className='best_seller_slider'>
        <div className="container-fluid">
          <div className='row'>
            <h3 className='store_headings'>Kairaus's Handpicked Curation</h3>
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}><LoadingSpinner /></div>
            ) : (
              <div className='dinner_slider'>
                <Slider {...settings} ref={sliderRef}>
                  {sellerList?.data.slice(0, 9)?.map((item) => (
                    <div className='dinner_sliderImg' key={item.id}>
                      {item.productimages && item.productimages.length > 0 ? (
                        <div className="imageContainer">
                          <Link to={`/product/${item.product_slug}`} state={{ ProductId: item.id }}>
                          <ProductImage imgPath={imgPath} imgName={item?.productimages[0]?.file_name} alt={`Image for ${item?.product_name}`} />
                          {item?.productimages[1]?.file_name&&<ProductImage imgPath={imgPath} imgName={item?.productimages[1]?.file_name} alt={`Image for ${item?.product_name}`} className={'hoverImage'}/>}
                          </Link>
                          {item.stock_quantity <= 0 ? (
                            <div className="sold_off_chips">
                              <p>Sold Out</p>
                            </div>
                          ) : item.discount !== 0 && (
                            <div className="off_chips">
                              <p>{item.discount}% off</p>
                            </div>
                          )}
                          <Wishlist is_wishlist={item.is_wishlist} pId={item.id} path={`${Routes.ProductDetail}/${item.product_slug}`} mode='HeartOutlined' />
                          <AddToCart productList={item} p_id={item.id} imgPath={imgPath} />
                        </div>
                      ) : (
                        <img src={dImg} alt="Placeholder" />
                      )}
                      <div className='dinnerSlider_details'>
                        <Link to={`/product/${item.product_slug}`} state={{ ProductId: item.id }}>
                          <p>{item.product_name}</p>
                        </Link>
                        <div className='dinnerSliderSpan'>
                          <ProductPrice product={item} />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
                <Link to={Routes.FinestProduct}>
                  <button className="common_btns">View More</button>
                </Link>
              </div>
            )}
            {renderArrows()}
          </div>
        </div>
      </section>
    )
  );
};

export default FinestProduct;
