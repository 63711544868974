// services/orders/order.js
import axios from "axios";

const apiConfig = {
  baseURL: process.env.REACT_APP_API_SHIP_URL || 'https://apiv2.shiprocket.in/v1/external',
  headers: {
    'Content-Type': 'application/json',
  },
};
const aConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);
const apiDB = axios.create(aConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};
// ShipRocket login 
export const generateToken = async (authData) =>
{
  try {
    const response = await api.post('/auth/login', authData);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};
// ShipRocket
export const createCustomOrder = async (data, token) =>
{
  try {
    const response = await api.post('/orders/create/adhoc', data, {
      headers: {
        Authorization: `Bearer${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

// use db
export const createOrder =async (data, token)=>{
  try{
    const response = await apiDB.post('/pay-payment', data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}
// update
export const orderShipmentUpdate =async (data, token)=>{
  try{
    const response = await apiDB.post('/order-shipment-update', data, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}
export const trackingOrder= async (orderId,companyId) => {
  try {
    const response = await axios.get(`https://shiprocket.co/tracking/order/${orderId}?company_id=${companyId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`API Error: ${response.statusText}`);
      }
  } catch (error) {
    return handleApiError(error);
  }
};

export const initiateOrder =async (data)=>{
  try{
    const response = await axios.post('http://api.growthgrids.com:3011/api/user/ccavenue/initiate-payment', data, {
      // headers: {
      //   Authorization: token,
      // },
    });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
}

