import React, { useState, useCallback } from "react";
import { Input, Skeleton } from "antd";
import { serviceability } from "../../services/ship/courierServiceability";
import { toast } from "react-toastify";

const ProductDetailPinCode = ({ isProLoading }) => {
    const [pincode, setPincode] = useState('');
    const [pinVerified, setPinVerified] = useState(false);
    const [updatePinCode, setUpdatePinCode] = useState([]);
    const [pincodeError, setPincodeError] = useState('');

    const handleKeyDown = useCallback((e) => {
        const key = e.key;
        const input = e.target.value;
        if (key === "0" && (input === "" || e.target.selectionStart === 0)) {
            e.preventDefault();
        }
        if (
            !(key >= "0" && key <= "9") &&
            key !== "Backspace" &&
            key !== "Tab" &&
            key !== "ArrowLeft" &&
            key !== "ArrowRight"
        ) {
            e.preventDefault();
        }
    }, []);

    const handlePincode = useCallback(async (newPin) => {
        if (newPin) {
            const formData = new URLSearchParams();
            formData.append("delivery_postcode", newPin);
            formData.append("pickup_postcode", "302014");
            formData.append("weight", "0.5");
            formData.append("cod", "1");
            try {
                const response = await serviceability(formData);
                const availableCourierCompanies = response?.data?.available_courier_companies || [];
                setPinVerified(availableCourierCompanies.length > 0);
                setUpdatePinCode(availableCourierCompanies);

                if (!availableCourierCompanies.length) {
                    setPincodeError("No delivery found with this pincode");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("An error occurred while checking the pincode");
            }
        } else {
            toast.error("Please Enter Pincode");
        }
    }, []);

    const pincodeChange = useCallback((e) => {
        const newPincode = e.target.value;
        setPincode(newPincode);
        setPinVerified(false);
        if (newPincode.length > 5) {
            handlePincode(newPincode);
            setPincodeError('');
        }
    }, [handlePincode]);

    return (
        <>
        
            <span className='pinCode_availability mt-3'>
                Please enter PIN code to check delivery time & Pay on Delivery Availability
            </span>
            <div className="pincode-btn">
                {isProLoading ? (
                    <Skeleton height={40} width={200} />
                ) : (
                    <>
                        <Input
                            placeholder="Enter Your Pincode"
                            onKeyDown={handleKeyDown}
                            value={pincode}
                            className='pincode'
                            onChange={pincodeChange}
                            maxLength={6}
                        />
                        {/* {pincode.length > 5 && (
                            <button className='checkout'>Check</button>
                        )} */}
                    </>
                )}
            </div>
            <h5 className='pinCode_delivery'>
                {pinVerified ? (
                    <p>
                        Estimated Delivery, {updatePinCode[0]?.etd && new Date(new Date(updatePinCode[0]?.etd).getTime() + 3 * 24 * 60 * 60 * 1000).toLocaleDateString("en-US", { month: 'short', day: 'numeric', year: 'numeric' })}
                    </p>
                ) : (
                    pinVerified === false && pincode.length > 5 && (
                        <p style={{ color: "red" }}>{pincodeError}</p>
                    )
                )}
            </h5>
        </>
    );
};

export default ProductDetailPinCode;
