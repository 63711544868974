// services/cart/addCart.js
import axios from "axios";
import { clearUser } from "../../reducers/userSlice";
import { getStoreInstance } from "../../storeInstance";
// const apiConfig = {
//   baseURL: 'http://localhost:3000/api' || 'https://demo1.growthgrids.com/api/user',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };
const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://api.growthgrids.com:3011/api/user',
  headers: {
    'Content-Type': 'application/json',
  },
};

const api = axios.create(apiConfig);

const handleApiError = (error) => {
  // console.error('API Error:', error);
  let errorMessage = 'An error occurred. Please try again later.';
  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  }
  throw new Error(errorMessage);
};

const handleUnauthorized = (error) => {
  if (error.response?.status === 401) {
    localStorage.removeItem('user');
    const storeInstance = getStoreInstance();
    if (storeInstance) {
      storeInstance.dispatch(clearUser());
    }
    return 'Unauthorized. Please log in again.';
  }
  return handleApiError(error);
};

export const addCart = async (data, token) => {
    try {
      const response = await api.post('/add-cart', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      return handleUnauthorized(error);
    }
  };

export const getCart = async (token) => {
    try {
      const response = await api.get('/cart',{
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      return handleUnauthorized(error);
    }
  };

  export const deleteCart = async (data) => {
    const user = localStorage.getItem('user');
    const tokenLocal = JSON.parse(user);
    const newToken = tokenLocal.token;
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": newToken
    }

    try {
        const response = await api.delete('/delete-cart', { data: data, headers: headers })
        return response
    }
    catch (error) {
      return handleUnauthorized(error);
    }
  }
  export const updateCartCount = async (data,token) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": token
    }
    try {
        const response = await api.post('/update-cart', data, {headers: headers })
        return response
    }
    catch (error) {
      return handleUnauthorized(error);
    }
  }
  export const updateProductCoupon = async (data,token) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": token
    }
    try {
        const response = await api.post('/update-product-discount', data, {headers: headers })
        return response
    }
    catch (error) {
      return handleUnauthorized(error);
    }
  }
  export const updateCartStatus = async (data, token) => {
    try {
      const response = await api.post('/update-cart-status', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      return handleUnauthorized(error);
    }
  };
  //
  export const addToBuyDB= async (data, token) => {
    try {
      const response = await api.post('/buy-now', data, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      return handleUnauthorized(error);
    }
  };
  export const removeItemFromBuyNowDB= async (token) => {
    try {
      const response = await api.post('/remove-buy-now',{}, {
        headers: {
          Authorization: token,
        },
      });
      return response.data;
    } catch (error) {
      return handleUnauthorized(error);
    }
  };
