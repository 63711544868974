import React, { useState, useEffect } from "react";
import { Radio } from 'antd';
import Routes from "../../Routes/Routes";
import { useNavigate } from "react-router-dom";
import { getAttributeTypeList, getPrimaryVarientAttribute, getVarientWiseAttribute } from "../../services/product";

const ProductDetailVariant = ({ responseData }) => {
    const navigate = useNavigate();
    const [defaultSelected, setDefaultSelected] = useState({});
    const [isVariant, setIsVariant] = useState(true);
    const [attributeTypes, setAttributeTypes] = useState(null);
    const [showVariantListing, setShowVariantListing] = useState(false);
    const [defaultColor, setDefaultColor] = useState(null);
    const [colorImgPath, setColorImgPath] = useState([]);

    useEffect(() => {
        if (responseData?.is_variant === 1) {
            setColorImgPath(responseData.color_img_path);
            const { id = 0 } = responseData?.data;
            setShowVariantListing(true);
            setIsVariant(true);
            let sortedProductAttribute = responseData?.product_attribute?.sort(
                (a, b) => a.attr_type_id - b?.attr_type_id
            );

            let primaryVariant = sortedProductAttribute.filter((item) => item?.attr_type_id === responseData?.primary_variant_detail?.variants?.attr_type_id);

            if (primaryVariant?.length > 0) {
                fetchAttbtTypeList(
                    primaryVariant[0]?.attr_val_id,
                    id,
                    responseData?.product_attribute,
                    primaryVariant[0]?.attr_type_id
                );
            }
        } else if (
            responseData?.is_variant === 0 &&
            responseData?.product_attribute.length > 0
        ) {
            setShowVariantListing(true);
            setIsVariant(false);
            let defaultColorName = responseData?.product_attribute?.find(
                (item) => item.attr_type_id === 2
            )?.product_attr_value?.attr_val_name;
            setDefaultColor(defaultColorName);
        } else if (
            responseData?.is_variant === 0 &&
            responseData?.product_attribute.length < 1
        ) {
            setIsVariant(false);
            setShowVariantListing(false);
        }
    }, [responseData]);
    const handleRadioChangeForColor = (e) => {
        // setColorSelectedValue(e.target.value);
    };

    const variantSlugNavigate = (slug) => {
        navigate(Routes?.ProductDetail + "/" + slug);
        // fetchDataAndRelatedData(slug);
    };

    // Fetch Attribute types list
    // Fetch Attribute types list
    const fetchAttbtTypeList = async (val, prodId, prodArr, primTypeId) => {
        try {
            const attributeTypeListresponse = await getAttributeTypeList(); // First API
            if (attributeTypeListresponse.status != 1) {
                throw new Error("Failed to fetch attribute type list");
            }
            // setColorImgPathVar(attributeTypeListresponse?.path);

            const promises = attributeTypeListresponse?.data?.map(
                async (attribute, index) => {
                    const formData = new URLSearchParams();
                    formData.append("product_id", prodId);
                    formData.append("attr_type_id", attribute?.id);

                    const childResponse = await getPrimaryVarientAttribute(formData); // Second API
                    if (childResponse?.status !== "1") {
                        throw new Error("Failed to fetch primary variant attribute");
                    }

                    const primaryObj = childResponse?.data?.find(
                        (item) => item?.attr_type_id === primTypeId
                    );

                    let thirdResponse = "";
                    if (index === attributeTypeListresponse?.data?.length - 1) {
                        // console.log('prodArr===451==', prodArr);
                        // console.log('prodArr[index].attr_val_id==452==',primTypeId==2 ? prodArr[index-1].attr_val_id : prodArr[index-2].attr_val_id);
                        const thirdFormData = new URLSearchParams();
                        thirdFormData.append("attr_val_id", val);
                        thirdFormData.append(
                            "attr_second_val_id",
                            primTypeId === 2
                                ? prodArr[index - 1].attr_val_id
                                : prodArr[index - 2].attr_val_id
                        );
                        thirdFormData.append("attr_type_id", attribute?.id);
                        thirdFormData.append(
                            "variant_id",
                            childResponse?.variant_detail?.variant_id
                        );

                        thirdResponse = await getVarientWiseAttribute(thirdFormData); // Third API
                        if (thirdResponse?.status !== "1") {
                            throw new Error("Failed to fetch variant wise attribute");
                        }
                    } else {
                        // console.log('prodArr[index].attr_val_id==465', prodArr[index].attr_val_id);
                        const thirdFormData = new URLSearchParams();
                        thirdFormData.append("attr_val_id", val);
                        thirdFormData.append(
                            "attr_second_val_id",
                            attribute?.id === 3 ? prodArr[index - 1].attr_val_id : ""
                        );
                        thirdFormData.append("attr_type_id", attribute?.id);
                        thirdFormData.append(
                            "variant_id",
                            childResponse?.variant_detail?.variant_id
                        );

                        thirdResponse = await getVarientWiseAttribute(thirdFormData); // Third API
                        if (thirdResponse?.status !== "1") {
                            throw new Error("Failed to fetch variant wise attribute");
                        }
                    }

                    // console.log('thirdResponse.data===465===>>>>>', thirdResponse);
                    ////
                    let newArr = thirdResponse?.data?.filter((item) =>
                        prodArr?.some((attr) => attr?.attr_val_id === item?.attr_val_id)
                    );

                    const mergeArrays = (allData, data) => {
                        // Create a set of data item identifiers for quick lookup
                        const dataSet = new Set(
                            data.map((item) => `${item.attr_type_id}-${item.attr_val_id}`)
                        );

                        // Get items from data array with 'active' key
                        const activeItems = data.map((item) => {
                            // const identifier = `${item.attr_type_id}-${item.attr_val_id}`;
                            return {
                                ...item,
                                active: "active",
                            };
                        });

                        // Get items from allData array that are not in data array, with 'not active' key
                        const notActiveItems = allData
                            .filter(
                                (item) =>
                                    !dataSet.has(`${item.attr_type_id}-${item.attr_val_id}`)
                            )
                            .map((item) => ({
                                ...item,
                                active: "not active",
                            }));

                        // Combine active and not active items
                        return [...activeItems, ...notActiveItems];
                    };

                    const mergedArray = mergeArrays(
                        thirdResponse?.all_data,
                        thirdResponse?.data
                    );
                    // console.log('mergedArray===', mergedArray);
                    // setDefaultSelected(newArr[0]?.attr_val_id);
                    defaultSelected[attribute?.id] = newArr[0]?.attr_val_id;
                    // console.log('defaultSelected===',defaultSelected);
                    setDefaultSelected(defaultSelected);
                    // console.log('primTypeId=========448==',primTypeId);
                    return {
                        attribute,
                        primaryChildrenData: childResponse?.data,
                        childreData: mergedArray,
                        primaryVariantId: primTypeId,
                        primaryObj: primaryObj,
                    };
                }
            );

            const results = await Promise.all(promises);
            // console.log('results===456===>>>>>', results);

            results.sort((a, b) => {
                if (a.primaryObj && !b.primaryObj) {
                    return -1; // a comes before b
                } else if (!a.primaryObj && b.primaryObj) {
                    return 1; // b comes before a
                } else {
                    return 0; // No change in order
                }
            });

            setAttributeTypes(results);
        } catch (error) {
            console.error("Error:", error);
        }

    };
    return (
        
        <>
        {showVariantListing&&(
            isVariant ? (
                <div className="choooseSize">
                    {attributeTypes?.map(
                        ({
                            attribute,
                            childreData,
                            primaryChildrenData,
                            primaryVariantId,
                        }) => (
                            <div key={attribute?.id}>
                                <h4>{attribute?.attr_type_name}</h4>
                                <div className='chooseSizeButton chossewidth'>
                                    {attribute?.id === primaryVariantId
                                        ? primaryChildrenData?.map((child) => (
                                            <Radio.Group
                                                key={child?.attr_val_id}
                                                value={defaultSelected[attribute?.id]}
                                                onChange={handleRadioChangeForColor}
                                            >
                                                <Radio.Button
                                                    value={child?.attr_val_id}
                                                    onClick={() =>
                                                        variantSlugNavigate(
                                                            child?.products?.product_slug
                                                        )
                                                    }
                                                    className={attribute?.attr_type_name === "Color" ? "noborder" : ""}
                                                >
                                                    {attribute?.attr_type_name === "Color" ? (
                                                        attribute?.attr_type_name === "Color" && defaultSelected[attribute?.id] === child?.attr_val_id ? (
                                                            <div className="radioBtn_color">
                                                                <div className="checked_icon">
                                                                    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 24L20 34L40 14" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                                </div>
                                                                <img
                                                                    src={
                                                                        colorImgPath +
                                                                        child?.product_attr_value?.image
                                                                    }
                                                                    alt={
                                                                        child?.product_attr_value
                                                                            ?.attr_val_name
                                                                    }
                                                                />
                                                            </div>
                                                        ) :
                                                            <div className="radioBtn_color">
                                                                <img
                                                                    src={
                                                                        colorImgPath +
                                                                        child?.product_attr_value?.image
                                                                    }
                                                                    alt={
                                                                        child?.product_attr_value
                                                                            ?.attr_val_name
                                                                    }
                                                                />
                                                            </div>
                                                    ) : (
                                                        child?.product_attr_value?.attr_val_name
                                                    )}
                                                </Radio.Button>
                                            </Radio.Group>
                                        ))
                                        : childreData?.map((child) => (
                                            <Radio.Group
                                                key={child?.attr_val_id}
                                                value={defaultSelected[attribute?.id]}
                                                onChange={handleRadioChangeForColor}
                                            >
                                                <Radio.Button
                                                    value={child?.attr_val_id}
                                                    onClick={() =>
                                                        variantSlugNavigate(
                                                            child?.products?.product_slug
                                                        )
                                                    }
                                                    className={attribute?.attr_type_name === "Color" ? "noborder" : ""}
                                                >
                                                    {attribute?.attr_type_name === "Color" ? (
                                                        attribute?.attr_type_name === "Color" && defaultSelected[attribute?.id] === child?.attr_val_id ? (
                                                            <div className="radioBtn_color">
                                                                <div className="checked_icon">
                                                                    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 24L20 34L40 14" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" /></svg>
                                                                </div>
                                                                <img
                                                                    src={
                                                                        colorImgPath +
                                                                        child?.product_attr_value?.image
                                                                    }
                                                                    alt={
                                                                        child?.product_attr_value
                                                                            ?.attr_val_name
                                                                    }
                                                                />
                                                            </div>
                                                        ) :
                                                            <div className="radioBtn_color">
                                                                <img
                                                                    src={
                                                                        colorImgPath +
                                                                        child?.product_attr_value?.image
                                                                    }
                                                                    alt={
                                                                        child?.product_attr_value
                                                                            ?.attr_val_name
                                                                    }
                                                                />
                                                            </div>
                                                    ) : (
                                                        child?.product_attr_value?.attr_val_name
                                                    )}
                                                </Radio.Button>
                                            </Radio.Group>
                                        ))}
                                </div>
                            </div>
                        )
                    )}
                </div>
            ) : (
                <>
                    {defaultColor && (
                        <div className="defaultColor">
                            <p>Default Color: {defaultColor}</p>
                        </div>
                    )}
                </>
            )
        )}
        </>
        
    );
};

export default ProductDetailVariant;
