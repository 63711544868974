import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
};
const api = axios.create(apiConfig);

// Create a separate function for handling API errors
const handleApiError = (error) => {
  // console.error('API Error:', error);
  throw error;
};

// get StaticPage
export const getStaticPage = async (slug)=>{
  try {
    const response = await api.get(`/static-page/${slug}`);
    if(response.status === 200){
      return response.data;
    }else{
      throw  new Error(`API Error: ${response.statusText}`);
    }
  }catch(error){
    return handleApiError(error);
  }
}
