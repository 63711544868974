import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd'; // Adjust the import based on your project structure

import img1 from "../../assets/images/drinkwarecup.png";
import { comboProduct } from '../../services/assurance';
import {useNavigate } from 'react-router-dom';
import { addToCart } from '../../reducers/cartSlice';
import { addCart, addToBuyDB } from '../../services/cart/addCart';
import { STRINGS } from '../../constants/Constant';
import { toast } from 'react-toastify';
import { addToBuy, clearBuy } from '../../reducers/buySlice';
import Routes from "../../Routes/Routes";

const ProductCombo = ({ comboProductId, comboProductCategoryId, productDetails }) => {
    // console.log("productDetails vivek",productDetails?.stock_quantity);  
    const user = useSelector((state) => state.user);
    const cart = useSelector((state) => state.cart);
    // console.log('cartcartcartcart',cart);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productCombo, setProductCombo] = useState([]);
    const [firstIndexData, setFirstIndexData] = useState();
    const [suggetOthers, setSuggestOthers] = useState([]);
    const [comboImages, setComboImages] = useState();
    const [remainingIndex, setRemainingIndex] = useState(2);
    const [isOutOfStock, setIsOutOfStock] = useState(false);

    // Changes and optimizations:
    const [comboChecked, setComboChecked] = useState(
        Array(productCombo.length).fill(true)
    );
    //
    const calculateFilteredCartCount = () => {
        // Check productDetails
        const filteredProductDetails = cart.find(product => product.id === productDetails?.id);
        if (filteredProductDetails) {
            const outOfStockDetails = filteredProductDetails.count === filteredProductDetails.stock_quantity;
            if (outOfStockDetails) return true;
        }
    
        // Check productCombo
        const filteredProductCombo = cart.find(product => productCombo.some(combo => combo.id === product.id));
        if (filteredProductCombo) {
            const outOfStockCombo = filteredProductCombo.count === filteredProductCombo.stock_quantity;
            if (outOfStockCombo) return true;
        }
    
        return false; // Assuming that if neither the productDetails nor productCombo are found to be out of stock
    };
    
    // Effect to update out of stock status based on cart contents
    useEffect(() => {
        const outOfStock = calculateFilteredCartCount();
        setIsOutOfStock(outOfStock);
    }, [cart, productDetails, productCombo]);

    const handleComboCheckbox = (index) => {
        const newCheckedStates = [...comboChecked];
        newCheckedStates[index] = !newCheckedStates[index];
        setComboChecked(newCheckedStates);
    };

    //
    const checkedData = useMemo(() => productCombo.filter((_, index) => !comboChecked[index]), [productCombo, comboChecked]);
    const newArray = useMemo(() => [...checkedData, firstIndexData], [checkedData, firstIndexData]);
    const totalPrice = useMemo(() => newArray.reduce((total, comboItem) => total + comboItem?.price, 0), [newArray]);


    const addItemToCart = async (item) => {
        const cartData = JSON.parse(localStorage.getItem("cart")) || [];
        const { id, product_name, product_slug, is_wishlist, price, compare_price, weight, unit, stock_quantity, shipping_amount_type, shipping_charge } = item;
        const image = `${comboImages}${item?.productimages?.[0]?.file_name_120_x_120}`;
        const newItem = { id, product_name, product_slug, is_wishlist, price, image, shipping_amount_type, shipping_charge, count: 1, p_variant_id: 0, compare_price, weight, unit, stock_quantity };
        const productForDB = { product_id: newItem.id, product_variant_id: newItem.p_variant_id, quantity: newItem.count, amount: newItem.price };
        const existingItemIndex = cartData.findIndex((cartItem) => cartItem.id === newItem.id);

        let updatedCart;
        if (existingItemIndex === -1) {
            updatedCart = [...cartData, newItem];
        } else {
            updatedCart = [...cartData];
            const existingItem = updatedCart[existingItemIndex];
            if (existingItem.count < stock_quantity) {
                // updatedCart = [...cartData];
                updatedCart[existingItemIndex] = { ...updatedCart[existingItemIndex], count: updatedCart[existingItemIndex].count + 1 };
            } else {
                // Item count reached stock quantity, handle as sold out
                setIsOutOfStock(true)
                toast.warning(STRINGS.OUT_OF_STOCK, { toastId: "2" });
                return;
            }
            
        }

        localStorage.setItem("cart", JSON.stringify(updatedCart));
        dispatch(addToCart(updatedCart));

        if (user.token) {
            await addCart([productForDB], user.token);
            toast.success(STRINGS.ADDED_TO_CART_SUCCESSFULLY, { toastId: "1" });
        }
    };
    const handleCombo = () => {
        for (const item of newArray) {
            addItemToCart(item);
        }
    };
    // add to Db
    const addToDB = async (item) => {
        // const dataToSend = [
        //     {
        //         product_id: item?.id,            // Use the actual product ID
        //         quantity: 1,     // Use the actual quantity
        //         product_coupon: "" // Use the actual coupon code
        //     }
        // ];
        try {
            const CartData = await addToBuyDB(item, user.token);
            if (CartData.status === "1") {
                // navigate("/checkout");
            } else {
                toast.error("There was an issue processing your cart. Please check your connection or try again later.");
            }

        } catch (e) {
            //
        }
    }
    
    //
    const excRate = useSelector(state => state.excrate);
    const handleBuyCombo = () => {
        if (user?.id) {
            dispatch(clearBuy());
            localStorage.removeItem("buy");
            const allItemsForDB = [];
            const updatedBuyItems = [];
            newArray.forEach(async (item) => {
                const existingBuyItems = JSON.parse(localStorage.getItem("buy")) || [];
                const { id, product_name, product_slug, is_wishlist, price, compare_price, weight, unit, stock_quantity, shipping_amount_type, shipping_charge, usd_price } = item;
                const image = `${comboImages}${item?.productimages?.[0]?.file_name}`;
                const newItem = { id, product_name, product_slug, is_wishlist, price, compare_price, shipping_amount_type, shipping_charge, image, count: 1, p_variant_id: 0, weight, unit, stock_quantity };
                updatedBuyItems?.push(newItem)
                allItemsForDB.push({
                    product_id: item?.id,   
                    quantity: 1,            
                    product_coupon: "",
                    usd_amount:usd_price,
                    currency_code:excRate?.currencyCode,
                    currency_rate:excRate?.rate,
                    currency_symbol:excRate?.currencySymbol
                });
            });
            localStorage.setItem("buy", JSON.stringify(updatedBuyItems));
            dispatch(addToBuy(updatedBuyItems));
            addToDB(allItemsForDB);
            navigate("/checkout");
        } else {
            navigate(Routes?.SignIn);
        }
    }
//
// console.log('checkedDatacheckedData',checkedData);
    const handleOthersCombo = async () => {
        let nextIndex = (remainingIndex + 1) % suggetOthers.length;

        const nextComboProducts = [
            suggetOthers[nextIndex],
            suggetOthers[(nextIndex + 1) % suggetOthers.length],
        ];
        setRemainingIndex(nextIndex);
        setProductCombo(nextComboProducts);
    };
    const variantSlugNavigate = (slug) => {
        navigate(Routes?.ProductDetail + "/" + slug);
        // fetchDataAndRelatedData(slug);
    };
    useEffect(() => {
    const handleComboProduct = async () => {
        const formData = new URLSearchParams();
        formData.append("product_id", comboProductId);
        formData.append("category_id", comboProductCategoryId);
        try {
            const response = await comboProduct(formData);

            if (response.status === "1") {
                const [firstIndexData, ...rest] = response?.data;
                const filteredData = rest.filter(item => item.id !== firstIndexData.id);

                setProductCombo(response.data.slice(1, 3));
                setFirstIndexData(firstIndexData);
                setSuggestOthers(filteredData);
                setComboImages(response.path);
                // setPage(response.pagination);
            }
        } catch (error) {
            console.error("Error fetching combo product data:", error);
        }
    };
        handleComboProduct();
    }, [])

    const renderedProductCombo = useMemo(() => {
        return productCombo?.map((comboItem, index) => (
            <React.Fragment key={comboItem.id}>
                <div className="combo_first_product">
                    <div className="combo_products">
                        <div className="combo_image">
                            <Checkbox
                                checked={!comboChecked[index]}
                                onChange={() => handleComboCheckbox(index)}
                            ></Checkbox>
                            <img
                                src={comboItem?.productimages.length > 0 ? `${comboImages}${comboItem?.productimages[0]?.file_name}` : img1}
                                alt=""
                                onClick={() =>
                                    variantSlugNavigate(
                                        comboItem?.product_slug
                                    )
                                }
                            />
                        </div>
                        <div className="combo_priceSpan" onClick={() =>
                            variantSlugNavigate(
                                comboItem?.product_slug
                            )
                        }>
                            <span>{comboItem?.product_name}</span>
                            <div className="combo_price">
                                <h4>₹{comboItem?.price}</h4>
                                <span>₹{comboItem?.compare_price}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {index !== productCombo.length - 1 && (
                    <div className="combo_plus">
                        <svg
                            width="32"
                            height="32"
                            viewBox="0 0 48 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M24.0605 10L24.0239 38"
                                stroke="#333"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="miter"
                            />
                            <path
                                d="M10 24L38 24"
                                stroke="#333"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="miter"
                            />
                        </svg>
                    </div>
                )}
            </React.Fragment>
        ));
    }, [productCombo, comboChecked, comboImages]);

    return (
        productCombo?.length > 1 && (
            <div className="combo_section">
                <h2>Pairs Well With</h2>
                <div className="combo_card_products">
                    <div className="combo_section_design">
                        <div className="combo_first_product">
                            <div className="combo_products">
                                <div className="combo_image">
                                    <img
                                        src={`${comboImages}${firstIndexData?.productimages?.[0]?.file_name}`}
                                        alt=""
                                    />
                                </div>
                                <div className="combo_priceSpan">
                                    <span>{firstIndexData?.product_name}</span>
                                    <div className="combo_price">
                                        <h4>₹{firstIndexData?.price}</h4>
                                        {firstIndexData?.price !==
                                            parseFloat(firstIndexData?.compare_price) &&
                                            firstIndexData?.compare_price > 0 && (
                                                <span>₹{firstIndexData?.compare_price}</span>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="combo_plus">
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M24.0605 10L24.0239 38"
                                    stroke="#333"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="miter"
                                />
                                <path
                                    d="M10 24L38 24"
                                    stroke="#333"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="miter"
                                />
                            </svg>
                        </div>
                        {renderedProductCombo}
                    </div>
                    <div className="combo_total">
                        <div className="combo__product">
                            <h6>Total Price </h6>
                            <h5>₹ {totalPrice}</h5>
                            <div className="combo_buttons">
                                {!isOutOfStock?(<button onClick={handleCombo} className="add_combo">Add to Cart</button>):(<button disabled className="add_combo">Out Of Stock</button>)}
                                <button className="combo_buy" onClick={handleBuyCombo}>
                                    Buy Combo
                                </button>
                            </div>
                            <div className="suggest_combo">
                                <button onClick={() => handleOthersCombo(productDetails)}>
                                    Suggest Other Combos
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default ProductCombo;
