import React, { useEffect, useState, useMemo } from 'react';
import { TruckOutlined } from '@ant-design/icons';
import "../../assets/css/style/checkout.scss";
import { getCustomerSupport } from '../../services/assurance';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Routes from '../../Routes/Routes';
import Dayjs from 'dayjs';
import ProductPrice from '../ProductPrice';

const CheckoutCartProduct = ({ cartDetailList, cartDetailImgPath }) => {
  // console.log('cartDetailList',cartDetailList);
  const comp = useSelector(state => state.shippingFees);
  const [supportData, setSupportData] = useState({});
  const getLocalData = localStorage.getItem("bufferdays");
  // const excRate = useSelector(state => state.excrate);

  const handleSupport = async () => {
    try {
      const result = await getCustomerSupport();
      setSupportData(result?.data || {});
    } catch (e) {
      console.error('Error fetching customer support data:', e);
    }
  };

  useEffect(() => {
    handleSupport();
  }, []);

  const estimatedDays = useMemo(() => {
    return parseInt(getLocalData) + (supportData?.product_buffer_days || 0);
  }, [getLocalData, supportData]);

  const newDateString = useMemo(() => {
    const dateFormat = 'MMM DD, YYYY';
    const originalDate = Dayjs(comp, dateFormat);
    const newDate = originalDate.add(estimatedDays, 'day');
    return newDate.format(dateFormat);
  }, [comp, estimatedDays]);

  return (
    <>
      {cartDetailList?.map((list) => {
        const { product, quantity} = list;
        const { id, product_name, product_slug, productimage, price, compare_price } = product;

        return (
          <div className="checkout_bag" key={id}>
            <div className='cart-products'>
              <div className='left_cart'>
                {product_name ? (
                  <Link to={`${Routes?.ProductDetail}/${product_slug}`} state={{ ProductId: id }}>
                    <img
                      src={`${cartDetailImgPath}/${productimage?.file_name_120_x_120}`}
                      alt={product_name}
                    />
                  </Link>
                ) : (
                  'Image Not Found'
                )}
              </div>
              <div className='right_cart'>
                <div className='price_delete align-items-start'>
                  <Link to={`${Routes?.ProductDetail}/${product_slug}`} state={{ ProductId: id }}>
                    <h2>{product_name}</h2>
                  </Link>
                  <div className='cartProduct_price'>
                    {/* <span>₹{price.toFixed(2)}</span>
                    {(compare_price !== null && compare_price !== undefined && !isNaN(compare_price)) && (
                      <p>₹{parseFloat(compare_price).toFixed(2)}</p>
                    )} */}
                    <ProductPrice product={product}/>
                  </div>
                </div>
                <span>QTY : {quantity}</span>
                <h5 className='pinCode_delivery'>
                  <TruckOutlined /> Estimated delivery: {newDateString !== "Invalid Date" ? newDateString : comp}
                </h5>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default CheckoutCartProduct;
