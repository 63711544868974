// services/Master.js
import axios from 'axios';

const apiConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL || 'https://demo1.growthgrids.com/api/user',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

const api = axios.create(apiConfig);

export const getColor= async (data) => {
  try {
    const response = await api.post('/color-list', data);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API Error: ${response.statusText}`);
    }
  } catch (error) {
    // console.error('Error fetching menu data:', error);
    throw error; // Re-throw the error to allow the calling code to handle it
  }
};
